.@{noUi-css-prefix}-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
}

.@{noUi-css-prefix}-horizontal .@{noUi-css-prefix}-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
}

.@{noUi-css-prefix}-vertical .@{noUi-css-prefix}-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
}

.@{noUi-css-prefix}-horizontal .@{noUi-css-prefix}-origin > .@{noUi-css-prefix}-tooltip {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0);
    left: auto;
    bottom: 10px;
}

.@{noUi-css-prefix}-vertical .@{noUi-css-prefix}-origin > .@{noUi-css-prefix}-tooltip {
    -webkit-transform: translate(0, -18px);
    transform: translate(0, -18px);
    top: auto;
    right: 28px;
}
