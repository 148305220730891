/* You can add global styles to this file, and also import other style files */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
.noUi-connect {
  height: 100%;
  width: 100%;
}
.noUi-origin {
  height: 100%;
  width: 100%;
}
/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}
.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  top: -17px;
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: #3FB8AF;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
}
.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
}
/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}
/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}
.noUi-marker-sub {
  background: #AAA;
}
.noUi-marker-large {
  background: #AAA;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
.noUi-horizontal .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}
.noUi-vertical .noUi-origin > .noUi-tooltip {
  -webkit-transform: translate(0, -18px);
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}
/* Keep this file after other imports to override other styles*/
/* Variables */
/* Fonts */
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
}
body {
  padding-top: 0;
}
h1.theme-font,
h2.theme-font,
h3.theme-font,
h4.theme-font,
h5.theme-font,
button.theme-font,
a.theme-font {
  font-family: "Open Sans", "Open Sans Condensed", "Trade Gothic", Arial, Helvetica, Sans-Serif !important;
  letter-spacing: 0.1px;
  word-spacing: 1.5px;
  color: #666;
}
/* Colors */
.bg-transparent {
  background-color: transparent !important;
}
.bg-white {
  background-color: #ffffff !important;
}
.red {
  color: #a90329;
}
/* General layout */
.clear {
  clear: both;
}
.clear-left {
  clear: left;
}
.clear-right {
  clear: right;
}
.nowrap {
  white-space: nowrap !important;
}
.pre-line {
  white-space: pre-line !important;
}
.block {
  display: block !important;
}
.inline-block {
  display: inline-block !important;
}
.padding-0 {
  padding: 0px !important;
}
.padding-top-0 {
  padding-top: 0px !important;
}
.padding-right-0 {
  padding-right: 0px !important;
}
.padding-bottom-0 {
  padding-bottom: 0px !important;
}
.padding-left-0 {
  padding-left: 0px !important;
}
.padding-neg-0 {
  padding: 0px !important;
}
.padding-top-neg-0 {
  padding-top: 0px !important;
}
.padding-right-neg-0 {
  padding-right: 0px !important;
}
.padding-bottom-neg-0 {
  padding-bottom: 0px !important;
}
.padding-left-neg-0 {
  padding-left: 0px !important;
}
.padding-5 {
  padding: 5px !important;
}
.padding-top-5 {
  padding-top: 5px !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}
.padding-bottom-5 {
  padding-bottom: 5px !important;
}
.padding-left-5 {
  padding-left: 5px !important;
}
.padding-neg-5 {
  padding: 5px !important;
}
.padding-top-neg-5 {
  padding-top: 5px !important;
}
.padding-right-neg-5 {
  padding-right: 5px !important;
}
.padding-bottom-neg-5 {
  padding-bottom: 5px !important;
}
.padding-left-neg-5 {
  padding-left: 5px !important;
}
.padding-10 {
  padding: 10px !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-right-10 {
  padding-right: 10px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.padding-left-10 {
  padding-left: 10px !important;
}
.padding-neg-10 {
  padding: 10px !important;
}
.padding-top-neg-10 {
  padding-top: 10px !important;
}
.padding-right-neg-10 {
  padding-right: 10px !important;
}
.padding-bottom-neg-10 {
  padding-bottom: 10px !important;
}
.padding-left-neg-10 {
  padding-left: 10px !important;
}
.padding-15 {
  padding: 15px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-right-15 {
  padding-right: 15px !important;
}
.padding-bottom-15 {
  padding-bottom: 15px !important;
}
.padding-left-15 {
  padding-left: 15px !important;
}
.padding-neg-15 {
  padding: 15px !important;
}
.padding-top-neg-15 {
  padding-top: 15px !important;
}
.padding-right-neg-15 {
  padding-right: 15px !important;
}
.padding-bottom-neg-15 {
  padding-bottom: 15px !important;
}
.padding-left-neg-15 {
  padding-left: 15px !important;
}
.padding-20 {
  padding: 20px !important;
}
.padding-top-20 {
  padding-top: 20px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.padding-bottom-20 {
  padding-bottom: 20px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-neg-20 {
  padding: 20px !important;
}
.padding-top-neg-20 {
  padding-top: 20px !important;
}
.padding-right-neg-20 {
  padding-right: 20px !important;
}
.padding-bottom-neg-20 {
  padding-bottom: 20px !important;
}
.padding-left-neg-20 {
  padding-left: 20px !important;
}
.padding-25 {
  padding: 25px !important;
}
.padding-top-25 {
  padding-top: 25px !important;
}
.padding-right-25 {
  padding-right: 25px !important;
}
.padding-bottom-25 {
  padding-bottom: 25px !important;
}
.padding-left-25 {
  padding-left: 25px !important;
}
.padding-neg-25 {
  padding: 25px !important;
}
.padding-top-neg-25 {
  padding-top: 25px !important;
}
.padding-right-neg-25 {
  padding-right: 25px !important;
}
.padding-bottom-neg-25 {
  padding-bottom: 25px !important;
}
.padding-left-neg-25 {
  padding-left: 25px !important;
}
.padding-30 {
  padding: 30px !important;
}
.padding-top-30 {
  padding-top: 30px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}
.padding-bottom-30 {
  padding-bottom: 30px !important;
}
.padding-left-30 {
  padding-left: 30px !important;
}
.padding-neg-30 {
  padding: 30px !important;
}
.padding-top-neg-30 {
  padding-top: 30px !important;
}
.padding-right-neg-30 {
  padding-right: 30px !important;
}
.padding-bottom-neg-30 {
  padding-bottom: 30px !important;
}
.padding-left-neg-30 {
  padding-left: 30px !important;
}
.padding-35 {
  padding: 35px !important;
}
.padding-top-35 {
  padding-top: 35px !important;
}
.padding-right-35 {
  padding-right: 35px !important;
}
.padding-bottom-35 {
  padding-bottom: 35px !important;
}
.padding-left-35 {
  padding-left: 35px !important;
}
.padding-neg-35 {
  padding: 35px !important;
}
.padding-top-neg-35 {
  padding-top: 35px !important;
}
.padding-right-neg-35 {
  padding-right: 35px !important;
}
.padding-bottom-neg-35 {
  padding-bottom: 35px !important;
}
.padding-left-neg-35 {
  padding-left: 35px !important;
}
.padding-40 {
  padding: 40px !important;
}
.padding-top-40 {
  padding-top: 40px !important;
}
.padding-right-40 {
  padding-right: 40px !important;
}
.padding-bottom-40 {
  padding-bottom: 40px !important;
}
.padding-left-40 {
  padding-left: 40px !important;
}
.padding-neg-40 {
  padding: 40px !important;
}
.padding-top-neg-40 {
  padding-top: 40px !important;
}
.padding-right-neg-40 {
  padding-right: 40px !important;
}
.padding-bottom-neg-40 {
  padding-bottom: 40px !important;
}
.padding-left-neg-40 {
  padding-left: 40px !important;
}
.padding-45 {
  padding: 45px !important;
}
.padding-top-45 {
  padding-top: 45px !important;
}
.padding-right-45 {
  padding-right: 45px !important;
}
.padding-bottom-45 {
  padding-bottom: 45px !important;
}
.padding-left-45 {
  padding-left: 45px !important;
}
.padding-neg-45 {
  padding: 45px !important;
}
.padding-top-neg-45 {
  padding-top: 45px !important;
}
.padding-right-neg-45 {
  padding-right: 45px !important;
}
.padding-bottom-neg-45 {
  padding-bottom: 45px !important;
}
.padding-left-neg-45 {
  padding-left: 45px !important;
}
.padding-50 {
  padding: 50px !important;
}
.padding-top-50 {
  padding-top: 50px !important;
}
.padding-right-50 {
  padding-right: 50px !important;
}
.padding-bottom-50 {
  padding-bottom: 50px !important;
}
.padding-left-50 {
  padding-left: 50px !important;
}
.padding-neg-50 {
  padding: 50px !important;
}
.padding-top-neg-50 {
  padding-top: 50px !important;
}
.padding-right-neg-50 {
  padding-right: 50px !important;
}
.padding-bottom-neg-50 {
  padding-bottom: 50px !important;
}
.padding-left-neg-50 {
  padding-left: 50px !important;
}
.padding-55 {
  padding: 55px !important;
}
.padding-top-55 {
  padding-top: 55px !important;
}
.padding-right-55 {
  padding-right: 55px !important;
}
.padding-bottom-55 {
  padding-bottom: 55px !important;
}
.padding-left-55 {
  padding-left: 55px !important;
}
.padding-neg-55 {
  padding: 55px !important;
}
.padding-top-neg-55 {
  padding-top: 55px !important;
}
.padding-right-neg-55 {
  padding-right: 55px !important;
}
.padding-bottom-neg-55 {
  padding-bottom: 55px !important;
}
.padding-left-neg-55 {
  padding-left: 55px !important;
}
.padding-60 {
  padding: 60px !important;
}
.padding-top-60 {
  padding-top: 60px !important;
}
.padding-right-60 {
  padding-right: 60px !important;
}
.padding-bottom-60 {
  padding-bottom: 60px !important;
}
.padding-left-60 {
  padding-left: 60px !important;
}
.padding-neg-60 {
  padding: 60px !important;
}
.padding-top-neg-60 {
  padding-top: 60px !important;
}
.padding-right-neg-60 {
  padding-right: 60px !important;
}
.padding-bottom-neg-60 {
  padding-bottom: 60px !important;
}
.padding-left-neg-60 {
  padding-left: 60px !important;
}
.padding-65 {
  padding: 65px !important;
}
.padding-top-65 {
  padding-top: 65px !important;
}
.padding-right-65 {
  padding-right: 65px !important;
}
.padding-bottom-65 {
  padding-bottom: 65px !important;
}
.padding-left-65 {
  padding-left: 65px !important;
}
.padding-neg-65 {
  padding: 65px !important;
}
.padding-top-neg-65 {
  padding-top: 65px !important;
}
.padding-right-neg-65 {
  padding-right: 65px !important;
}
.padding-bottom-neg-65 {
  padding-bottom: 65px !important;
}
.padding-left-neg-65 {
  padding-left: 65px !important;
}
.padding-70 {
  padding: 70px !important;
}
.padding-top-70 {
  padding-top: 70px !important;
}
.padding-right-70 {
  padding-right: 70px !important;
}
.padding-bottom-70 {
  padding-bottom: 70px !important;
}
.padding-left-70 {
  padding-left: 70px !important;
}
.padding-neg-70 {
  padding: 70px !important;
}
.padding-top-neg-70 {
  padding-top: 70px !important;
}
.padding-right-neg-70 {
  padding-right: 70px !important;
}
.padding-bottom-neg-70 {
  padding-bottom: 70px !important;
}
.padding-left-neg-70 {
  padding-left: 70px !important;
}
.padding-75 {
  padding: 75px !important;
}
.padding-top-75 {
  padding-top: 75px !important;
}
.padding-right-75 {
  padding-right: 75px !important;
}
.padding-bottom-75 {
  padding-bottom: 75px !important;
}
.padding-left-75 {
  padding-left: 75px !important;
}
.padding-neg-75 {
  padding: 75px !important;
}
.padding-top-neg-75 {
  padding-top: 75px !important;
}
.padding-right-neg-75 {
  padding-right: 75px !important;
}
.padding-bottom-neg-75 {
  padding-bottom: 75px !important;
}
.padding-left-neg-75 {
  padding-left: 75px !important;
}
.padding-80 {
  padding: 80px !important;
}
.padding-top-80 {
  padding-top: 80px !important;
}
.padding-right-80 {
  padding-right: 80px !important;
}
.padding-bottom-80 {
  padding-bottom: 80px !important;
}
.padding-left-80 {
  padding-left: 80px !important;
}
.padding-neg-80 {
  padding: 80px !important;
}
.padding-top-neg-80 {
  padding-top: 80px !important;
}
.padding-right-neg-80 {
  padding-right: 80px !important;
}
.padding-bottom-neg-80 {
  padding-bottom: 80px !important;
}
.padding-left-neg-80 {
  padding-left: 80px !important;
}
.padding-85 {
  padding: 85px !important;
}
.padding-top-85 {
  padding-top: 85px !important;
}
.padding-right-85 {
  padding-right: 85px !important;
}
.padding-bottom-85 {
  padding-bottom: 85px !important;
}
.padding-left-85 {
  padding-left: 85px !important;
}
.padding-neg-85 {
  padding: 85px !important;
}
.padding-top-neg-85 {
  padding-top: 85px !important;
}
.padding-right-neg-85 {
  padding-right: 85px !important;
}
.padding-bottom-neg-85 {
  padding-bottom: 85px !important;
}
.padding-left-neg-85 {
  padding-left: 85px !important;
}
.padding-90 {
  padding: 90px !important;
}
.padding-top-90 {
  padding-top: 90px !important;
}
.padding-right-90 {
  padding-right: 90px !important;
}
.padding-bottom-90 {
  padding-bottom: 90px !important;
}
.padding-left-90 {
  padding-left: 90px !important;
}
.padding-neg-90 {
  padding: 90px !important;
}
.padding-top-neg-90 {
  padding-top: 90px !important;
}
.padding-right-neg-90 {
  padding-right: 90px !important;
}
.padding-bottom-neg-90 {
  padding-bottom: 90px !important;
}
.padding-left-neg-90 {
  padding-left: 90px !important;
}
.padding-95 {
  padding: 95px !important;
}
.padding-top-95 {
  padding-top: 95px !important;
}
.padding-right-95 {
  padding-right: 95px !important;
}
.padding-bottom-95 {
  padding-bottom: 95px !important;
}
.padding-left-95 {
  padding-left: 95px !important;
}
.padding-neg-95 {
  padding: 95px !important;
}
.padding-top-neg-95 {
  padding-top: 95px !important;
}
.padding-right-neg-95 {
  padding-right: 95px !important;
}
.padding-bottom-neg-95 {
  padding-bottom: 95px !important;
}
.padding-left-neg-95 {
  padding-left: 95px !important;
}
.padding-100 {
  padding: 100px !important;
}
.padding-top-100 {
  padding-top: 100px !important;
}
.padding-right-100 {
  padding-right: 100px !important;
}
.padding-bottom-100 {
  padding-bottom: 100px !important;
}
.padding-left-100 {
  padding-left: 100px !important;
}
.padding-neg-100 {
  padding: 100px !important;
}
.padding-top-neg-100 {
  padding-top: 100px !important;
}
.padding-right-neg-100 {
  padding-right: 100px !important;
}
.padding-bottom-neg-100 {
  padding-bottom: 100px !important;
}
.padding-left-neg-100 {
  padding-left: 100px !important;
}
.margin-0 {
  margin: 0px !important;
}
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-right-0 {
  margin-right: 0px !important;
}
.margin-bottom-0 {
  margin-bottom: 0px !important;
}
.margin-left-0 {
  margin-left: 0px !important;
}
.margin-neg-0 {
  margin: 0px !important;
}
.margin-top-neg-0 {
  margin-top: 0px !important;
}
.margin-right-neg-0 {
  margin-right: 0px !important;
}
.margin-bottom-neg-0 {
  margin-bottom: 0px !important;
}
.margin-left-neg-0 {
  margin-left: 0px !important;
}
.margin-5 {
  margin: 5px !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-left-5 {
  margin-left: 5px !important;
}
.margin-neg-5 {
  margin: 5px !important;
}
.margin-top-neg-5 {
  margin-top: 5px !important;
}
.margin-right-neg-5 {
  margin-right: 5px !important;
}
.margin-bottom-neg-5 {
  margin-bottom: 5px !important;
}
.margin-left-neg-5 {
  margin-left: 5px !important;
}
.margin-10 {
  margin: 10px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-right-10 {
  margin-right: 10px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}
.margin-neg-10 {
  margin: 10px !important;
}
.margin-top-neg-10 {
  margin-top: 10px !important;
}
.margin-right-neg-10 {
  margin-right: 10px !important;
}
.margin-bottom-neg-10 {
  margin-bottom: 10px !important;
}
.margin-left-neg-10 {
  margin-left: 10px !important;
}
.margin-15 {
  margin: 15px !important;
}
.margin-top-15 {
  margin-top: 15px !important;
}
.margin-right-15 {
  margin-right: 15px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.margin-left-15 {
  margin-left: 15px !important;
}
.margin-neg-15 {
  margin: 15px !important;
}
.margin-top-neg-15 {
  margin-top: 15px !important;
}
.margin-right-neg-15 {
  margin-right: 15px !important;
}
.margin-bottom-neg-15 {
  margin-bottom: 15px !important;
}
.margin-left-neg-15 {
  margin-left: 15px !important;
}
.margin-20 {
  margin: 20px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.margin-neg-20 {
  margin: 20px !important;
}
.margin-top-neg-20 {
  margin-top: 20px !important;
}
.margin-right-neg-20 {
  margin-right: 20px !important;
}
.margin-bottom-neg-20 {
  margin-bottom: 20px !important;
}
.margin-left-neg-20 {
  margin-left: 20px !important;
}
.margin-25 {
  margin: 25px !important;
}
.margin-top-25 {
  margin-top: 25px !important;
}
.margin-right-25 {
  margin-right: 25px !important;
}
.margin-bottom-25 {
  margin-bottom: 25px !important;
}
.margin-left-25 {
  margin-left: 25px !important;
}
.margin-neg-25 {
  margin: 25px !important;
}
.margin-top-neg-25 {
  margin-top: 25px !important;
}
.margin-right-neg-25 {
  margin-right: 25px !important;
}
.margin-bottom-neg-25 {
  margin-bottom: 25px !important;
}
.margin-left-neg-25 {
  margin-left: 25px !important;
}
.margin-30 {
  margin: 30px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.margin-right-30 {
  margin-right: 30px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.margin-left-30 {
  margin-left: 30px !important;
}
.margin-neg-30 {
  margin: 30px !important;
}
.margin-top-neg-30 {
  margin-top: 30px !important;
}
.margin-right-neg-30 {
  margin-right: 30px !important;
}
.margin-bottom-neg-30 {
  margin-bottom: 30px !important;
}
.margin-left-neg-30 {
  margin-left: 30px !important;
}
.margin-35 {
  margin: 35px !important;
}
.margin-top-35 {
  margin-top: 35px !important;
}
.margin-right-35 {
  margin-right: 35px !important;
}
.margin-bottom-35 {
  margin-bottom: 35px !important;
}
.margin-left-35 {
  margin-left: 35px !important;
}
.margin-neg-35 {
  margin: 35px !important;
}
.margin-top-neg-35 {
  margin-top: 35px !important;
}
.margin-right-neg-35 {
  margin-right: 35px !important;
}
.margin-bottom-neg-35 {
  margin-bottom: 35px !important;
}
.margin-left-neg-35 {
  margin-left: 35px !important;
}
.margin-40 {
  margin: 40px !important;
}
.margin-top-40 {
  margin-top: 40px !important;
}
.margin-right-40 {
  margin-right: 40px !important;
}
.margin-bottom-40 {
  margin-bottom: 40px !important;
}
.margin-left-40 {
  margin-left: 40px !important;
}
.margin-neg-40 {
  margin: 40px !important;
}
.margin-top-neg-40 {
  margin-top: 40px !important;
}
.margin-right-neg-40 {
  margin-right: 40px !important;
}
.margin-bottom-neg-40 {
  margin-bottom: 40px !important;
}
.margin-left-neg-40 {
  margin-left: 40px !important;
}
.margin-45 {
  margin: 45px !important;
}
.margin-top-45 {
  margin-top: 45px !important;
}
.margin-right-45 {
  margin-right: 45px !important;
}
.margin-bottom-45 {
  margin-bottom: 45px !important;
}
.margin-left-45 {
  margin-left: 45px !important;
}
.margin-neg-45 {
  margin: 45px !important;
}
.margin-top-neg-45 {
  margin-top: 45px !important;
}
.margin-right-neg-45 {
  margin-right: 45px !important;
}
.margin-bottom-neg-45 {
  margin-bottom: 45px !important;
}
.margin-left-neg-45 {
  margin-left: 45px !important;
}
.margin-50 {
  margin: 50px !important;
}
.margin-top-50 {
  margin-top: 50px !important;
}
.margin-right-50 {
  margin-right: 50px !important;
}
.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.margin-left-50 {
  margin-left: 50px !important;
}
.margin-neg-50 {
  margin: 50px !important;
}
.margin-top-neg-50 {
  margin-top: 50px !important;
}
.margin-right-neg-50 {
  margin-right: 50px !important;
}
.margin-bottom-neg-50 {
  margin-bottom: 50px !important;
}
.margin-left-neg-50 {
  margin-left: 50px !important;
}
.margin-55 {
  margin: 55px !important;
}
.margin-top-55 {
  margin-top: 55px !important;
}
.margin-right-55 {
  margin-right: 55px !important;
}
.margin-bottom-55 {
  margin-bottom: 55px !important;
}
.margin-left-55 {
  margin-left: 55px !important;
}
.margin-neg-55 {
  margin: 55px !important;
}
.margin-top-neg-55 {
  margin-top: 55px !important;
}
.margin-right-neg-55 {
  margin-right: 55px !important;
}
.margin-bottom-neg-55 {
  margin-bottom: 55px !important;
}
.margin-left-neg-55 {
  margin-left: 55px !important;
}
.margin-60 {
  margin: 60px !important;
}
.margin-top-60 {
  margin-top: 60px !important;
}
.margin-right-60 {
  margin-right: 60px !important;
}
.margin-bottom-60 {
  margin-bottom: 60px !important;
}
.margin-left-60 {
  margin-left: 60px !important;
}
.margin-neg-60 {
  margin: 60px !important;
}
.margin-top-neg-60 {
  margin-top: 60px !important;
}
.margin-right-neg-60 {
  margin-right: 60px !important;
}
.margin-bottom-neg-60 {
  margin-bottom: 60px !important;
}
.margin-left-neg-60 {
  margin-left: 60px !important;
}
.margin-65 {
  margin: 65px !important;
}
.margin-top-65 {
  margin-top: 65px !important;
}
.margin-right-65 {
  margin-right: 65px !important;
}
.margin-bottom-65 {
  margin-bottom: 65px !important;
}
.margin-left-65 {
  margin-left: 65px !important;
}
.margin-neg-65 {
  margin: 65px !important;
}
.margin-top-neg-65 {
  margin-top: 65px !important;
}
.margin-right-neg-65 {
  margin-right: 65px !important;
}
.margin-bottom-neg-65 {
  margin-bottom: 65px !important;
}
.margin-left-neg-65 {
  margin-left: 65px !important;
}
.margin-70 {
  margin: 70px !important;
}
.margin-top-70 {
  margin-top: 70px !important;
}
.margin-right-70 {
  margin-right: 70px !important;
}
.margin-bottom-70 {
  margin-bottom: 70px !important;
}
.margin-left-70 {
  margin-left: 70px !important;
}
.margin-neg-70 {
  margin: 70px !important;
}
.margin-top-neg-70 {
  margin-top: 70px !important;
}
.margin-right-neg-70 {
  margin-right: 70px !important;
}
.margin-bottom-neg-70 {
  margin-bottom: 70px !important;
}
.margin-left-neg-70 {
  margin-left: 70px !important;
}
.margin-75 {
  margin: 75px !important;
}
.margin-top-75 {
  margin-top: 75px !important;
}
.margin-right-75 {
  margin-right: 75px !important;
}
.margin-bottom-75 {
  margin-bottom: 75px !important;
}
.margin-left-75 {
  margin-left: 75px !important;
}
.margin-neg-75 {
  margin: 75px !important;
}
.margin-top-neg-75 {
  margin-top: 75px !important;
}
.margin-right-neg-75 {
  margin-right: 75px !important;
}
.margin-bottom-neg-75 {
  margin-bottom: 75px !important;
}
.margin-left-neg-75 {
  margin-left: 75px !important;
}
.margin-80 {
  margin: 80px !important;
}
.margin-top-80 {
  margin-top: 80px !important;
}
.margin-right-80 {
  margin-right: 80px !important;
}
.margin-bottom-80 {
  margin-bottom: 80px !important;
}
.margin-left-80 {
  margin-left: 80px !important;
}
.margin-neg-80 {
  margin: 80px !important;
}
.margin-top-neg-80 {
  margin-top: 80px !important;
}
.margin-right-neg-80 {
  margin-right: 80px !important;
}
.margin-bottom-neg-80 {
  margin-bottom: 80px !important;
}
.margin-left-neg-80 {
  margin-left: 80px !important;
}
.margin-85 {
  margin: 85px !important;
}
.margin-top-85 {
  margin-top: 85px !important;
}
.margin-right-85 {
  margin-right: 85px !important;
}
.margin-bottom-85 {
  margin-bottom: 85px !important;
}
.margin-left-85 {
  margin-left: 85px !important;
}
.margin-neg-85 {
  margin: 85px !important;
}
.margin-top-neg-85 {
  margin-top: 85px !important;
}
.margin-right-neg-85 {
  margin-right: 85px !important;
}
.margin-bottom-neg-85 {
  margin-bottom: 85px !important;
}
.margin-left-neg-85 {
  margin-left: 85px !important;
}
.margin-90 {
  margin: 90px !important;
}
.margin-top-90 {
  margin-top: 90px !important;
}
.margin-right-90 {
  margin-right: 90px !important;
}
.margin-bottom-90 {
  margin-bottom: 90px !important;
}
.margin-left-90 {
  margin-left: 90px !important;
}
.margin-neg-90 {
  margin: 90px !important;
}
.margin-top-neg-90 {
  margin-top: 90px !important;
}
.margin-right-neg-90 {
  margin-right: 90px !important;
}
.margin-bottom-neg-90 {
  margin-bottom: 90px !important;
}
.margin-left-neg-90 {
  margin-left: 90px !important;
}
.margin-95 {
  margin: 95px !important;
}
.margin-top-95 {
  margin-top: 95px !important;
}
.margin-right-95 {
  margin-right: 95px !important;
}
.margin-bottom-95 {
  margin-bottom: 95px !important;
}
.margin-left-95 {
  margin-left: 95px !important;
}
.margin-neg-95 {
  margin: 95px !important;
}
.margin-top-neg-95 {
  margin-top: 95px !important;
}
.margin-right-neg-95 {
  margin-right: 95px !important;
}
.margin-bottom-neg-95 {
  margin-bottom: 95px !important;
}
.margin-left-neg-95 {
  margin-left: 95px !important;
}
.margin-100 {
  margin: 100px !important;
}
.margin-top-100 {
  margin-top: 100px !important;
}
.margin-right-100 {
  margin-right: 100px !important;
}
.margin-bottom-100 {
  margin-bottom: 100px !important;
}
.margin-left-100 {
  margin-left: 100px !important;
}
.margin-neg-100 {
  margin: 100px !important;
}
.margin-top-neg-100 {
  margin-top: 100px !important;
}
.margin-right-neg-100 {
  margin-right: 100px !important;
}
.margin-bottom-neg-100 {
  margin-bottom: 100px !important;
}
.margin-left-neg-100 {
  margin-left: 100px !important;
}
.w0 {
  width: 0% !important;
}
.w5 {
  width: 5% !important;
}
.w10 {
  width: 10% !important;
}
.w15 {
  width: 15% !important;
}
.w20 {
  width: 20% !important;
}
.w25 {
  width: 25% !important;
}
.w30 {
  width: 30% !important;
}
.w35 {
  width: 35% !important;
}
.w40 {
  width: 40% !important;
}
.w45 {
  width: 45% !important;
}
.w50 {
  width: 50% !important;
}
.w55 {
  width: 55% !important;
}
.w60 {
  width: 60% !important;
}
.w65 {
  width: 65% !important;
}
.w70 {
  width: 70% !important;
}
.w75 {
  width: 75% !important;
}
.w80 {
  width: 80% !important;
}
.w85 {
  width: 85% !important;
}
.w90 {
  width: 90% !important;
}
.w95 {
  width: 95% !important;
}
.w100 {
  width: 100% !important;
}
.h0 {
  height: 0% !important;
}
.h5 {
  height: 5% !important;
}
.h10 {
  height: 10% !important;
}
.h15 {
  height: 15% !important;
}
.h20 {
  height: 20% !important;
}
.h25 {
  height: 25% !important;
}
.h30 {
  height: 30% !important;
}
.h35 {
  height: 35% !important;
}
.h40 {
  height: 40% !important;
}
.h45 {
  height: 45% !important;
}
.h50 {
  height: 50% !important;
}
.h55 {
  height: 55% !important;
}
.h60 {
  height: 60% !important;
}
.h65 {
  height: 65% !important;
}
.h70 {
  height: 70% !important;
}
.h75 {
  height: 75% !important;
}
.h80 {
  height: 80% !important;
}
.h85 {
  height: 85% !important;
}
.h90 {
  height: 90% !important;
}
.h95 {
  height: 95% !important;
}
.h100 {
  height: 100% !important;
}
.w0px {
  width: 0px;
}
.w5px {
  width: 5px;
}
.w10px {
  width: 10px;
}
.w15px {
  width: 15px;
}
.w20px {
  width: 20px;
}
.w25px {
  width: 25px;
}
.w30px {
  width: 30px;
}
.w35px {
  width: 35px;
}
.w40px {
  width: 40px;
}
.w45px {
  width: 45px;
}
.w50px {
  width: 50px;
}
.w55px {
  width: 55px;
}
.w60px {
  width: 60px;
}
.w65px {
  width: 65px;
}
.w70px {
  width: 70px;
}
.w75px {
  width: 75px;
}
.w80px {
  width: 80px;
}
.w85px {
  width: 85px;
}
.w90px {
  width: 90px;
}
.w95px {
  width: 95px;
}
.w100px {
  width: 100px;
}
.w105px {
  width: 105px;
}
.w110px {
  width: 110px;
}
.w115px {
  width: 115px;
}
.w120px {
  width: 120px;
}
.w125px {
  width: 125px;
}
.w130px {
  width: 130px;
}
.w135px {
  width: 135px;
}
.w140px {
  width: 140px;
}
.w145px {
  width: 145px;
}
.w150px {
  width: 150px;
}
.w155px {
  width: 155px;
}
.w160px {
  width: 160px;
}
.w165px {
  width: 165px;
}
.w170px {
  width: 170px;
}
.w175px {
  width: 175px;
}
.w180px {
  width: 180px;
}
.w185px {
  width: 185px;
}
.w190px {
  width: 190px;
}
.w195px {
  width: 195px;
}
.w200px {
  width: 200px;
}
.w205px {
  width: 205px;
}
.w210px {
  width: 210px;
}
.w215px {
  width: 215px;
}
.w220px {
  width: 220px;
}
.w225px {
  width: 225px;
}
.w230px {
  width: 230px;
}
.w235px {
  width: 235px;
}
.w240px {
  width: 240px;
}
.w245px {
  width: 245px;
}
.w250px {
  width: 250px;
}
.w255px {
  width: 255px;
}
.w260px {
  width: 260px;
}
.w265px {
  width: 265px;
}
.w270px {
  width: 270px;
}
.w275px {
  width: 275px;
}
.w280px {
  width: 280px;
}
.w285px {
  width: 285px;
}
.w290px {
  width: 290px;
}
.w295px {
  width: 295px;
}
.w300px {
  width: 300px;
}
.w305px {
  width: 305px;
}
.w310px {
  width: 310px;
}
.w315px {
  width: 315px;
}
.w320px {
  width: 320px;
}
.w325px {
  width: 325px;
}
.w330px {
  width: 330px;
}
.w335px {
  width: 335px;
}
.w340px {
  width: 340px;
}
.w345px {
  width: 345px;
}
.w350px {
  width: 350px;
}
.w355px {
  width: 355px;
}
.w360px {
  width: 360px;
}
.w365px {
  width: 365px;
}
.w370px {
  width: 370px;
}
.w375px {
  width: 375px;
}
.w380px {
  width: 380px;
}
.w385px {
  width: 385px;
}
.w390px {
  width: 390px;
}
.w395px {
  width: 395px;
}
.w400px {
  width: 400px;
}
.w405px {
  width: 405px;
}
.w410px {
  width: 410px;
}
.w415px {
  width: 415px;
}
.w420px {
  width: 420px;
}
.w425px {
  width: 425px;
}
.w430px {
  width: 430px;
}
.w435px {
  width: 435px;
}
.w440px {
  width: 440px;
}
.w445px {
  width: 445px;
}
.w450px {
  width: 450px;
}
.w455px {
  width: 455px;
}
.w460px {
  width: 460px;
}
.w465px {
  width: 465px;
}
.w470px {
  width: 470px;
}
.w475px {
  width: 475px;
}
.w480px {
  width: 480px;
}
.w485px {
  width: 485px;
}
.w490px {
  width: 490px;
}
.w495px {
  width: 495px;
}
.w500px {
  width: 500px;
}
.w505px {
  width: 505px;
}
.w510px {
  width: 510px;
}
.w515px {
  width: 515px;
}
.w520px {
  width: 520px;
}
.w525px {
  width: 525px;
}
.w530px {
  width: 530px;
}
.w535px {
  width: 535px;
}
.w540px {
  width: 540px;
}
.w545px {
  width: 545px;
}
.w550px {
  width: 550px;
}
.w555px {
  width: 555px;
}
.w560px {
  width: 560px;
}
.w565px {
  width: 565px;
}
.w570px {
  width: 570px;
}
.w575px {
  width: 575px;
}
.w580px {
  width: 580px;
}
.w585px {
  width: 585px;
}
.w590px {
  width: 590px;
}
.w595px {
  width: 595px;
}
.w600px {
  width: 600px;
}
.w605px {
  width: 605px;
}
.w610px {
  width: 610px;
}
.w615px {
  width: 615px;
}
.w620px {
  width: 620px;
}
.w625px {
  width: 625px;
}
.w630px {
  width: 630px;
}
.w635px {
  width: 635px;
}
.w640px {
  width: 640px;
}
.w645px {
  width: 645px;
}
.w650px {
  width: 650px;
}
.w655px {
  width: 655px;
}
.w660px {
  width: 660px;
}
.w665px {
  width: 665px;
}
.w670px {
  width: 670px;
}
.w675px {
  width: 675px;
}
.w680px {
  width: 680px;
}
.w685px {
  width: 685px;
}
.w690px {
  width: 690px;
}
.w695px {
  width: 695px;
}
.w700px {
  width: 700px;
}
.w705px {
  width: 705px;
}
.w710px {
  width: 710px;
}
.w715px {
  width: 715px;
}
.w720px {
  width: 720px;
}
.w725px {
  width: 725px;
}
.w730px {
  width: 730px;
}
.w735px {
  width: 735px;
}
.w740px {
  width: 740px;
}
.w745px {
  width: 745px;
}
.w750px {
  width: 750px;
}
.w755px {
  width: 755px;
}
.w760px {
  width: 760px;
}
.w765px {
  width: 765px;
}
.w770px {
  width: 770px;
}
.w775px {
  width: 775px;
}
.w780px {
  width: 780px;
}
.w785px {
  width: 785px;
}
.w790px {
  width: 790px;
}
.w795px {
  width: 795px;
}
.w800px {
  width: 800px;
}
.w805px {
  width: 805px;
}
.w810px {
  width: 810px;
}
.w815px {
  width: 815px;
}
.w820px {
  width: 820px;
}
.w825px {
  width: 825px;
}
.w830px {
  width: 830px;
}
.w835px {
  width: 835px;
}
.w840px {
  width: 840px;
}
.w845px {
  width: 845px;
}
.w850px {
  width: 850px;
}
.w855px {
  width: 855px;
}
.w860px {
  width: 860px;
}
.w865px {
  width: 865px;
}
.w870px {
  width: 870px;
}
.w875px {
  width: 875px;
}
.w880px {
  width: 880px;
}
.w885px {
  width: 885px;
}
.w890px {
  width: 890px;
}
.w895px {
  width: 895px;
}
.w900px {
  width: 900px;
}
.w905px {
  width: 905px;
}
.w910px {
  width: 910px;
}
.w915px {
  width: 915px;
}
.w920px {
  width: 920px;
}
.w925px {
  width: 925px;
}
.w930px {
  width: 930px;
}
.w935px {
  width: 935px;
}
.w940px {
  width: 940px;
}
.w945px {
  width: 945px;
}
.w950px {
  width: 950px;
}
.w955px {
  width: 955px;
}
.w960px {
  width: 960px;
}
.w965px {
  width: 965px;
}
.w970px {
  width: 970px;
}
.w975px {
  width: 975px;
}
.w980px {
  width: 980px;
}
.w985px {
  width: 985px;
}
.w990px {
  width: 990px;
}
.w995px {
  width: 995px;
}
.w1000px {
  width: 1000px;
}
.row.compact {
  margin-left: -5px;
  margin-right: -5px;
}
.row.compact .col.compact {
  padding-left: 5px;
  padding-right: 5px;
}
.well {
  border-radius: 0;
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  background-color: #f5f5f5;
}
.stacking {
  margin-top: 5px;
  margin-bottom: 5px;
}
.btn,
a:link,
button {
  -webkit-tap-highlight-color: #d1e9f9;
}
.btn {
  border-radius: 4px;
  border: none;
  transition: background-color 200ms ease-out;
}
.btn[disabled]:active,
.btn.disabled:active {
  top: 0;
  left: 0;
}
.btn.btn-default {
  background-color: #eeeeee;
}
.btn.btn-default[disabled],
.btn.btn-default.disabled,
.btn.btn-default[disabled]:hover,
.btn.btn-default.disabled:hover,
.btn.btn-default[disabled]:active,
.btn.btn-default.disabled:active,
.btn.btn-default[disabled]:focus,
.btn.btn-default.disabled:focus {
  background-color: #efefef;
  cursor: default;
}
.btn.btn-primary {
  color: #fff !important;
  background-color: #54b7ff;
}
.btn.btn-primary:not([disabled]):hover,
.btn.btn-primary:not([disabled]):active,
.btn.btn-primary:not([disabled]):focus {
  background-color: #0086e6;
}
.btn.btn-primary[disabled],
.btn.btn-primary[disabled]:hover,
.btn.btn-primary[disabled]:active,
.btn.btn-primary[disabled]:focus {
  background-color: #d1e9f9;
  cursor: default;
}
.btn.btn-dropdown.dropdown-toggle {
  padding-top: 2px;
}
.btn.btn-lg.btn-labeled .btn-label {
  left: -16px;
  padding: 12px 16px;
}
.btn.btn-link {
  font-size: 1em;
  line-height: 1em;
  padding-left: 0;
  padding-right: 0;
}
.btn.btn-link,
.btn.btn-link:active {
  position: static;
  transform: translateY(-1px) !important;
}
.btn.btn-link[disabled] {
  cursor: default;
}
.btn.btn-link[disabled]:hover {
  color: #3276b1;
}
.smart-form .btn.form-button {
  box-sizing: content-box;
  padding: 6px 12px;
}
.dropdown-menu {
  border-radius: 4px;
}
.dropdown-menu .scroll {
  overflow-y: auto;
  max-height: 150px;
}
.dropdown-menu .scroll-toggle {
  color: #54b7ff;
}
.dropdown-menu .scroll-toggle .fa {
  transition: transform 200ms linear;
}
.dropdown-menu .scroll-toggle:hover {
  color: #0086e6;
}
.dropdown-menu .scroll-toggle:hover .fa {
  transform: scale(1.5);
}
.dropdown-menu .scroll-toggle.disabled {
  color: #999;
}
.dropdown-menu .scroll-toggle.disabled:hover .fa {
  transform: none;
}
.dropdown-menu .scroll-toggle:first-child {
  padding-top: 5px;
  margin-top: -5px;
}
.dropdown-menu li {
  list-style-type: none;
  width: 100%;
}
.dropdown-menu li a {
  width: 100%;
  box-sizing: border-box;
}
.dropdown-menu li ul {
  margin-left: -40px;
}
.dropdown-menu li ul li a {
  display: block;
  width: 100%;
  padding: 3px 20px;
  color: #404040;
}
.dropdown-menu li.active > a,
.dropdown-menu li:hover > a,
.dropdown-menu li.active > a:hover {
  background-color: #54b7ff;
}
.dropdown-menu li.active > a,
.dropdown-menu li:hover > a,
.dropdown-menu li.active > a:hover,
.dropdown-menu li.active > a .txt-color-primary,
.dropdown-menu li:hover > a .txt-color-primary,
.dropdown-menu li.active > a:hover .txt-color-primary,
.dropdown-menu li.active > a .txt-color,
.dropdown-menu li:hover > a .txt-color,
.dropdown-menu li.active > a:hover .txt-color {
  color: #ffffff !important;
}
.dropdown-menu::-webkit-scrollbar,
.dropdown-menu::-webkit-scrollbar-track,
.dropdown-menu::-webkit-scrollbar-thumb {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.dropdown-menu .dropdown-toggle .fa-caret-right {
  transition: transform 100ms linear;
}
.dropdown-menu .dropdown-toggle .fa-caret-right.open {
  transform: rotate(90deg);
}
.btn-group .btn-default:not(:first-child),
.btn-group .btn-default:hover:not(:first-child),
.btn-group .btn-default:active:not(:first-child) {
  border-left: 1px solid #fbfbfb;
}
.btn-group .btn-default:not(:last-child),
.btn-group .btn-default:hover:not(:last-child),
.btn-group .btn-default:active:not(:last-child) {
  border-right: 1px solid #fbfbfb;
}
.txt-color-transparent {
  color: transparent;
}
.txt-color-primary {
  color: #54b7ff !important;
}
.txt-color-primaryDark {
  color: #0086e6 !important;
}
.txt-color-primaryLight {
  color: #d1e9f9 !important;
}
.bold {
  font-weight: bold !important;
}
.demi {
  font-weight: 500 !important;
}
.italic {
  font-style: italic !important;
}
.light {
  color: #999 !important;
}
.larger {
  font-size: larger;
}
.smaller {
  font-size: smaller;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bg-white {
  background-color: #ffffff;
}
.animated.fast {
  animation-duration: 300ms;
}
.page-title {
  margin-top: -3px;
  border-bottom: 3px solid #f5f5f5;
  margin-bottom: 25px;
}
#page-title {
  border-bottom: 3px solid #54b7ff;
  font-size: 24px;
  padding-bottom: 3px;
  margin-bottom: -3px;
}
.click-me,
.btn-inline {
  cursor: pointer;
}
.click-me i,
.btn-inline i {
  color: #54b7ff;
  transition: color 200ms ease;
}
.click-me:hover i,
.btn-inline:hover i,
.click-me:focus i,
.btn-inline:focus i,
.click-me:active i,
.btn-inline:active i {
  color: #0086e6;
}
.click-me:hover .light,
.btn-inline:hover .light,
.click-me:focus .light,
.btn-inline:focus .light,
.click-me:active .light,
.btn-inline:active .light,
.click-me:hover.light,
.btn-inline:hover.light,
.click-me:focus.light,
.btn-inline:focus.light,
.click-me:active.light,
.btn-inline:active.light,
.click-me:hover .small,
.btn-inline:hover .small,
.click-me:focus .small,
.btn-inline:focus .small,
.click-me:active .small,
.btn-inline:active .small,
.click-me:hover.small,
.btn-inline:hover.small,
.click-me:focus.small,
.btn-inline:focus.small,
.click-me:active.small,
.btn-inline:active.small {
  color: #666 !important;
}
.click-me .light,
.btn-inline .light,
.click-me.light,
.btn-inline.light {
  color: #666 !important;
}
.click-me.disabled,
.btn-inline.disabled {
  cursor: default;
}
.click-me.disabled i,
.btn-inline.disabled i {
  color: #d1e9f9 !important;
}
.click-me.disabled,
.btn-inline.disabled,
.click-me.disabled .light,
.btn-inline.disabled .light,
.click-me.disabled.light,
.btn-inline.disabled.light,
.click-me.disabled .small,
.btn-inline.disabled .small,
.click-me.disabled.small,
.btn-inline.disabled.small {
  color: #999 !important;
}
.loading img {
  display: inline-block;
  margin: 50px auto;
}
table.vertical-center td {
  vertical-align: middle;
}
.alert .close {
  top: -10px;
}
.alert .btn {
  border: none;
  transition: background-color 200ms ease-out;
}
.alert .btn.btn-info {
  background-color: #9cb4c5;
}
.alert .btn.btn-info:hover {
  background-color: #6c8fa9;
}
.alert a {
  text-decoration: underline;
}
.alert.alert-danger .btn-link {
  font-size: 1em;
  line-height: 1em;
  transform: translateY(-1px);
  text-decoration: underline;
  color: #fff;
}
.alert.alert-danger .btn-link:hover {
  opacity: 0.85;
}
.alert.alert-info .click-me {
  color: #555;
  font-size: smaller;
}
.alert.alert-info .click-me i {
  font-size: normal;
  color: #305d8c;
}
.alert.alert-info .click-me:hover {
  color: #333;
}
.alert.alert-info .click-me:hover i {
  color: #204060;
}
.relative {
  position: relative;
}
.fill-height {
  min-height: calc(100vh - 224px);
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 601px) {
  .visible-mobile {
    display: none !important;
  }
}
@media only screen and (max-width: 600px) {
  .hidden-mobile {
    display: none !important;
  }
  body > bs-dropdown-container {
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    height: 100%;
    width: 100%;
    z-index: 1000;
  }
  body > bs-dropdown-container .dropdown.open {
    position: absolute;
    width: 100%;
    height: 150px;
    bottom: 0;
  }
  body > bs-dropdown-container .dropdown.open > .dropdown-menu {
    animation-name: fadeInUp;
    top: 0;
    width: 100%;
    height: 150px;
    border: none;
    border-top: 3px solid #ddd;
    border-radius: 0;
  }
  body > bs-dropdown-container .dropdown.open > .dropdown-menu::-webkit-scrollbar,
  body > bs-dropdown-container .dropdown.open > .dropdown-menu::-webkit-scrollbar-track,
  body > bs-dropdown-container .dropdown.open > .dropdown-menu::-webkit-scrollbar-thumb {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .padding-mobile-0 {
    padding: 0px !important;
  }
  .padding-mobile-top-0 {
    padding-top: 0px !important;
  }
  .padding-mobile-right-0 {
    padding-right: 0px !important;
  }
  .padding-mobile-bottom-0 {
    padding-bottom: 0px !important;
  }
  .padding-mobile-left-0 {
    padding-left: 0px !important;
  }
  .padding-mobile-neg-0 {
    padding: 0px !important;
  }
  .padding-mobile-top-neg-0 {
    padding-top: 0px !important;
  }
  .padding-mobile-right-neg-0 {
    padding-right: 0px !important;
  }
  .padding-mobile-bottom-neg-0 {
    padding-bottom: 0px !important;
  }
  .padding-mobile-left-neg-0 {
    padding-left: 0px !important;
  }
  .padding-mobile-5 {
    padding: 5px !important;
  }
  .padding-mobile-top-5 {
    padding-top: 5px !important;
  }
  .padding-mobile-right-5 {
    padding-right: 5px !important;
  }
  .padding-mobile-bottom-5 {
    padding-bottom: 5px !important;
  }
  .padding-mobile-left-5 {
    padding-left: 5px !important;
  }
  .padding-mobile-neg-5 {
    padding: 5px !important;
  }
  .padding-mobile-top-neg-5 {
    padding-top: 5px !important;
  }
  .padding-mobile-right-neg-5 {
    padding-right: 5px !important;
  }
  .padding-mobile-bottom-neg-5 {
    padding-bottom: 5px !important;
  }
  .padding-mobile-left-neg-5 {
    padding-left: 5px !important;
  }
  .padding-mobile-10 {
    padding: 10px !important;
  }
  .padding-mobile-top-10 {
    padding-top: 10px !important;
  }
  .padding-mobile-right-10 {
    padding-right: 10px !important;
  }
  .padding-mobile-bottom-10 {
    padding-bottom: 10px !important;
  }
  .padding-mobile-left-10 {
    padding-left: 10px !important;
  }
  .padding-mobile-neg-10 {
    padding: 10px !important;
  }
  .padding-mobile-top-neg-10 {
    padding-top: 10px !important;
  }
  .padding-mobile-right-neg-10 {
    padding-right: 10px !important;
  }
  .padding-mobile-bottom-neg-10 {
    padding-bottom: 10px !important;
  }
  .padding-mobile-left-neg-10 {
    padding-left: 10px !important;
  }
  .padding-mobile-15 {
    padding: 15px !important;
  }
  .padding-mobile-top-15 {
    padding-top: 15px !important;
  }
  .padding-mobile-right-15 {
    padding-right: 15px !important;
  }
  .padding-mobile-bottom-15 {
    padding-bottom: 15px !important;
  }
  .padding-mobile-left-15 {
    padding-left: 15px !important;
  }
  .padding-mobile-neg-15 {
    padding: 15px !important;
  }
  .padding-mobile-top-neg-15 {
    padding-top: 15px !important;
  }
  .padding-mobile-right-neg-15 {
    padding-right: 15px !important;
  }
  .padding-mobile-bottom-neg-15 {
    padding-bottom: 15px !important;
  }
  .padding-mobile-left-neg-15 {
    padding-left: 15px !important;
  }
  .padding-mobile-20 {
    padding: 20px !important;
  }
  .padding-mobile-top-20 {
    padding-top: 20px !important;
  }
  .padding-mobile-right-20 {
    padding-right: 20px !important;
  }
  .padding-mobile-bottom-20 {
    padding-bottom: 20px !important;
  }
  .padding-mobile-left-20 {
    padding-left: 20px !important;
  }
  .padding-mobile-neg-20 {
    padding: 20px !important;
  }
  .padding-mobile-top-neg-20 {
    padding-top: 20px !important;
  }
  .padding-mobile-right-neg-20 {
    padding-right: 20px !important;
  }
  .padding-mobile-bottom-neg-20 {
    padding-bottom: 20px !important;
  }
  .padding-mobile-left-neg-20 {
    padding-left: 20px !important;
  }
  .padding-mobile-25 {
    padding: 25px !important;
  }
  .padding-mobile-top-25 {
    padding-top: 25px !important;
  }
  .padding-mobile-right-25 {
    padding-right: 25px !important;
  }
  .padding-mobile-bottom-25 {
    padding-bottom: 25px !important;
  }
  .padding-mobile-left-25 {
    padding-left: 25px !important;
  }
  .padding-mobile-neg-25 {
    padding: 25px !important;
  }
  .padding-mobile-top-neg-25 {
    padding-top: 25px !important;
  }
  .padding-mobile-right-neg-25 {
    padding-right: 25px !important;
  }
  .padding-mobile-bottom-neg-25 {
    padding-bottom: 25px !important;
  }
  .padding-mobile-left-neg-25 {
    padding-left: 25px !important;
  }
  .padding-mobile-30 {
    padding: 30px !important;
  }
  .padding-mobile-top-30 {
    padding-top: 30px !important;
  }
  .padding-mobile-right-30 {
    padding-right: 30px !important;
  }
  .padding-mobile-bottom-30 {
    padding-bottom: 30px !important;
  }
  .padding-mobile-left-30 {
    padding-left: 30px !important;
  }
  .padding-mobile-neg-30 {
    padding: 30px !important;
  }
  .padding-mobile-top-neg-30 {
    padding-top: 30px !important;
  }
  .padding-mobile-right-neg-30 {
    padding-right: 30px !important;
  }
  .padding-mobile-bottom-neg-30 {
    padding-bottom: 30px !important;
  }
  .padding-mobile-left-neg-30 {
    padding-left: 30px !important;
  }
  .padding-mobile-35 {
    padding: 35px !important;
  }
  .padding-mobile-top-35 {
    padding-top: 35px !important;
  }
  .padding-mobile-right-35 {
    padding-right: 35px !important;
  }
  .padding-mobile-bottom-35 {
    padding-bottom: 35px !important;
  }
  .padding-mobile-left-35 {
    padding-left: 35px !important;
  }
  .padding-mobile-neg-35 {
    padding: 35px !important;
  }
  .padding-mobile-top-neg-35 {
    padding-top: 35px !important;
  }
  .padding-mobile-right-neg-35 {
    padding-right: 35px !important;
  }
  .padding-mobile-bottom-neg-35 {
    padding-bottom: 35px !important;
  }
  .padding-mobile-left-neg-35 {
    padding-left: 35px !important;
  }
  .padding-mobile-40 {
    padding: 40px !important;
  }
  .padding-mobile-top-40 {
    padding-top: 40px !important;
  }
  .padding-mobile-right-40 {
    padding-right: 40px !important;
  }
  .padding-mobile-bottom-40 {
    padding-bottom: 40px !important;
  }
  .padding-mobile-left-40 {
    padding-left: 40px !important;
  }
  .padding-mobile-neg-40 {
    padding: 40px !important;
  }
  .padding-mobile-top-neg-40 {
    padding-top: 40px !important;
  }
  .padding-mobile-right-neg-40 {
    padding-right: 40px !important;
  }
  .padding-mobile-bottom-neg-40 {
    padding-bottom: 40px !important;
  }
  .padding-mobile-left-neg-40 {
    padding-left: 40px !important;
  }
  .padding-mobile-45 {
    padding: 45px !important;
  }
  .padding-mobile-top-45 {
    padding-top: 45px !important;
  }
  .padding-mobile-right-45 {
    padding-right: 45px !important;
  }
  .padding-mobile-bottom-45 {
    padding-bottom: 45px !important;
  }
  .padding-mobile-left-45 {
    padding-left: 45px !important;
  }
  .padding-mobile-neg-45 {
    padding: 45px !important;
  }
  .padding-mobile-top-neg-45 {
    padding-top: 45px !important;
  }
  .padding-mobile-right-neg-45 {
    padding-right: 45px !important;
  }
  .padding-mobile-bottom-neg-45 {
    padding-bottom: 45px !important;
  }
  .padding-mobile-left-neg-45 {
    padding-left: 45px !important;
  }
  .padding-mobile-50 {
    padding: 50px !important;
  }
  .padding-mobile-top-50 {
    padding-top: 50px !important;
  }
  .padding-mobile-right-50 {
    padding-right: 50px !important;
  }
  .padding-mobile-bottom-50 {
    padding-bottom: 50px !important;
  }
  .padding-mobile-left-50 {
    padding-left: 50px !important;
  }
  .padding-mobile-neg-50 {
    padding: 50px !important;
  }
  .padding-mobile-top-neg-50 {
    padding-top: 50px !important;
  }
  .padding-mobile-right-neg-50 {
    padding-right: 50px !important;
  }
  .padding-mobile-bottom-neg-50 {
    padding-bottom: 50px !important;
  }
  .padding-mobile-left-neg-50 {
    padding-left: 50px !important;
  }
  .padding-mobile-55 {
    padding: 55px !important;
  }
  .padding-mobile-top-55 {
    padding-top: 55px !important;
  }
  .padding-mobile-right-55 {
    padding-right: 55px !important;
  }
  .padding-mobile-bottom-55 {
    padding-bottom: 55px !important;
  }
  .padding-mobile-left-55 {
    padding-left: 55px !important;
  }
  .padding-mobile-neg-55 {
    padding: 55px !important;
  }
  .padding-mobile-top-neg-55 {
    padding-top: 55px !important;
  }
  .padding-mobile-right-neg-55 {
    padding-right: 55px !important;
  }
  .padding-mobile-bottom-neg-55 {
    padding-bottom: 55px !important;
  }
  .padding-mobile-left-neg-55 {
    padding-left: 55px !important;
  }
  .padding-mobile-60 {
    padding: 60px !important;
  }
  .padding-mobile-top-60 {
    padding-top: 60px !important;
  }
  .padding-mobile-right-60 {
    padding-right: 60px !important;
  }
  .padding-mobile-bottom-60 {
    padding-bottom: 60px !important;
  }
  .padding-mobile-left-60 {
    padding-left: 60px !important;
  }
  .padding-mobile-neg-60 {
    padding: 60px !important;
  }
  .padding-mobile-top-neg-60 {
    padding-top: 60px !important;
  }
  .padding-mobile-right-neg-60 {
    padding-right: 60px !important;
  }
  .padding-mobile-bottom-neg-60 {
    padding-bottom: 60px !important;
  }
  .padding-mobile-left-neg-60 {
    padding-left: 60px !important;
  }
  .padding-mobile-65 {
    padding: 65px !important;
  }
  .padding-mobile-top-65 {
    padding-top: 65px !important;
  }
  .padding-mobile-right-65 {
    padding-right: 65px !important;
  }
  .padding-mobile-bottom-65 {
    padding-bottom: 65px !important;
  }
  .padding-mobile-left-65 {
    padding-left: 65px !important;
  }
  .padding-mobile-neg-65 {
    padding: 65px !important;
  }
  .padding-mobile-top-neg-65 {
    padding-top: 65px !important;
  }
  .padding-mobile-right-neg-65 {
    padding-right: 65px !important;
  }
  .padding-mobile-bottom-neg-65 {
    padding-bottom: 65px !important;
  }
  .padding-mobile-left-neg-65 {
    padding-left: 65px !important;
  }
  .padding-mobile-70 {
    padding: 70px !important;
  }
  .padding-mobile-top-70 {
    padding-top: 70px !important;
  }
  .padding-mobile-right-70 {
    padding-right: 70px !important;
  }
  .padding-mobile-bottom-70 {
    padding-bottom: 70px !important;
  }
  .padding-mobile-left-70 {
    padding-left: 70px !important;
  }
  .padding-mobile-neg-70 {
    padding: 70px !important;
  }
  .padding-mobile-top-neg-70 {
    padding-top: 70px !important;
  }
  .padding-mobile-right-neg-70 {
    padding-right: 70px !important;
  }
  .padding-mobile-bottom-neg-70 {
    padding-bottom: 70px !important;
  }
  .padding-mobile-left-neg-70 {
    padding-left: 70px !important;
  }
  .padding-mobile-75 {
    padding: 75px !important;
  }
  .padding-mobile-top-75 {
    padding-top: 75px !important;
  }
  .padding-mobile-right-75 {
    padding-right: 75px !important;
  }
  .padding-mobile-bottom-75 {
    padding-bottom: 75px !important;
  }
  .padding-mobile-left-75 {
    padding-left: 75px !important;
  }
  .padding-mobile-neg-75 {
    padding: 75px !important;
  }
  .padding-mobile-top-neg-75 {
    padding-top: 75px !important;
  }
  .padding-mobile-right-neg-75 {
    padding-right: 75px !important;
  }
  .padding-mobile-bottom-neg-75 {
    padding-bottom: 75px !important;
  }
  .padding-mobile-left-neg-75 {
    padding-left: 75px !important;
  }
  .padding-mobile-80 {
    padding: 80px !important;
  }
  .padding-mobile-top-80 {
    padding-top: 80px !important;
  }
  .padding-mobile-right-80 {
    padding-right: 80px !important;
  }
  .padding-mobile-bottom-80 {
    padding-bottom: 80px !important;
  }
  .padding-mobile-left-80 {
    padding-left: 80px !important;
  }
  .padding-mobile-neg-80 {
    padding: 80px !important;
  }
  .padding-mobile-top-neg-80 {
    padding-top: 80px !important;
  }
  .padding-mobile-right-neg-80 {
    padding-right: 80px !important;
  }
  .padding-mobile-bottom-neg-80 {
    padding-bottom: 80px !important;
  }
  .padding-mobile-left-neg-80 {
    padding-left: 80px !important;
  }
  .padding-mobile-85 {
    padding: 85px !important;
  }
  .padding-mobile-top-85 {
    padding-top: 85px !important;
  }
  .padding-mobile-right-85 {
    padding-right: 85px !important;
  }
  .padding-mobile-bottom-85 {
    padding-bottom: 85px !important;
  }
  .padding-mobile-left-85 {
    padding-left: 85px !important;
  }
  .padding-mobile-neg-85 {
    padding: 85px !important;
  }
  .padding-mobile-top-neg-85 {
    padding-top: 85px !important;
  }
  .padding-mobile-right-neg-85 {
    padding-right: 85px !important;
  }
  .padding-mobile-bottom-neg-85 {
    padding-bottom: 85px !important;
  }
  .padding-mobile-left-neg-85 {
    padding-left: 85px !important;
  }
  .padding-mobile-90 {
    padding: 90px !important;
  }
  .padding-mobile-top-90 {
    padding-top: 90px !important;
  }
  .padding-mobile-right-90 {
    padding-right: 90px !important;
  }
  .padding-mobile-bottom-90 {
    padding-bottom: 90px !important;
  }
  .padding-mobile-left-90 {
    padding-left: 90px !important;
  }
  .padding-mobile-neg-90 {
    padding: 90px !important;
  }
  .padding-mobile-top-neg-90 {
    padding-top: 90px !important;
  }
  .padding-mobile-right-neg-90 {
    padding-right: 90px !important;
  }
  .padding-mobile-bottom-neg-90 {
    padding-bottom: 90px !important;
  }
  .padding-mobile-left-neg-90 {
    padding-left: 90px !important;
  }
  .padding-mobile-95 {
    padding: 95px !important;
  }
  .padding-mobile-top-95 {
    padding-top: 95px !important;
  }
  .padding-mobile-right-95 {
    padding-right: 95px !important;
  }
  .padding-mobile-bottom-95 {
    padding-bottom: 95px !important;
  }
  .padding-mobile-left-95 {
    padding-left: 95px !important;
  }
  .padding-mobile-neg-95 {
    padding: 95px !important;
  }
  .padding-mobile-top-neg-95 {
    padding-top: 95px !important;
  }
  .padding-mobile-right-neg-95 {
    padding-right: 95px !important;
  }
  .padding-mobile-bottom-neg-95 {
    padding-bottom: 95px !important;
  }
  .padding-mobile-left-neg-95 {
    padding-left: 95px !important;
  }
  .padding-mobile-100 {
    padding: 100px !important;
  }
  .padding-mobile-top-100 {
    padding-top: 100px !important;
  }
  .padding-mobile-right-100 {
    padding-right: 100px !important;
  }
  .padding-mobile-bottom-100 {
    padding-bottom: 100px !important;
  }
  .padding-mobile-left-100 {
    padding-left: 100px !important;
  }
  .padding-mobile-neg-100 {
    padding: 100px !important;
  }
  .padding-mobile-top-neg-100 {
    padding-top: 100px !important;
  }
  .padding-mobile-right-neg-100 {
    padding-right: 100px !important;
  }
  .padding-mobile-bottom-neg-100 {
    padding-bottom: 100px !important;
  }
  .padding-mobile-left-neg-100 {
    padding-left: 100px !important;
  }
  .margin-mobile-0 {
    margin: 0px !important;
  }
  .margin-mobile-top-0 {
    margin-top: 0px !important;
  }
  .margin-mobile-right-0 {
    margin-right: 0px !important;
  }
  .margin-mobile-bottom-0 {
    margin-bottom: 0px !important;
  }
  .margin-mobile-left-0 {
    margin-left: 0px !important;
  }
  .margin-mobile-neg-0 {
    margin: 0px !important;
  }
  .margin-mobile-top-neg-0 {
    margin-top: 0px !important;
  }
  .margin-mobile-right-neg-0 {
    margin-right: 0px !important;
  }
  .margin-mobile-bottom-neg-0 {
    margin-bottom: 0px !important;
  }
  .margin-mobile-left-neg-0 {
    margin-left: 0px !important;
  }
  .margin-mobile-5 {
    margin: 5px !important;
  }
  .margin-mobile-top-5 {
    margin-top: 5px !important;
  }
  .margin-mobile-right-5 {
    margin-right: 5px !important;
  }
  .margin-mobile-bottom-5 {
    margin-bottom: 5px !important;
  }
  .margin-mobile-left-5 {
    margin-left: 5px !important;
  }
  .margin-mobile-neg-5 {
    margin: 5px !important;
  }
  .margin-mobile-top-neg-5 {
    margin-top: 5px !important;
  }
  .margin-mobile-right-neg-5 {
    margin-right: 5px !important;
  }
  .margin-mobile-bottom-neg-5 {
    margin-bottom: 5px !important;
  }
  .margin-mobile-left-neg-5 {
    margin-left: 5px !important;
  }
  .margin-mobile-10 {
    margin: 10px !important;
  }
  .margin-mobile-top-10 {
    margin-top: 10px !important;
  }
  .margin-mobile-right-10 {
    margin-right: 10px !important;
  }
  .margin-mobile-bottom-10 {
    margin-bottom: 10px !important;
  }
  .margin-mobile-left-10 {
    margin-left: 10px !important;
  }
  .margin-mobile-neg-10 {
    margin: 10px !important;
  }
  .margin-mobile-top-neg-10 {
    margin-top: 10px !important;
  }
  .margin-mobile-right-neg-10 {
    margin-right: 10px !important;
  }
  .margin-mobile-bottom-neg-10 {
    margin-bottom: 10px !important;
  }
  .margin-mobile-left-neg-10 {
    margin-left: 10px !important;
  }
  .margin-mobile-15 {
    margin: 15px !important;
  }
  .margin-mobile-top-15 {
    margin-top: 15px !important;
  }
  .margin-mobile-right-15 {
    margin-right: 15px !important;
  }
  .margin-mobile-bottom-15 {
    margin-bottom: 15px !important;
  }
  .margin-mobile-left-15 {
    margin-left: 15px !important;
  }
  .margin-mobile-neg-15 {
    margin: 15px !important;
  }
  .margin-mobile-top-neg-15 {
    margin-top: 15px !important;
  }
  .margin-mobile-right-neg-15 {
    margin-right: 15px !important;
  }
  .margin-mobile-bottom-neg-15 {
    margin-bottom: 15px !important;
  }
  .margin-mobile-left-neg-15 {
    margin-left: 15px !important;
  }
  .margin-mobile-20 {
    margin: 20px !important;
  }
  .margin-mobile-top-20 {
    margin-top: 20px !important;
  }
  .margin-mobile-right-20 {
    margin-right: 20px !important;
  }
  .margin-mobile-bottom-20 {
    margin-bottom: 20px !important;
  }
  .margin-mobile-left-20 {
    margin-left: 20px !important;
  }
  .margin-mobile-neg-20 {
    margin: 20px !important;
  }
  .margin-mobile-top-neg-20 {
    margin-top: 20px !important;
  }
  .margin-mobile-right-neg-20 {
    margin-right: 20px !important;
  }
  .margin-mobile-bottom-neg-20 {
    margin-bottom: 20px !important;
  }
  .margin-mobile-left-neg-20 {
    margin-left: 20px !important;
  }
  .margin-mobile-25 {
    margin: 25px !important;
  }
  .margin-mobile-top-25 {
    margin-top: 25px !important;
  }
  .margin-mobile-right-25 {
    margin-right: 25px !important;
  }
  .margin-mobile-bottom-25 {
    margin-bottom: 25px !important;
  }
  .margin-mobile-left-25 {
    margin-left: 25px !important;
  }
  .margin-mobile-neg-25 {
    margin: 25px !important;
  }
  .margin-mobile-top-neg-25 {
    margin-top: 25px !important;
  }
  .margin-mobile-right-neg-25 {
    margin-right: 25px !important;
  }
  .margin-mobile-bottom-neg-25 {
    margin-bottom: 25px !important;
  }
  .margin-mobile-left-neg-25 {
    margin-left: 25px !important;
  }
  .margin-mobile-30 {
    margin: 30px !important;
  }
  .margin-mobile-top-30 {
    margin-top: 30px !important;
  }
  .margin-mobile-right-30 {
    margin-right: 30px !important;
  }
  .margin-mobile-bottom-30 {
    margin-bottom: 30px !important;
  }
  .margin-mobile-left-30 {
    margin-left: 30px !important;
  }
  .margin-mobile-neg-30 {
    margin: 30px !important;
  }
  .margin-mobile-top-neg-30 {
    margin-top: 30px !important;
  }
  .margin-mobile-right-neg-30 {
    margin-right: 30px !important;
  }
  .margin-mobile-bottom-neg-30 {
    margin-bottom: 30px !important;
  }
  .margin-mobile-left-neg-30 {
    margin-left: 30px !important;
  }
  .margin-mobile-35 {
    margin: 35px !important;
  }
  .margin-mobile-top-35 {
    margin-top: 35px !important;
  }
  .margin-mobile-right-35 {
    margin-right: 35px !important;
  }
  .margin-mobile-bottom-35 {
    margin-bottom: 35px !important;
  }
  .margin-mobile-left-35 {
    margin-left: 35px !important;
  }
  .margin-mobile-neg-35 {
    margin: 35px !important;
  }
  .margin-mobile-top-neg-35 {
    margin-top: 35px !important;
  }
  .margin-mobile-right-neg-35 {
    margin-right: 35px !important;
  }
  .margin-mobile-bottom-neg-35 {
    margin-bottom: 35px !important;
  }
  .margin-mobile-left-neg-35 {
    margin-left: 35px !important;
  }
  .margin-mobile-40 {
    margin: 40px !important;
  }
  .margin-mobile-top-40 {
    margin-top: 40px !important;
  }
  .margin-mobile-right-40 {
    margin-right: 40px !important;
  }
  .margin-mobile-bottom-40 {
    margin-bottom: 40px !important;
  }
  .margin-mobile-left-40 {
    margin-left: 40px !important;
  }
  .margin-mobile-neg-40 {
    margin: 40px !important;
  }
  .margin-mobile-top-neg-40 {
    margin-top: 40px !important;
  }
  .margin-mobile-right-neg-40 {
    margin-right: 40px !important;
  }
  .margin-mobile-bottom-neg-40 {
    margin-bottom: 40px !important;
  }
  .margin-mobile-left-neg-40 {
    margin-left: 40px !important;
  }
  .margin-mobile-45 {
    margin: 45px !important;
  }
  .margin-mobile-top-45 {
    margin-top: 45px !important;
  }
  .margin-mobile-right-45 {
    margin-right: 45px !important;
  }
  .margin-mobile-bottom-45 {
    margin-bottom: 45px !important;
  }
  .margin-mobile-left-45 {
    margin-left: 45px !important;
  }
  .margin-mobile-neg-45 {
    margin: 45px !important;
  }
  .margin-mobile-top-neg-45 {
    margin-top: 45px !important;
  }
  .margin-mobile-right-neg-45 {
    margin-right: 45px !important;
  }
  .margin-mobile-bottom-neg-45 {
    margin-bottom: 45px !important;
  }
  .margin-mobile-left-neg-45 {
    margin-left: 45px !important;
  }
  .margin-mobile-50 {
    margin: 50px !important;
  }
  .margin-mobile-top-50 {
    margin-top: 50px !important;
  }
  .margin-mobile-right-50 {
    margin-right: 50px !important;
  }
  .margin-mobile-bottom-50 {
    margin-bottom: 50px !important;
  }
  .margin-mobile-left-50 {
    margin-left: 50px !important;
  }
  .margin-mobile-neg-50 {
    margin: 50px !important;
  }
  .margin-mobile-top-neg-50 {
    margin-top: 50px !important;
  }
  .margin-mobile-right-neg-50 {
    margin-right: 50px !important;
  }
  .margin-mobile-bottom-neg-50 {
    margin-bottom: 50px !important;
  }
  .margin-mobile-left-neg-50 {
    margin-left: 50px !important;
  }
  .margin-mobile-55 {
    margin: 55px !important;
  }
  .margin-mobile-top-55 {
    margin-top: 55px !important;
  }
  .margin-mobile-right-55 {
    margin-right: 55px !important;
  }
  .margin-mobile-bottom-55 {
    margin-bottom: 55px !important;
  }
  .margin-mobile-left-55 {
    margin-left: 55px !important;
  }
  .margin-mobile-neg-55 {
    margin: 55px !important;
  }
  .margin-mobile-top-neg-55 {
    margin-top: 55px !important;
  }
  .margin-mobile-right-neg-55 {
    margin-right: 55px !important;
  }
  .margin-mobile-bottom-neg-55 {
    margin-bottom: 55px !important;
  }
  .margin-mobile-left-neg-55 {
    margin-left: 55px !important;
  }
  .margin-mobile-60 {
    margin: 60px !important;
  }
  .margin-mobile-top-60 {
    margin-top: 60px !important;
  }
  .margin-mobile-right-60 {
    margin-right: 60px !important;
  }
  .margin-mobile-bottom-60 {
    margin-bottom: 60px !important;
  }
  .margin-mobile-left-60 {
    margin-left: 60px !important;
  }
  .margin-mobile-neg-60 {
    margin: 60px !important;
  }
  .margin-mobile-top-neg-60 {
    margin-top: 60px !important;
  }
  .margin-mobile-right-neg-60 {
    margin-right: 60px !important;
  }
  .margin-mobile-bottom-neg-60 {
    margin-bottom: 60px !important;
  }
  .margin-mobile-left-neg-60 {
    margin-left: 60px !important;
  }
  .margin-mobile-65 {
    margin: 65px !important;
  }
  .margin-mobile-top-65 {
    margin-top: 65px !important;
  }
  .margin-mobile-right-65 {
    margin-right: 65px !important;
  }
  .margin-mobile-bottom-65 {
    margin-bottom: 65px !important;
  }
  .margin-mobile-left-65 {
    margin-left: 65px !important;
  }
  .margin-mobile-neg-65 {
    margin: 65px !important;
  }
  .margin-mobile-top-neg-65 {
    margin-top: 65px !important;
  }
  .margin-mobile-right-neg-65 {
    margin-right: 65px !important;
  }
  .margin-mobile-bottom-neg-65 {
    margin-bottom: 65px !important;
  }
  .margin-mobile-left-neg-65 {
    margin-left: 65px !important;
  }
  .margin-mobile-70 {
    margin: 70px !important;
  }
  .margin-mobile-top-70 {
    margin-top: 70px !important;
  }
  .margin-mobile-right-70 {
    margin-right: 70px !important;
  }
  .margin-mobile-bottom-70 {
    margin-bottom: 70px !important;
  }
  .margin-mobile-left-70 {
    margin-left: 70px !important;
  }
  .margin-mobile-neg-70 {
    margin: 70px !important;
  }
  .margin-mobile-top-neg-70 {
    margin-top: 70px !important;
  }
  .margin-mobile-right-neg-70 {
    margin-right: 70px !important;
  }
  .margin-mobile-bottom-neg-70 {
    margin-bottom: 70px !important;
  }
  .margin-mobile-left-neg-70 {
    margin-left: 70px !important;
  }
  .margin-mobile-75 {
    margin: 75px !important;
  }
  .margin-mobile-top-75 {
    margin-top: 75px !important;
  }
  .margin-mobile-right-75 {
    margin-right: 75px !important;
  }
  .margin-mobile-bottom-75 {
    margin-bottom: 75px !important;
  }
  .margin-mobile-left-75 {
    margin-left: 75px !important;
  }
  .margin-mobile-neg-75 {
    margin: 75px !important;
  }
  .margin-mobile-top-neg-75 {
    margin-top: 75px !important;
  }
  .margin-mobile-right-neg-75 {
    margin-right: 75px !important;
  }
  .margin-mobile-bottom-neg-75 {
    margin-bottom: 75px !important;
  }
  .margin-mobile-left-neg-75 {
    margin-left: 75px !important;
  }
  .margin-mobile-80 {
    margin: 80px !important;
  }
  .margin-mobile-top-80 {
    margin-top: 80px !important;
  }
  .margin-mobile-right-80 {
    margin-right: 80px !important;
  }
  .margin-mobile-bottom-80 {
    margin-bottom: 80px !important;
  }
  .margin-mobile-left-80 {
    margin-left: 80px !important;
  }
  .margin-mobile-neg-80 {
    margin: 80px !important;
  }
  .margin-mobile-top-neg-80 {
    margin-top: 80px !important;
  }
  .margin-mobile-right-neg-80 {
    margin-right: 80px !important;
  }
  .margin-mobile-bottom-neg-80 {
    margin-bottom: 80px !important;
  }
  .margin-mobile-left-neg-80 {
    margin-left: 80px !important;
  }
  .margin-mobile-85 {
    margin: 85px !important;
  }
  .margin-mobile-top-85 {
    margin-top: 85px !important;
  }
  .margin-mobile-right-85 {
    margin-right: 85px !important;
  }
  .margin-mobile-bottom-85 {
    margin-bottom: 85px !important;
  }
  .margin-mobile-left-85 {
    margin-left: 85px !important;
  }
  .margin-mobile-neg-85 {
    margin: 85px !important;
  }
  .margin-mobile-top-neg-85 {
    margin-top: 85px !important;
  }
  .margin-mobile-right-neg-85 {
    margin-right: 85px !important;
  }
  .margin-mobile-bottom-neg-85 {
    margin-bottom: 85px !important;
  }
  .margin-mobile-left-neg-85 {
    margin-left: 85px !important;
  }
  .margin-mobile-90 {
    margin: 90px !important;
  }
  .margin-mobile-top-90 {
    margin-top: 90px !important;
  }
  .margin-mobile-right-90 {
    margin-right: 90px !important;
  }
  .margin-mobile-bottom-90 {
    margin-bottom: 90px !important;
  }
  .margin-mobile-left-90 {
    margin-left: 90px !important;
  }
  .margin-mobile-neg-90 {
    margin: 90px !important;
  }
  .margin-mobile-top-neg-90 {
    margin-top: 90px !important;
  }
  .margin-mobile-right-neg-90 {
    margin-right: 90px !important;
  }
  .margin-mobile-bottom-neg-90 {
    margin-bottom: 90px !important;
  }
  .margin-mobile-left-neg-90 {
    margin-left: 90px !important;
  }
  .margin-mobile-95 {
    margin: 95px !important;
  }
  .margin-mobile-top-95 {
    margin-top: 95px !important;
  }
  .margin-mobile-right-95 {
    margin-right: 95px !important;
  }
  .margin-mobile-bottom-95 {
    margin-bottom: 95px !important;
  }
  .margin-mobile-left-95 {
    margin-left: 95px !important;
  }
  .margin-mobile-neg-95 {
    margin: 95px !important;
  }
  .margin-mobile-top-neg-95 {
    margin-top: 95px !important;
  }
  .margin-mobile-right-neg-95 {
    margin-right: 95px !important;
  }
  .margin-mobile-bottom-neg-95 {
    margin-bottom: 95px !important;
  }
  .margin-mobile-left-neg-95 {
    margin-left: 95px !important;
  }
  .margin-mobile-100 {
    margin: 100px !important;
  }
  .margin-mobile-top-100 {
    margin-top: 100px !important;
  }
  .margin-mobile-right-100 {
    margin-right: 100px !important;
  }
  .margin-mobile-bottom-100 {
    margin-bottom: 100px !important;
  }
  .margin-mobile-left-100 {
    margin-left: 100px !important;
  }
  .margin-mobile-neg-100 {
    margin: 100px !important;
  }
  .margin-mobile-top-neg-100 {
    margin-top: 100px !important;
  }
  .margin-mobile-right-neg-100 {
    margin-right: 100px !important;
  }
  .margin-mobile-bottom-neg-100 {
    margin-bottom: 100px !important;
  }
  .margin-mobile-left-neg-100 {
    margin-left: 100px !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 767px) {
  .padding-xs-0 {
    padding: 0px !important;
  }
  .padding-xs-top-0 {
    padding-top: 0px !important;
  }
  .padding-xs-right-0 {
    padding-right: 0px !important;
  }
  .padding-xs-bottom-0 {
    padding-bottom: 0px !important;
  }
  .padding-xs-left-0 {
    padding-left: 0px !important;
  }
  .padding-xs-neg-0 {
    padding: 0px !important;
  }
  .padding-xs-top-neg-0 {
    padding-top: 0px !important;
  }
  .padding-xs-right-neg-0 {
    padding-right: 0px !important;
  }
  .padding-xs-bottom-neg-0 {
    padding-bottom: 0px !important;
  }
  .padding-xs-left-neg-0 {
    padding-left: 0px !important;
  }
  .padding-xs-5 {
    padding: 5px !important;
  }
  .padding-xs-top-5 {
    padding-top: 5px !important;
  }
  .padding-xs-right-5 {
    padding-right: 5px !important;
  }
  .padding-xs-bottom-5 {
    padding-bottom: 5px !important;
  }
  .padding-xs-left-5 {
    padding-left: 5px !important;
  }
  .padding-xs-neg-5 {
    padding: 5px !important;
  }
  .padding-xs-top-neg-5 {
    padding-top: 5px !important;
  }
  .padding-xs-right-neg-5 {
    padding-right: 5px !important;
  }
  .padding-xs-bottom-neg-5 {
    padding-bottom: 5px !important;
  }
  .padding-xs-left-neg-5 {
    padding-left: 5px !important;
  }
  .padding-xs-10 {
    padding: 10px !important;
  }
  .padding-xs-top-10 {
    padding-top: 10px !important;
  }
  .padding-xs-right-10 {
    padding-right: 10px !important;
  }
  .padding-xs-bottom-10 {
    padding-bottom: 10px !important;
  }
  .padding-xs-left-10 {
    padding-left: 10px !important;
  }
  .padding-xs-neg-10 {
    padding: 10px !important;
  }
  .padding-xs-top-neg-10 {
    padding-top: 10px !important;
  }
  .padding-xs-right-neg-10 {
    padding-right: 10px !important;
  }
  .padding-xs-bottom-neg-10 {
    padding-bottom: 10px !important;
  }
  .padding-xs-left-neg-10 {
    padding-left: 10px !important;
  }
  .padding-xs-15 {
    padding: 15px !important;
  }
  .padding-xs-top-15 {
    padding-top: 15px !important;
  }
  .padding-xs-right-15 {
    padding-right: 15px !important;
  }
  .padding-xs-bottom-15 {
    padding-bottom: 15px !important;
  }
  .padding-xs-left-15 {
    padding-left: 15px !important;
  }
  .padding-xs-neg-15 {
    padding: 15px !important;
  }
  .padding-xs-top-neg-15 {
    padding-top: 15px !important;
  }
  .padding-xs-right-neg-15 {
    padding-right: 15px !important;
  }
  .padding-xs-bottom-neg-15 {
    padding-bottom: 15px !important;
  }
  .padding-xs-left-neg-15 {
    padding-left: 15px !important;
  }
  .padding-xs-20 {
    padding: 20px !important;
  }
  .padding-xs-top-20 {
    padding-top: 20px !important;
  }
  .padding-xs-right-20 {
    padding-right: 20px !important;
  }
  .padding-xs-bottom-20 {
    padding-bottom: 20px !important;
  }
  .padding-xs-left-20 {
    padding-left: 20px !important;
  }
  .padding-xs-neg-20 {
    padding: 20px !important;
  }
  .padding-xs-top-neg-20 {
    padding-top: 20px !important;
  }
  .padding-xs-right-neg-20 {
    padding-right: 20px !important;
  }
  .padding-xs-bottom-neg-20 {
    padding-bottom: 20px !important;
  }
  .padding-xs-left-neg-20 {
    padding-left: 20px !important;
  }
  .padding-xs-25 {
    padding: 25px !important;
  }
  .padding-xs-top-25 {
    padding-top: 25px !important;
  }
  .padding-xs-right-25 {
    padding-right: 25px !important;
  }
  .padding-xs-bottom-25 {
    padding-bottom: 25px !important;
  }
  .padding-xs-left-25 {
    padding-left: 25px !important;
  }
  .padding-xs-neg-25 {
    padding: 25px !important;
  }
  .padding-xs-top-neg-25 {
    padding-top: 25px !important;
  }
  .padding-xs-right-neg-25 {
    padding-right: 25px !important;
  }
  .padding-xs-bottom-neg-25 {
    padding-bottom: 25px !important;
  }
  .padding-xs-left-neg-25 {
    padding-left: 25px !important;
  }
  .padding-xs-30 {
    padding: 30px !important;
  }
  .padding-xs-top-30 {
    padding-top: 30px !important;
  }
  .padding-xs-right-30 {
    padding-right: 30px !important;
  }
  .padding-xs-bottom-30 {
    padding-bottom: 30px !important;
  }
  .padding-xs-left-30 {
    padding-left: 30px !important;
  }
  .padding-xs-neg-30 {
    padding: 30px !important;
  }
  .padding-xs-top-neg-30 {
    padding-top: 30px !important;
  }
  .padding-xs-right-neg-30 {
    padding-right: 30px !important;
  }
  .padding-xs-bottom-neg-30 {
    padding-bottom: 30px !important;
  }
  .padding-xs-left-neg-30 {
    padding-left: 30px !important;
  }
  .padding-xs-35 {
    padding: 35px !important;
  }
  .padding-xs-top-35 {
    padding-top: 35px !important;
  }
  .padding-xs-right-35 {
    padding-right: 35px !important;
  }
  .padding-xs-bottom-35 {
    padding-bottom: 35px !important;
  }
  .padding-xs-left-35 {
    padding-left: 35px !important;
  }
  .padding-xs-neg-35 {
    padding: 35px !important;
  }
  .padding-xs-top-neg-35 {
    padding-top: 35px !important;
  }
  .padding-xs-right-neg-35 {
    padding-right: 35px !important;
  }
  .padding-xs-bottom-neg-35 {
    padding-bottom: 35px !important;
  }
  .padding-xs-left-neg-35 {
    padding-left: 35px !important;
  }
  .padding-xs-40 {
    padding: 40px !important;
  }
  .padding-xs-top-40 {
    padding-top: 40px !important;
  }
  .padding-xs-right-40 {
    padding-right: 40px !important;
  }
  .padding-xs-bottom-40 {
    padding-bottom: 40px !important;
  }
  .padding-xs-left-40 {
    padding-left: 40px !important;
  }
  .padding-xs-neg-40 {
    padding: 40px !important;
  }
  .padding-xs-top-neg-40 {
    padding-top: 40px !important;
  }
  .padding-xs-right-neg-40 {
    padding-right: 40px !important;
  }
  .padding-xs-bottom-neg-40 {
    padding-bottom: 40px !important;
  }
  .padding-xs-left-neg-40 {
    padding-left: 40px !important;
  }
  .padding-xs-45 {
    padding: 45px !important;
  }
  .padding-xs-top-45 {
    padding-top: 45px !important;
  }
  .padding-xs-right-45 {
    padding-right: 45px !important;
  }
  .padding-xs-bottom-45 {
    padding-bottom: 45px !important;
  }
  .padding-xs-left-45 {
    padding-left: 45px !important;
  }
  .padding-xs-neg-45 {
    padding: 45px !important;
  }
  .padding-xs-top-neg-45 {
    padding-top: 45px !important;
  }
  .padding-xs-right-neg-45 {
    padding-right: 45px !important;
  }
  .padding-xs-bottom-neg-45 {
    padding-bottom: 45px !important;
  }
  .padding-xs-left-neg-45 {
    padding-left: 45px !important;
  }
  .padding-xs-50 {
    padding: 50px !important;
  }
  .padding-xs-top-50 {
    padding-top: 50px !important;
  }
  .padding-xs-right-50 {
    padding-right: 50px !important;
  }
  .padding-xs-bottom-50 {
    padding-bottom: 50px !important;
  }
  .padding-xs-left-50 {
    padding-left: 50px !important;
  }
  .padding-xs-neg-50 {
    padding: 50px !important;
  }
  .padding-xs-top-neg-50 {
    padding-top: 50px !important;
  }
  .padding-xs-right-neg-50 {
    padding-right: 50px !important;
  }
  .padding-xs-bottom-neg-50 {
    padding-bottom: 50px !important;
  }
  .padding-xs-left-neg-50 {
    padding-left: 50px !important;
  }
  .padding-xs-55 {
    padding: 55px !important;
  }
  .padding-xs-top-55 {
    padding-top: 55px !important;
  }
  .padding-xs-right-55 {
    padding-right: 55px !important;
  }
  .padding-xs-bottom-55 {
    padding-bottom: 55px !important;
  }
  .padding-xs-left-55 {
    padding-left: 55px !important;
  }
  .padding-xs-neg-55 {
    padding: 55px !important;
  }
  .padding-xs-top-neg-55 {
    padding-top: 55px !important;
  }
  .padding-xs-right-neg-55 {
    padding-right: 55px !important;
  }
  .padding-xs-bottom-neg-55 {
    padding-bottom: 55px !important;
  }
  .padding-xs-left-neg-55 {
    padding-left: 55px !important;
  }
  .padding-xs-60 {
    padding: 60px !important;
  }
  .padding-xs-top-60 {
    padding-top: 60px !important;
  }
  .padding-xs-right-60 {
    padding-right: 60px !important;
  }
  .padding-xs-bottom-60 {
    padding-bottom: 60px !important;
  }
  .padding-xs-left-60 {
    padding-left: 60px !important;
  }
  .padding-xs-neg-60 {
    padding: 60px !important;
  }
  .padding-xs-top-neg-60 {
    padding-top: 60px !important;
  }
  .padding-xs-right-neg-60 {
    padding-right: 60px !important;
  }
  .padding-xs-bottom-neg-60 {
    padding-bottom: 60px !important;
  }
  .padding-xs-left-neg-60 {
    padding-left: 60px !important;
  }
  .padding-xs-65 {
    padding: 65px !important;
  }
  .padding-xs-top-65 {
    padding-top: 65px !important;
  }
  .padding-xs-right-65 {
    padding-right: 65px !important;
  }
  .padding-xs-bottom-65 {
    padding-bottom: 65px !important;
  }
  .padding-xs-left-65 {
    padding-left: 65px !important;
  }
  .padding-xs-neg-65 {
    padding: 65px !important;
  }
  .padding-xs-top-neg-65 {
    padding-top: 65px !important;
  }
  .padding-xs-right-neg-65 {
    padding-right: 65px !important;
  }
  .padding-xs-bottom-neg-65 {
    padding-bottom: 65px !important;
  }
  .padding-xs-left-neg-65 {
    padding-left: 65px !important;
  }
  .padding-xs-70 {
    padding: 70px !important;
  }
  .padding-xs-top-70 {
    padding-top: 70px !important;
  }
  .padding-xs-right-70 {
    padding-right: 70px !important;
  }
  .padding-xs-bottom-70 {
    padding-bottom: 70px !important;
  }
  .padding-xs-left-70 {
    padding-left: 70px !important;
  }
  .padding-xs-neg-70 {
    padding: 70px !important;
  }
  .padding-xs-top-neg-70 {
    padding-top: 70px !important;
  }
  .padding-xs-right-neg-70 {
    padding-right: 70px !important;
  }
  .padding-xs-bottom-neg-70 {
    padding-bottom: 70px !important;
  }
  .padding-xs-left-neg-70 {
    padding-left: 70px !important;
  }
  .padding-xs-75 {
    padding: 75px !important;
  }
  .padding-xs-top-75 {
    padding-top: 75px !important;
  }
  .padding-xs-right-75 {
    padding-right: 75px !important;
  }
  .padding-xs-bottom-75 {
    padding-bottom: 75px !important;
  }
  .padding-xs-left-75 {
    padding-left: 75px !important;
  }
  .padding-xs-neg-75 {
    padding: 75px !important;
  }
  .padding-xs-top-neg-75 {
    padding-top: 75px !important;
  }
  .padding-xs-right-neg-75 {
    padding-right: 75px !important;
  }
  .padding-xs-bottom-neg-75 {
    padding-bottom: 75px !important;
  }
  .padding-xs-left-neg-75 {
    padding-left: 75px !important;
  }
  .padding-xs-80 {
    padding: 80px !important;
  }
  .padding-xs-top-80 {
    padding-top: 80px !important;
  }
  .padding-xs-right-80 {
    padding-right: 80px !important;
  }
  .padding-xs-bottom-80 {
    padding-bottom: 80px !important;
  }
  .padding-xs-left-80 {
    padding-left: 80px !important;
  }
  .padding-xs-neg-80 {
    padding: 80px !important;
  }
  .padding-xs-top-neg-80 {
    padding-top: 80px !important;
  }
  .padding-xs-right-neg-80 {
    padding-right: 80px !important;
  }
  .padding-xs-bottom-neg-80 {
    padding-bottom: 80px !important;
  }
  .padding-xs-left-neg-80 {
    padding-left: 80px !important;
  }
  .padding-xs-85 {
    padding: 85px !important;
  }
  .padding-xs-top-85 {
    padding-top: 85px !important;
  }
  .padding-xs-right-85 {
    padding-right: 85px !important;
  }
  .padding-xs-bottom-85 {
    padding-bottom: 85px !important;
  }
  .padding-xs-left-85 {
    padding-left: 85px !important;
  }
  .padding-xs-neg-85 {
    padding: 85px !important;
  }
  .padding-xs-top-neg-85 {
    padding-top: 85px !important;
  }
  .padding-xs-right-neg-85 {
    padding-right: 85px !important;
  }
  .padding-xs-bottom-neg-85 {
    padding-bottom: 85px !important;
  }
  .padding-xs-left-neg-85 {
    padding-left: 85px !important;
  }
  .padding-xs-90 {
    padding: 90px !important;
  }
  .padding-xs-top-90 {
    padding-top: 90px !important;
  }
  .padding-xs-right-90 {
    padding-right: 90px !important;
  }
  .padding-xs-bottom-90 {
    padding-bottom: 90px !important;
  }
  .padding-xs-left-90 {
    padding-left: 90px !important;
  }
  .padding-xs-neg-90 {
    padding: 90px !important;
  }
  .padding-xs-top-neg-90 {
    padding-top: 90px !important;
  }
  .padding-xs-right-neg-90 {
    padding-right: 90px !important;
  }
  .padding-xs-bottom-neg-90 {
    padding-bottom: 90px !important;
  }
  .padding-xs-left-neg-90 {
    padding-left: 90px !important;
  }
  .padding-xs-95 {
    padding: 95px !important;
  }
  .padding-xs-top-95 {
    padding-top: 95px !important;
  }
  .padding-xs-right-95 {
    padding-right: 95px !important;
  }
  .padding-xs-bottom-95 {
    padding-bottom: 95px !important;
  }
  .padding-xs-left-95 {
    padding-left: 95px !important;
  }
  .padding-xs-neg-95 {
    padding: 95px !important;
  }
  .padding-xs-top-neg-95 {
    padding-top: 95px !important;
  }
  .padding-xs-right-neg-95 {
    padding-right: 95px !important;
  }
  .padding-xs-bottom-neg-95 {
    padding-bottom: 95px !important;
  }
  .padding-xs-left-neg-95 {
    padding-left: 95px !important;
  }
  .padding-xs-100 {
    padding: 100px !important;
  }
  .padding-xs-top-100 {
    padding-top: 100px !important;
  }
  .padding-xs-right-100 {
    padding-right: 100px !important;
  }
  .padding-xs-bottom-100 {
    padding-bottom: 100px !important;
  }
  .padding-xs-left-100 {
    padding-left: 100px !important;
  }
  .padding-xs-neg-100 {
    padding: 100px !important;
  }
  .padding-xs-top-neg-100 {
    padding-top: 100px !important;
  }
  .padding-xs-right-neg-100 {
    padding-right: 100px !important;
  }
  .padding-xs-bottom-neg-100 {
    padding-bottom: 100px !important;
  }
  .padding-xs-left-neg-100 {
    padding-left: 100px !important;
  }
  .margin-xs-0 {
    margin: 0px !important;
  }
  .margin-xs-top-0 {
    margin-top: 0px !important;
  }
  .margin-xs-right-0 {
    margin-right: 0px !important;
  }
  .margin-xs-bottom-0 {
    margin-bottom: 0px !important;
  }
  .margin-xs-left-0 {
    margin-left: 0px !important;
  }
  .margin-xs-neg-0 {
    margin: 0px !important;
  }
  .margin-xs-top-neg-0 {
    margin-top: 0px !important;
  }
  .margin-xs-right-neg-0 {
    margin-right: 0px !important;
  }
  .margin-xs-bottom-neg-0 {
    margin-bottom: 0px !important;
  }
  .margin-xs-left-neg-0 {
    margin-left: 0px !important;
  }
  .margin-xs-5 {
    margin: 5px !important;
  }
  .margin-xs-top-5 {
    margin-top: 5px !important;
  }
  .margin-xs-right-5 {
    margin-right: 5px !important;
  }
  .margin-xs-bottom-5 {
    margin-bottom: 5px !important;
  }
  .margin-xs-left-5 {
    margin-left: 5px !important;
  }
  .margin-xs-neg-5 {
    margin: 5px !important;
  }
  .margin-xs-top-neg-5 {
    margin-top: 5px !important;
  }
  .margin-xs-right-neg-5 {
    margin-right: 5px !important;
  }
  .margin-xs-bottom-neg-5 {
    margin-bottom: 5px !important;
  }
  .margin-xs-left-neg-5 {
    margin-left: 5px !important;
  }
  .margin-xs-10 {
    margin: 10px !important;
  }
  .margin-xs-top-10 {
    margin-top: 10px !important;
  }
  .margin-xs-right-10 {
    margin-right: 10px !important;
  }
  .margin-xs-bottom-10 {
    margin-bottom: 10px !important;
  }
  .margin-xs-left-10 {
    margin-left: 10px !important;
  }
  .margin-xs-neg-10 {
    margin: 10px !important;
  }
  .margin-xs-top-neg-10 {
    margin-top: 10px !important;
  }
  .margin-xs-right-neg-10 {
    margin-right: 10px !important;
  }
  .margin-xs-bottom-neg-10 {
    margin-bottom: 10px !important;
  }
  .margin-xs-left-neg-10 {
    margin-left: 10px !important;
  }
  .margin-xs-15 {
    margin: 15px !important;
  }
  .margin-xs-top-15 {
    margin-top: 15px !important;
  }
  .margin-xs-right-15 {
    margin-right: 15px !important;
  }
  .margin-xs-bottom-15 {
    margin-bottom: 15px !important;
  }
  .margin-xs-left-15 {
    margin-left: 15px !important;
  }
  .margin-xs-neg-15 {
    margin: 15px !important;
  }
  .margin-xs-top-neg-15 {
    margin-top: 15px !important;
  }
  .margin-xs-right-neg-15 {
    margin-right: 15px !important;
  }
  .margin-xs-bottom-neg-15 {
    margin-bottom: 15px !important;
  }
  .margin-xs-left-neg-15 {
    margin-left: 15px !important;
  }
  .margin-xs-20 {
    margin: 20px !important;
  }
  .margin-xs-top-20 {
    margin-top: 20px !important;
  }
  .margin-xs-right-20 {
    margin-right: 20px !important;
  }
  .margin-xs-bottom-20 {
    margin-bottom: 20px !important;
  }
  .margin-xs-left-20 {
    margin-left: 20px !important;
  }
  .margin-xs-neg-20 {
    margin: 20px !important;
  }
  .margin-xs-top-neg-20 {
    margin-top: 20px !important;
  }
  .margin-xs-right-neg-20 {
    margin-right: 20px !important;
  }
  .margin-xs-bottom-neg-20 {
    margin-bottom: 20px !important;
  }
  .margin-xs-left-neg-20 {
    margin-left: 20px !important;
  }
  .margin-xs-25 {
    margin: 25px !important;
  }
  .margin-xs-top-25 {
    margin-top: 25px !important;
  }
  .margin-xs-right-25 {
    margin-right: 25px !important;
  }
  .margin-xs-bottom-25 {
    margin-bottom: 25px !important;
  }
  .margin-xs-left-25 {
    margin-left: 25px !important;
  }
  .margin-xs-neg-25 {
    margin: 25px !important;
  }
  .margin-xs-top-neg-25 {
    margin-top: 25px !important;
  }
  .margin-xs-right-neg-25 {
    margin-right: 25px !important;
  }
  .margin-xs-bottom-neg-25 {
    margin-bottom: 25px !important;
  }
  .margin-xs-left-neg-25 {
    margin-left: 25px !important;
  }
  .margin-xs-30 {
    margin: 30px !important;
  }
  .margin-xs-top-30 {
    margin-top: 30px !important;
  }
  .margin-xs-right-30 {
    margin-right: 30px !important;
  }
  .margin-xs-bottom-30 {
    margin-bottom: 30px !important;
  }
  .margin-xs-left-30 {
    margin-left: 30px !important;
  }
  .margin-xs-neg-30 {
    margin: 30px !important;
  }
  .margin-xs-top-neg-30 {
    margin-top: 30px !important;
  }
  .margin-xs-right-neg-30 {
    margin-right: 30px !important;
  }
  .margin-xs-bottom-neg-30 {
    margin-bottom: 30px !important;
  }
  .margin-xs-left-neg-30 {
    margin-left: 30px !important;
  }
  .margin-xs-35 {
    margin: 35px !important;
  }
  .margin-xs-top-35 {
    margin-top: 35px !important;
  }
  .margin-xs-right-35 {
    margin-right: 35px !important;
  }
  .margin-xs-bottom-35 {
    margin-bottom: 35px !important;
  }
  .margin-xs-left-35 {
    margin-left: 35px !important;
  }
  .margin-xs-neg-35 {
    margin: 35px !important;
  }
  .margin-xs-top-neg-35 {
    margin-top: 35px !important;
  }
  .margin-xs-right-neg-35 {
    margin-right: 35px !important;
  }
  .margin-xs-bottom-neg-35 {
    margin-bottom: 35px !important;
  }
  .margin-xs-left-neg-35 {
    margin-left: 35px !important;
  }
  .margin-xs-40 {
    margin: 40px !important;
  }
  .margin-xs-top-40 {
    margin-top: 40px !important;
  }
  .margin-xs-right-40 {
    margin-right: 40px !important;
  }
  .margin-xs-bottom-40 {
    margin-bottom: 40px !important;
  }
  .margin-xs-left-40 {
    margin-left: 40px !important;
  }
  .margin-xs-neg-40 {
    margin: 40px !important;
  }
  .margin-xs-top-neg-40 {
    margin-top: 40px !important;
  }
  .margin-xs-right-neg-40 {
    margin-right: 40px !important;
  }
  .margin-xs-bottom-neg-40 {
    margin-bottom: 40px !important;
  }
  .margin-xs-left-neg-40 {
    margin-left: 40px !important;
  }
  .margin-xs-45 {
    margin: 45px !important;
  }
  .margin-xs-top-45 {
    margin-top: 45px !important;
  }
  .margin-xs-right-45 {
    margin-right: 45px !important;
  }
  .margin-xs-bottom-45 {
    margin-bottom: 45px !important;
  }
  .margin-xs-left-45 {
    margin-left: 45px !important;
  }
  .margin-xs-neg-45 {
    margin: 45px !important;
  }
  .margin-xs-top-neg-45 {
    margin-top: 45px !important;
  }
  .margin-xs-right-neg-45 {
    margin-right: 45px !important;
  }
  .margin-xs-bottom-neg-45 {
    margin-bottom: 45px !important;
  }
  .margin-xs-left-neg-45 {
    margin-left: 45px !important;
  }
  .margin-xs-50 {
    margin: 50px !important;
  }
  .margin-xs-top-50 {
    margin-top: 50px !important;
  }
  .margin-xs-right-50 {
    margin-right: 50px !important;
  }
  .margin-xs-bottom-50 {
    margin-bottom: 50px !important;
  }
  .margin-xs-left-50 {
    margin-left: 50px !important;
  }
  .margin-xs-neg-50 {
    margin: 50px !important;
  }
  .margin-xs-top-neg-50 {
    margin-top: 50px !important;
  }
  .margin-xs-right-neg-50 {
    margin-right: 50px !important;
  }
  .margin-xs-bottom-neg-50 {
    margin-bottom: 50px !important;
  }
  .margin-xs-left-neg-50 {
    margin-left: 50px !important;
  }
  .margin-xs-55 {
    margin: 55px !important;
  }
  .margin-xs-top-55 {
    margin-top: 55px !important;
  }
  .margin-xs-right-55 {
    margin-right: 55px !important;
  }
  .margin-xs-bottom-55 {
    margin-bottom: 55px !important;
  }
  .margin-xs-left-55 {
    margin-left: 55px !important;
  }
  .margin-xs-neg-55 {
    margin: 55px !important;
  }
  .margin-xs-top-neg-55 {
    margin-top: 55px !important;
  }
  .margin-xs-right-neg-55 {
    margin-right: 55px !important;
  }
  .margin-xs-bottom-neg-55 {
    margin-bottom: 55px !important;
  }
  .margin-xs-left-neg-55 {
    margin-left: 55px !important;
  }
  .margin-xs-60 {
    margin: 60px !important;
  }
  .margin-xs-top-60 {
    margin-top: 60px !important;
  }
  .margin-xs-right-60 {
    margin-right: 60px !important;
  }
  .margin-xs-bottom-60 {
    margin-bottom: 60px !important;
  }
  .margin-xs-left-60 {
    margin-left: 60px !important;
  }
  .margin-xs-neg-60 {
    margin: 60px !important;
  }
  .margin-xs-top-neg-60 {
    margin-top: 60px !important;
  }
  .margin-xs-right-neg-60 {
    margin-right: 60px !important;
  }
  .margin-xs-bottom-neg-60 {
    margin-bottom: 60px !important;
  }
  .margin-xs-left-neg-60 {
    margin-left: 60px !important;
  }
  .margin-xs-65 {
    margin: 65px !important;
  }
  .margin-xs-top-65 {
    margin-top: 65px !important;
  }
  .margin-xs-right-65 {
    margin-right: 65px !important;
  }
  .margin-xs-bottom-65 {
    margin-bottom: 65px !important;
  }
  .margin-xs-left-65 {
    margin-left: 65px !important;
  }
  .margin-xs-neg-65 {
    margin: 65px !important;
  }
  .margin-xs-top-neg-65 {
    margin-top: 65px !important;
  }
  .margin-xs-right-neg-65 {
    margin-right: 65px !important;
  }
  .margin-xs-bottom-neg-65 {
    margin-bottom: 65px !important;
  }
  .margin-xs-left-neg-65 {
    margin-left: 65px !important;
  }
  .margin-xs-70 {
    margin: 70px !important;
  }
  .margin-xs-top-70 {
    margin-top: 70px !important;
  }
  .margin-xs-right-70 {
    margin-right: 70px !important;
  }
  .margin-xs-bottom-70 {
    margin-bottom: 70px !important;
  }
  .margin-xs-left-70 {
    margin-left: 70px !important;
  }
  .margin-xs-neg-70 {
    margin: 70px !important;
  }
  .margin-xs-top-neg-70 {
    margin-top: 70px !important;
  }
  .margin-xs-right-neg-70 {
    margin-right: 70px !important;
  }
  .margin-xs-bottom-neg-70 {
    margin-bottom: 70px !important;
  }
  .margin-xs-left-neg-70 {
    margin-left: 70px !important;
  }
  .margin-xs-75 {
    margin: 75px !important;
  }
  .margin-xs-top-75 {
    margin-top: 75px !important;
  }
  .margin-xs-right-75 {
    margin-right: 75px !important;
  }
  .margin-xs-bottom-75 {
    margin-bottom: 75px !important;
  }
  .margin-xs-left-75 {
    margin-left: 75px !important;
  }
  .margin-xs-neg-75 {
    margin: 75px !important;
  }
  .margin-xs-top-neg-75 {
    margin-top: 75px !important;
  }
  .margin-xs-right-neg-75 {
    margin-right: 75px !important;
  }
  .margin-xs-bottom-neg-75 {
    margin-bottom: 75px !important;
  }
  .margin-xs-left-neg-75 {
    margin-left: 75px !important;
  }
  .margin-xs-80 {
    margin: 80px !important;
  }
  .margin-xs-top-80 {
    margin-top: 80px !important;
  }
  .margin-xs-right-80 {
    margin-right: 80px !important;
  }
  .margin-xs-bottom-80 {
    margin-bottom: 80px !important;
  }
  .margin-xs-left-80 {
    margin-left: 80px !important;
  }
  .margin-xs-neg-80 {
    margin: 80px !important;
  }
  .margin-xs-top-neg-80 {
    margin-top: 80px !important;
  }
  .margin-xs-right-neg-80 {
    margin-right: 80px !important;
  }
  .margin-xs-bottom-neg-80 {
    margin-bottom: 80px !important;
  }
  .margin-xs-left-neg-80 {
    margin-left: 80px !important;
  }
  .margin-xs-85 {
    margin: 85px !important;
  }
  .margin-xs-top-85 {
    margin-top: 85px !important;
  }
  .margin-xs-right-85 {
    margin-right: 85px !important;
  }
  .margin-xs-bottom-85 {
    margin-bottom: 85px !important;
  }
  .margin-xs-left-85 {
    margin-left: 85px !important;
  }
  .margin-xs-neg-85 {
    margin: 85px !important;
  }
  .margin-xs-top-neg-85 {
    margin-top: 85px !important;
  }
  .margin-xs-right-neg-85 {
    margin-right: 85px !important;
  }
  .margin-xs-bottom-neg-85 {
    margin-bottom: 85px !important;
  }
  .margin-xs-left-neg-85 {
    margin-left: 85px !important;
  }
  .margin-xs-90 {
    margin: 90px !important;
  }
  .margin-xs-top-90 {
    margin-top: 90px !important;
  }
  .margin-xs-right-90 {
    margin-right: 90px !important;
  }
  .margin-xs-bottom-90 {
    margin-bottom: 90px !important;
  }
  .margin-xs-left-90 {
    margin-left: 90px !important;
  }
  .margin-xs-neg-90 {
    margin: 90px !important;
  }
  .margin-xs-top-neg-90 {
    margin-top: 90px !important;
  }
  .margin-xs-right-neg-90 {
    margin-right: 90px !important;
  }
  .margin-xs-bottom-neg-90 {
    margin-bottom: 90px !important;
  }
  .margin-xs-left-neg-90 {
    margin-left: 90px !important;
  }
  .margin-xs-95 {
    margin: 95px !important;
  }
  .margin-xs-top-95 {
    margin-top: 95px !important;
  }
  .margin-xs-right-95 {
    margin-right: 95px !important;
  }
  .margin-xs-bottom-95 {
    margin-bottom: 95px !important;
  }
  .margin-xs-left-95 {
    margin-left: 95px !important;
  }
  .margin-xs-neg-95 {
    margin: 95px !important;
  }
  .margin-xs-top-neg-95 {
    margin-top: 95px !important;
  }
  .margin-xs-right-neg-95 {
    margin-right: 95px !important;
  }
  .margin-xs-bottom-neg-95 {
    margin-bottom: 95px !important;
  }
  .margin-xs-left-neg-95 {
    margin-left: 95px !important;
  }
  .margin-xs-100 {
    margin: 100px !important;
  }
  .margin-xs-top-100 {
    margin-top: 100px !important;
  }
  .margin-xs-right-100 {
    margin-right: 100px !important;
  }
  .margin-xs-bottom-100 {
    margin-bottom: 100px !important;
  }
  .margin-xs-left-100 {
    margin-left: 100px !important;
  }
  .margin-xs-neg-100 {
    margin: 100px !important;
  }
  .margin-xs-top-neg-100 {
    margin-top: 100px !important;
  }
  .margin-xs-right-neg-100 {
    margin-right: 100px !important;
  }
  .margin-xs-bottom-neg-100 {
    margin-bottom: 100px !important;
  }
  .margin-xs-left-neg-100 {
    margin-left: 100px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .padding-sm-0 {
    padding: 0px !important;
  }
  .padding-sm-top-0 {
    padding-top: 0px !important;
  }
  .padding-sm-right-0 {
    padding-right: 0px !important;
  }
  .padding-sm-bottom-0 {
    padding-bottom: 0px !important;
  }
  .padding-sm-left-0 {
    padding-left: 0px !important;
  }
  .padding-sm-neg-0 {
    padding: 0px !important;
  }
  .padding-sm-top-neg-0 {
    padding-top: 0px !important;
  }
  .padding-sm-right-neg-0 {
    padding-right: 0px !important;
  }
  .padding-sm-bottom-neg-0 {
    padding-bottom: 0px !important;
  }
  .padding-sm-left-neg-0 {
    padding-left: 0px !important;
  }
  .padding-sm-5 {
    padding: 5px !important;
  }
  .padding-sm-top-5 {
    padding-top: 5px !important;
  }
  .padding-sm-right-5 {
    padding-right: 5px !important;
  }
  .padding-sm-bottom-5 {
    padding-bottom: 5px !important;
  }
  .padding-sm-left-5 {
    padding-left: 5px !important;
  }
  .padding-sm-neg-5 {
    padding: 5px !important;
  }
  .padding-sm-top-neg-5 {
    padding-top: 5px !important;
  }
  .padding-sm-right-neg-5 {
    padding-right: 5px !important;
  }
  .padding-sm-bottom-neg-5 {
    padding-bottom: 5px !important;
  }
  .padding-sm-left-neg-5 {
    padding-left: 5px !important;
  }
  .padding-sm-10 {
    padding: 10px !important;
  }
  .padding-sm-top-10 {
    padding-top: 10px !important;
  }
  .padding-sm-right-10 {
    padding-right: 10px !important;
  }
  .padding-sm-bottom-10 {
    padding-bottom: 10px !important;
  }
  .padding-sm-left-10 {
    padding-left: 10px !important;
  }
  .padding-sm-neg-10 {
    padding: 10px !important;
  }
  .padding-sm-top-neg-10 {
    padding-top: 10px !important;
  }
  .padding-sm-right-neg-10 {
    padding-right: 10px !important;
  }
  .padding-sm-bottom-neg-10 {
    padding-bottom: 10px !important;
  }
  .padding-sm-left-neg-10 {
    padding-left: 10px !important;
  }
  .padding-sm-15 {
    padding: 15px !important;
  }
  .padding-sm-top-15 {
    padding-top: 15px !important;
  }
  .padding-sm-right-15 {
    padding-right: 15px !important;
  }
  .padding-sm-bottom-15 {
    padding-bottom: 15px !important;
  }
  .padding-sm-left-15 {
    padding-left: 15px !important;
  }
  .padding-sm-neg-15 {
    padding: 15px !important;
  }
  .padding-sm-top-neg-15 {
    padding-top: 15px !important;
  }
  .padding-sm-right-neg-15 {
    padding-right: 15px !important;
  }
  .padding-sm-bottom-neg-15 {
    padding-bottom: 15px !important;
  }
  .padding-sm-left-neg-15 {
    padding-left: 15px !important;
  }
  .padding-sm-20 {
    padding: 20px !important;
  }
  .padding-sm-top-20 {
    padding-top: 20px !important;
  }
  .padding-sm-right-20 {
    padding-right: 20px !important;
  }
  .padding-sm-bottom-20 {
    padding-bottom: 20px !important;
  }
  .padding-sm-left-20 {
    padding-left: 20px !important;
  }
  .padding-sm-neg-20 {
    padding: 20px !important;
  }
  .padding-sm-top-neg-20 {
    padding-top: 20px !important;
  }
  .padding-sm-right-neg-20 {
    padding-right: 20px !important;
  }
  .padding-sm-bottom-neg-20 {
    padding-bottom: 20px !important;
  }
  .padding-sm-left-neg-20 {
    padding-left: 20px !important;
  }
  .padding-sm-25 {
    padding: 25px !important;
  }
  .padding-sm-top-25 {
    padding-top: 25px !important;
  }
  .padding-sm-right-25 {
    padding-right: 25px !important;
  }
  .padding-sm-bottom-25 {
    padding-bottom: 25px !important;
  }
  .padding-sm-left-25 {
    padding-left: 25px !important;
  }
  .padding-sm-neg-25 {
    padding: 25px !important;
  }
  .padding-sm-top-neg-25 {
    padding-top: 25px !important;
  }
  .padding-sm-right-neg-25 {
    padding-right: 25px !important;
  }
  .padding-sm-bottom-neg-25 {
    padding-bottom: 25px !important;
  }
  .padding-sm-left-neg-25 {
    padding-left: 25px !important;
  }
  .padding-sm-30 {
    padding: 30px !important;
  }
  .padding-sm-top-30 {
    padding-top: 30px !important;
  }
  .padding-sm-right-30 {
    padding-right: 30px !important;
  }
  .padding-sm-bottom-30 {
    padding-bottom: 30px !important;
  }
  .padding-sm-left-30 {
    padding-left: 30px !important;
  }
  .padding-sm-neg-30 {
    padding: 30px !important;
  }
  .padding-sm-top-neg-30 {
    padding-top: 30px !important;
  }
  .padding-sm-right-neg-30 {
    padding-right: 30px !important;
  }
  .padding-sm-bottom-neg-30 {
    padding-bottom: 30px !important;
  }
  .padding-sm-left-neg-30 {
    padding-left: 30px !important;
  }
  .padding-sm-35 {
    padding: 35px !important;
  }
  .padding-sm-top-35 {
    padding-top: 35px !important;
  }
  .padding-sm-right-35 {
    padding-right: 35px !important;
  }
  .padding-sm-bottom-35 {
    padding-bottom: 35px !important;
  }
  .padding-sm-left-35 {
    padding-left: 35px !important;
  }
  .padding-sm-neg-35 {
    padding: 35px !important;
  }
  .padding-sm-top-neg-35 {
    padding-top: 35px !important;
  }
  .padding-sm-right-neg-35 {
    padding-right: 35px !important;
  }
  .padding-sm-bottom-neg-35 {
    padding-bottom: 35px !important;
  }
  .padding-sm-left-neg-35 {
    padding-left: 35px !important;
  }
  .padding-sm-40 {
    padding: 40px !important;
  }
  .padding-sm-top-40 {
    padding-top: 40px !important;
  }
  .padding-sm-right-40 {
    padding-right: 40px !important;
  }
  .padding-sm-bottom-40 {
    padding-bottom: 40px !important;
  }
  .padding-sm-left-40 {
    padding-left: 40px !important;
  }
  .padding-sm-neg-40 {
    padding: 40px !important;
  }
  .padding-sm-top-neg-40 {
    padding-top: 40px !important;
  }
  .padding-sm-right-neg-40 {
    padding-right: 40px !important;
  }
  .padding-sm-bottom-neg-40 {
    padding-bottom: 40px !important;
  }
  .padding-sm-left-neg-40 {
    padding-left: 40px !important;
  }
  .padding-sm-45 {
    padding: 45px !important;
  }
  .padding-sm-top-45 {
    padding-top: 45px !important;
  }
  .padding-sm-right-45 {
    padding-right: 45px !important;
  }
  .padding-sm-bottom-45 {
    padding-bottom: 45px !important;
  }
  .padding-sm-left-45 {
    padding-left: 45px !important;
  }
  .padding-sm-neg-45 {
    padding: 45px !important;
  }
  .padding-sm-top-neg-45 {
    padding-top: 45px !important;
  }
  .padding-sm-right-neg-45 {
    padding-right: 45px !important;
  }
  .padding-sm-bottom-neg-45 {
    padding-bottom: 45px !important;
  }
  .padding-sm-left-neg-45 {
    padding-left: 45px !important;
  }
  .padding-sm-50 {
    padding: 50px !important;
  }
  .padding-sm-top-50 {
    padding-top: 50px !important;
  }
  .padding-sm-right-50 {
    padding-right: 50px !important;
  }
  .padding-sm-bottom-50 {
    padding-bottom: 50px !important;
  }
  .padding-sm-left-50 {
    padding-left: 50px !important;
  }
  .padding-sm-neg-50 {
    padding: 50px !important;
  }
  .padding-sm-top-neg-50 {
    padding-top: 50px !important;
  }
  .padding-sm-right-neg-50 {
    padding-right: 50px !important;
  }
  .padding-sm-bottom-neg-50 {
    padding-bottom: 50px !important;
  }
  .padding-sm-left-neg-50 {
    padding-left: 50px !important;
  }
  .padding-sm-55 {
    padding: 55px !important;
  }
  .padding-sm-top-55 {
    padding-top: 55px !important;
  }
  .padding-sm-right-55 {
    padding-right: 55px !important;
  }
  .padding-sm-bottom-55 {
    padding-bottom: 55px !important;
  }
  .padding-sm-left-55 {
    padding-left: 55px !important;
  }
  .padding-sm-neg-55 {
    padding: 55px !important;
  }
  .padding-sm-top-neg-55 {
    padding-top: 55px !important;
  }
  .padding-sm-right-neg-55 {
    padding-right: 55px !important;
  }
  .padding-sm-bottom-neg-55 {
    padding-bottom: 55px !important;
  }
  .padding-sm-left-neg-55 {
    padding-left: 55px !important;
  }
  .padding-sm-60 {
    padding: 60px !important;
  }
  .padding-sm-top-60 {
    padding-top: 60px !important;
  }
  .padding-sm-right-60 {
    padding-right: 60px !important;
  }
  .padding-sm-bottom-60 {
    padding-bottom: 60px !important;
  }
  .padding-sm-left-60 {
    padding-left: 60px !important;
  }
  .padding-sm-neg-60 {
    padding: 60px !important;
  }
  .padding-sm-top-neg-60 {
    padding-top: 60px !important;
  }
  .padding-sm-right-neg-60 {
    padding-right: 60px !important;
  }
  .padding-sm-bottom-neg-60 {
    padding-bottom: 60px !important;
  }
  .padding-sm-left-neg-60 {
    padding-left: 60px !important;
  }
  .padding-sm-65 {
    padding: 65px !important;
  }
  .padding-sm-top-65 {
    padding-top: 65px !important;
  }
  .padding-sm-right-65 {
    padding-right: 65px !important;
  }
  .padding-sm-bottom-65 {
    padding-bottom: 65px !important;
  }
  .padding-sm-left-65 {
    padding-left: 65px !important;
  }
  .padding-sm-neg-65 {
    padding: 65px !important;
  }
  .padding-sm-top-neg-65 {
    padding-top: 65px !important;
  }
  .padding-sm-right-neg-65 {
    padding-right: 65px !important;
  }
  .padding-sm-bottom-neg-65 {
    padding-bottom: 65px !important;
  }
  .padding-sm-left-neg-65 {
    padding-left: 65px !important;
  }
  .padding-sm-70 {
    padding: 70px !important;
  }
  .padding-sm-top-70 {
    padding-top: 70px !important;
  }
  .padding-sm-right-70 {
    padding-right: 70px !important;
  }
  .padding-sm-bottom-70 {
    padding-bottom: 70px !important;
  }
  .padding-sm-left-70 {
    padding-left: 70px !important;
  }
  .padding-sm-neg-70 {
    padding: 70px !important;
  }
  .padding-sm-top-neg-70 {
    padding-top: 70px !important;
  }
  .padding-sm-right-neg-70 {
    padding-right: 70px !important;
  }
  .padding-sm-bottom-neg-70 {
    padding-bottom: 70px !important;
  }
  .padding-sm-left-neg-70 {
    padding-left: 70px !important;
  }
  .padding-sm-75 {
    padding: 75px !important;
  }
  .padding-sm-top-75 {
    padding-top: 75px !important;
  }
  .padding-sm-right-75 {
    padding-right: 75px !important;
  }
  .padding-sm-bottom-75 {
    padding-bottom: 75px !important;
  }
  .padding-sm-left-75 {
    padding-left: 75px !important;
  }
  .padding-sm-neg-75 {
    padding: 75px !important;
  }
  .padding-sm-top-neg-75 {
    padding-top: 75px !important;
  }
  .padding-sm-right-neg-75 {
    padding-right: 75px !important;
  }
  .padding-sm-bottom-neg-75 {
    padding-bottom: 75px !important;
  }
  .padding-sm-left-neg-75 {
    padding-left: 75px !important;
  }
  .padding-sm-80 {
    padding: 80px !important;
  }
  .padding-sm-top-80 {
    padding-top: 80px !important;
  }
  .padding-sm-right-80 {
    padding-right: 80px !important;
  }
  .padding-sm-bottom-80 {
    padding-bottom: 80px !important;
  }
  .padding-sm-left-80 {
    padding-left: 80px !important;
  }
  .padding-sm-neg-80 {
    padding: 80px !important;
  }
  .padding-sm-top-neg-80 {
    padding-top: 80px !important;
  }
  .padding-sm-right-neg-80 {
    padding-right: 80px !important;
  }
  .padding-sm-bottom-neg-80 {
    padding-bottom: 80px !important;
  }
  .padding-sm-left-neg-80 {
    padding-left: 80px !important;
  }
  .padding-sm-85 {
    padding: 85px !important;
  }
  .padding-sm-top-85 {
    padding-top: 85px !important;
  }
  .padding-sm-right-85 {
    padding-right: 85px !important;
  }
  .padding-sm-bottom-85 {
    padding-bottom: 85px !important;
  }
  .padding-sm-left-85 {
    padding-left: 85px !important;
  }
  .padding-sm-neg-85 {
    padding: 85px !important;
  }
  .padding-sm-top-neg-85 {
    padding-top: 85px !important;
  }
  .padding-sm-right-neg-85 {
    padding-right: 85px !important;
  }
  .padding-sm-bottom-neg-85 {
    padding-bottom: 85px !important;
  }
  .padding-sm-left-neg-85 {
    padding-left: 85px !important;
  }
  .padding-sm-90 {
    padding: 90px !important;
  }
  .padding-sm-top-90 {
    padding-top: 90px !important;
  }
  .padding-sm-right-90 {
    padding-right: 90px !important;
  }
  .padding-sm-bottom-90 {
    padding-bottom: 90px !important;
  }
  .padding-sm-left-90 {
    padding-left: 90px !important;
  }
  .padding-sm-neg-90 {
    padding: 90px !important;
  }
  .padding-sm-top-neg-90 {
    padding-top: 90px !important;
  }
  .padding-sm-right-neg-90 {
    padding-right: 90px !important;
  }
  .padding-sm-bottom-neg-90 {
    padding-bottom: 90px !important;
  }
  .padding-sm-left-neg-90 {
    padding-left: 90px !important;
  }
  .padding-sm-95 {
    padding: 95px !important;
  }
  .padding-sm-top-95 {
    padding-top: 95px !important;
  }
  .padding-sm-right-95 {
    padding-right: 95px !important;
  }
  .padding-sm-bottom-95 {
    padding-bottom: 95px !important;
  }
  .padding-sm-left-95 {
    padding-left: 95px !important;
  }
  .padding-sm-neg-95 {
    padding: 95px !important;
  }
  .padding-sm-top-neg-95 {
    padding-top: 95px !important;
  }
  .padding-sm-right-neg-95 {
    padding-right: 95px !important;
  }
  .padding-sm-bottom-neg-95 {
    padding-bottom: 95px !important;
  }
  .padding-sm-left-neg-95 {
    padding-left: 95px !important;
  }
  .padding-sm-100 {
    padding: 100px !important;
  }
  .padding-sm-top-100 {
    padding-top: 100px !important;
  }
  .padding-sm-right-100 {
    padding-right: 100px !important;
  }
  .padding-sm-bottom-100 {
    padding-bottom: 100px !important;
  }
  .padding-sm-left-100 {
    padding-left: 100px !important;
  }
  .padding-sm-neg-100 {
    padding: 100px !important;
  }
  .padding-sm-top-neg-100 {
    padding-top: 100px !important;
  }
  .padding-sm-right-neg-100 {
    padding-right: 100px !important;
  }
  .padding-sm-bottom-neg-100 {
    padding-bottom: 100px !important;
  }
  .padding-sm-left-neg-100 {
    padding-left: 100px !important;
  }
  .margin-sm-0 {
    margin: 0px !important;
  }
  .margin-sm-top-0 {
    margin-top: 0px !important;
  }
  .margin-sm-right-0 {
    margin-right: 0px !important;
  }
  .margin-sm-bottom-0 {
    margin-bottom: 0px !important;
  }
  .margin-sm-left-0 {
    margin-left: 0px !important;
  }
  .margin-sm-neg-0 {
    margin: 0px !important;
  }
  .margin-sm-top-neg-0 {
    margin-top: 0px !important;
  }
  .margin-sm-right-neg-0 {
    margin-right: 0px !important;
  }
  .margin-sm-bottom-neg-0 {
    margin-bottom: 0px !important;
  }
  .margin-sm-left-neg-0 {
    margin-left: 0px !important;
  }
  .margin-sm-5 {
    margin: 5px !important;
  }
  .margin-sm-top-5 {
    margin-top: 5px !important;
  }
  .margin-sm-right-5 {
    margin-right: 5px !important;
  }
  .margin-sm-bottom-5 {
    margin-bottom: 5px !important;
  }
  .margin-sm-left-5 {
    margin-left: 5px !important;
  }
  .margin-sm-neg-5 {
    margin: 5px !important;
  }
  .margin-sm-top-neg-5 {
    margin-top: 5px !important;
  }
  .margin-sm-right-neg-5 {
    margin-right: 5px !important;
  }
  .margin-sm-bottom-neg-5 {
    margin-bottom: 5px !important;
  }
  .margin-sm-left-neg-5 {
    margin-left: 5px !important;
  }
  .margin-sm-10 {
    margin: 10px !important;
  }
  .margin-sm-top-10 {
    margin-top: 10px !important;
  }
  .margin-sm-right-10 {
    margin-right: 10px !important;
  }
  .margin-sm-bottom-10 {
    margin-bottom: 10px !important;
  }
  .margin-sm-left-10 {
    margin-left: 10px !important;
  }
  .margin-sm-neg-10 {
    margin: 10px !important;
  }
  .margin-sm-top-neg-10 {
    margin-top: 10px !important;
  }
  .margin-sm-right-neg-10 {
    margin-right: 10px !important;
  }
  .margin-sm-bottom-neg-10 {
    margin-bottom: 10px !important;
  }
  .margin-sm-left-neg-10 {
    margin-left: 10px !important;
  }
  .margin-sm-15 {
    margin: 15px !important;
  }
  .margin-sm-top-15 {
    margin-top: 15px !important;
  }
  .margin-sm-right-15 {
    margin-right: 15px !important;
  }
  .margin-sm-bottom-15 {
    margin-bottom: 15px !important;
  }
  .margin-sm-left-15 {
    margin-left: 15px !important;
  }
  .margin-sm-neg-15 {
    margin: 15px !important;
  }
  .margin-sm-top-neg-15 {
    margin-top: 15px !important;
  }
  .margin-sm-right-neg-15 {
    margin-right: 15px !important;
  }
  .margin-sm-bottom-neg-15 {
    margin-bottom: 15px !important;
  }
  .margin-sm-left-neg-15 {
    margin-left: 15px !important;
  }
  .margin-sm-20 {
    margin: 20px !important;
  }
  .margin-sm-top-20 {
    margin-top: 20px !important;
  }
  .margin-sm-right-20 {
    margin-right: 20px !important;
  }
  .margin-sm-bottom-20 {
    margin-bottom: 20px !important;
  }
  .margin-sm-left-20 {
    margin-left: 20px !important;
  }
  .margin-sm-neg-20 {
    margin: 20px !important;
  }
  .margin-sm-top-neg-20 {
    margin-top: 20px !important;
  }
  .margin-sm-right-neg-20 {
    margin-right: 20px !important;
  }
  .margin-sm-bottom-neg-20 {
    margin-bottom: 20px !important;
  }
  .margin-sm-left-neg-20 {
    margin-left: 20px !important;
  }
  .margin-sm-25 {
    margin: 25px !important;
  }
  .margin-sm-top-25 {
    margin-top: 25px !important;
  }
  .margin-sm-right-25 {
    margin-right: 25px !important;
  }
  .margin-sm-bottom-25 {
    margin-bottom: 25px !important;
  }
  .margin-sm-left-25 {
    margin-left: 25px !important;
  }
  .margin-sm-neg-25 {
    margin: 25px !important;
  }
  .margin-sm-top-neg-25 {
    margin-top: 25px !important;
  }
  .margin-sm-right-neg-25 {
    margin-right: 25px !important;
  }
  .margin-sm-bottom-neg-25 {
    margin-bottom: 25px !important;
  }
  .margin-sm-left-neg-25 {
    margin-left: 25px !important;
  }
  .margin-sm-30 {
    margin: 30px !important;
  }
  .margin-sm-top-30 {
    margin-top: 30px !important;
  }
  .margin-sm-right-30 {
    margin-right: 30px !important;
  }
  .margin-sm-bottom-30 {
    margin-bottom: 30px !important;
  }
  .margin-sm-left-30 {
    margin-left: 30px !important;
  }
  .margin-sm-neg-30 {
    margin: 30px !important;
  }
  .margin-sm-top-neg-30 {
    margin-top: 30px !important;
  }
  .margin-sm-right-neg-30 {
    margin-right: 30px !important;
  }
  .margin-sm-bottom-neg-30 {
    margin-bottom: 30px !important;
  }
  .margin-sm-left-neg-30 {
    margin-left: 30px !important;
  }
  .margin-sm-35 {
    margin: 35px !important;
  }
  .margin-sm-top-35 {
    margin-top: 35px !important;
  }
  .margin-sm-right-35 {
    margin-right: 35px !important;
  }
  .margin-sm-bottom-35 {
    margin-bottom: 35px !important;
  }
  .margin-sm-left-35 {
    margin-left: 35px !important;
  }
  .margin-sm-neg-35 {
    margin: 35px !important;
  }
  .margin-sm-top-neg-35 {
    margin-top: 35px !important;
  }
  .margin-sm-right-neg-35 {
    margin-right: 35px !important;
  }
  .margin-sm-bottom-neg-35 {
    margin-bottom: 35px !important;
  }
  .margin-sm-left-neg-35 {
    margin-left: 35px !important;
  }
  .margin-sm-40 {
    margin: 40px !important;
  }
  .margin-sm-top-40 {
    margin-top: 40px !important;
  }
  .margin-sm-right-40 {
    margin-right: 40px !important;
  }
  .margin-sm-bottom-40 {
    margin-bottom: 40px !important;
  }
  .margin-sm-left-40 {
    margin-left: 40px !important;
  }
  .margin-sm-neg-40 {
    margin: 40px !important;
  }
  .margin-sm-top-neg-40 {
    margin-top: 40px !important;
  }
  .margin-sm-right-neg-40 {
    margin-right: 40px !important;
  }
  .margin-sm-bottom-neg-40 {
    margin-bottom: 40px !important;
  }
  .margin-sm-left-neg-40 {
    margin-left: 40px !important;
  }
  .margin-sm-45 {
    margin: 45px !important;
  }
  .margin-sm-top-45 {
    margin-top: 45px !important;
  }
  .margin-sm-right-45 {
    margin-right: 45px !important;
  }
  .margin-sm-bottom-45 {
    margin-bottom: 45px !important;
  }
  .margin-sm-left-45 {
    margin-left: 45px !important;
  }
  .margin-sm-neg-45 {
    margin: 45px !important;
  }
  .margin-sm-top-neg-45 {
    margin-top: 45px !important;
  }
  .margin-sm-right-neg-45 {
    margin-right: 45px !important;
  }
  .margin-sm-bottom-neg-45 {
    margin-bottom: 45px !important;
  }
  .margin-sm-left-neg-45 {
    margin-left: 45px !important;
  }
  .margin-sm-50 {
    margin: 50px !important;
  }
  .margin-sm-top-50 {
    margin-top: 50px !important;
  }
  .margin-sm-right-50 {
    margin-right: 50px !important;
  }
  .margin-sm-bottom-50 {
    margin-bottom: 50px !important;
  }
  .margin-sm-left-50 {
    margin-left: 50px !important;
  }
  .margin-sm-neg-50 {
    margin: 50px !important;
  }
  .margin-sm-top-neg-50 {
    margin-top: 50px !important;
  }
  .margin-sm-right-neg-50 {
    margin-right: 50px !important;
  }
  .margin-sm-bottom-neg-50 {
    margin-bottom: 50px !important;
  }
  .margin-sm-left-neg-50 {
    margin-left: 50px !important;
  }
  .margin-sm-55 {
    margin: 55px !important;
  }
  .margin-sm-top-55 {
    margin-top: 55px !important;
  }
  .margin-sm-right-55 {
    margin-right: 55px !important;
  }
  .margin-sm-bottom-55 {
    margin-bottom: 55px !important;
  }
  .margin-sm-left-55 {
    margin-left: 55px !important;
  }
  .margin-sm-neg-55 {
    margin: 55px !important;
  }
  .margin-sm-top-neg-55 {
    margin-top: 55px !important;
  }
  .margin-sm-right-neg-55 {
    margin-right: 55px !important;
  }
  .margin-sm-bottom-neg-55 {
    margin-bottom: 55px !important;
  }
  .margin-sm-left-neg-55 {
    margin-left: 55px !important;
  }
  .margin-sm-60 {
    margin: 60px !important;
  }
  .margin-sm-top-60 {
    margin-top: 60px !important;
  }
  .margin-sm-right-60 {
    margin-right: 60px !important;
  }
  .margin-sm-bottom-60 {
    margin-bottom: 60px !important;
  }
  .margin-sm-left-60 {
    margin-left: 60px !important;
  }
  .margin-sm-neg-60 {
    margin: 60px !important;
  }
  .margin-sm-top-neg-60 {
    margin-top: 60px !important;
  }
  .margin-sm-right-neg-60 {
    margin-right: 60px !important;
  }
  .margin-sm-bottom-neg-60 {
    margin-bottom: 60px !important;
  }
  .margin-sm-left-neg-60 {
    margin-left: 60px !important;
  }
  .margin-sm-65 {
    margin: 65px !important;
  }
  .margin-sm-top-65 {
    margin-top: 65px !important;
  }
  .margin-sm-right-65 {
    margin-right: 65px !important;
  }
  .margin-sm-bottom-65 {
    margin-bottom: 65px !important;
  }
  .margin-sm-left-65 {
    margin-left: 65px !important;
  }
  .margin-sm-neg-65 {
    margin: 65px !important;
  }
  .margin-sm-top-neg-65 {
    margin-top: 65px !important;
  }
  .margin-sm-right-neg-65 {
    margin-right: 65px !important;
  }
  .margin-sm-bottom-neg-65 {
    margin-bottom: 65px !important;
  }
  .margin-sm-left-neg-65 {
    margin-left: 65px !important;
  }
  .margin-sm-70 {
    margin: 70px !important;
  }
  .margin-sm-top-70 {
    margin-top: 70px !important;
  }
  .margin-sm-right-70 {
    margin-right: 70px !important;
  }
  .margin-sm-bottom-70 {
    margin-bottom: 70px !important;
  }
  .margin-sm-left-70 {
    margin-left: 70px !important;
  }
  .margin-sm-neg-70 {
    margin: 70px !important;
  }
  .margin-sm-top-neg-70 {
    margin-top: 70px !important;
  }
  .margin-sm-right-neg-70 {
    margin-right: 70px !important;
  }
  .margin-sm-bottom-neg-70 {
    margin-bottom: 70px !important;
  }
  .margin-sm-left-neg-70 {
    margin-left: 70px !important;
  }
  .margin-sm-75 {
    margin: 75px !important;
  }
  .margin-sm-top-75 {
    margin-top: 75px !important;
  }
  .margin-sm-right-75 {
    margin-right: 75px !important;
  }
  .margin-sm-bottom-75 {
    margin-bottom: 75px !important;
  }
  .margin-sm-left-75 {
    margin-left: 75px !important;
  }
  .margin-sm-neg-75 {
    margin: 75px !important;
  }
  .margin-sm-top-neg-75 {
    margin-top: 75px !important;
  }
  .margin-sm-right-neg-75 {
    margin-right: 75px !important;
  }
  .margin-sm-bottom-neg-75 {
    margin-bottom: 75px !important;
  }
  .margin-sm-left-neg-75 {
    margin-left: 75px !important;
  }
  .margin-sm-80 {
    margin: 80px !important;
  }
  .margin-sm-top-80 {
    margin-top: 80px !important;
  }
  .margin-sm-right-80 {
    margin-right: 80px !important;
  }
  .margin-sm-bottom-80 {
    margin-bottom: 80px !important;
  }
  .margin-sm-left-80 {
    margin-left: 80px !important;
  }
  .margin-sm-neg-80 {
    margin: 80px !important;
  }
  .margin-sm-top-neg-80 {
    margin-top: 80px !important;
  }
  .margin-sm-right-neg-80 {
    margin-right: 80px !important;
  }
  .margin-sm-bottom-neg-80 {
    margin-bottom: 80px !important;
  }
  .margin-sm-left-neg-80 {
    margin-left: 80px !important;
  }
  .margin-sm-85 {
    margin: 85px !important;
  }
  .margin-sm-top-85 {
    margin-top: 85px !important;
  }
  .margin-sm-right-85 {
    margin-right: 85px !important;
  }
  .margin-sm-bottom-85 {
    margin-bottom: 85px !important;
  }
  .margin-sm-left-85 {
    margin-left: 85px !important;
  }
  .margin-sm-neg-85 {
    margin: 85px !important;
  }
  .margin-sm-top-neg-85 {
    margin-top: 85px !important;
  }
  .margin-sm-right-neg-85 {
    margin-right: 85px !important;
  }
  .margin-sm-bottom-neg-85 {
    margin-bottom: 85px !important;
  }
  .margin-sm-left-neg-85 {
    margin-left: 85px !important;
  }
  .margin-sm-90 {
    margin: 90px !important;
  }
  .margin-sm-top-90 {
    margin-top: 90px !important;
  }
  .margin-sm-right-90 {
    margin-right: 90px !important;
  }
  .margin-sm-bottom-90 {
    margin-bottom: 90px !important;
  }
  .margin-sm-left-90 {
    margin-left: 90px !important;
  }
  .margin-sm-neg-90 {
    margin: 90px !important;
  }
  .margin-sm-top-neg-90 {
    margin-top: 90px !important;
  }
  .margin-sm-right-neg-90 {
    margin-right: 90px !important;
  }
  .margin-sm-bottom-neg-90 {
    margin-bottom: 90px !important;
  }
  .margin-sm-left-neg-90 {
    margin-left: 90px !important;
  }
  .margin-sm-95 {
    margin: 95px !important;
  }
  .margin-sm-top-95 {
    margin-top: 95px !important;
  }
  .margin-sm-right-95 {
    margin-right: 95px !important;
  }
  .margin-sm-bottom-95 {
    margin-bottom: 95px !important;
  }
  .margin-sm-left-95 {
    margin-left: 95px !important;
  }
  .margin-sm-neg-95 {
    margin: 95px !important;
  }
  .margin-sm-top-neg-95 {
    margin-top: 95px !important;
  }
  .margin-sm-right-neg-95 {
    margin-right: 95px !important;
  }
  .margin-sm-bottom-neg-95 {
    margin-bottom: 95px !important;
  }
  .margin-sm-left-neg-95 {
    margin-left: 95px !important;
  }
  .margin-sm-100 {
    margin: 100px !important;
  }
  .margin-sm-top-100 {
    margin-top: 100px !important;
  }
  .margin-sm-right-100 {
    margin-right: 100px !important;
  }
  .margin-sm-bottom-100 {
    margin-bottom: 100px !important;
  }
  .margin-sm-left-100 {
    margin-left: 100px !important;
  }
  .margin-sm-neg-100 {
    margin: 100px !important;
  }
  .margin-sm-top-neg-100 {
    margin-top: 100px !important;
  }
  .margin-sm-right-neg-100 {
    margin-right: 100px !important;
  }
  .margin-sm-bottom-neg-100 {
    margin-bottom: 100px !important;
  }
  .margin-sm-left-neg-100 {
    margin-left: 100px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .padding-md-0 {
    padding: 0px !important;
  }
  .padding-md-top-0 {
    padding-top: 0px !important;
  }
  .padding-md-right-0 {
    padding-right: 0px !important;
  }
  .padding-md-bottom-0 {
    padding-bottom: 0px !important;
  }
  .padding-md-left-0 {
    padding-left: 0px !important;
  }
  .padding-md-neg-0 {
    padding: 0px !important;
  }
  .padding-md-top-neg-0 {
    padding-top: 0px !important;
  }
  .padding-md-right-neg-0 {
    padding-right: 0px !important;
  }
  .padding-md-bottom-neg-0 {
    padding-bottom: 0px !important;
  }
  .padding-md-left-neg-0 {
    padding-left: 0px !important;
  }
  .padding-md-5 {
    padding: 5px !important;
  }
  .padding-md-top-5 {
    padding-top: 5px !important;
  }
  .padding-md-right-5 {
    padding-right: 5px !important;
  }
  .padding-md-bottom-5 {
    padding-bottom: 5px !important;
  }
  .padding-md-left-5 {
    padding-left: 5px !important;
  }
  .padding-md-neg-5 {
    padding: 5px !important;
  }
  .padding-md-top-neg-5 {
    padding-top: 5px !important;
  }
  .padding-md-right-neg-5 {
    padding-right: 5px !important;
  }
  .padding-md-bottom-neg-5 {
    padding-bottom: 5px !important;
  }
  .padding-md-left-neg-5 {
    padding-left: 5px !important;
  }
  .padding-md-10 {
    padding: 10px !important;
  }
  .padding-md-top-10 {
    padding-top: 10px !important;
  }
  .padding-md-right-10 {
    padding-right: 10px !important;
  }
  .padding-md-bottom-10 {
    padding-bottom: 10px !important;
  }
  .padding-md-left-10 {
    padding-left: 10px !important;
  }
  .padding-md-neg-10 {
    padding: 10px !important;
  }
  .padding-md-top-neg-10 {
    padding-top: 10px !important;
  }
  .padding-md-right-neg-10 {
    padding-right: 10px !important;
  }
  .padding-md-bottom-neg-10 {
    padding-bottom: 10px !important;
  }
  .padding-md-left-neg-10 {
    padding-left: 10px !important;
  }
  .padding-md-15 {
    padding: 15px !important;
  }
  .padding-md-top-15 {
    padding-top: 15px !important;
  }
  .padding-md-right-15 {
    padding-right: 15px !important;
  }
  .padding-md-bottom-15 {
    padding-bottom: 15px !important;
  }
  .padding-md-left-15 {
    padding-left: 15px !important;
  }
  .padding-md-neg-15 {
    padding: 15px !important;
  }
  .padding-md-top-neg-15 {
    padding-top: 15px !important;
  }
  .padding-md-right-neg-15 {
    padding-right: 15px !important;
  }
  .padding-md-bottom-neg-15 {
    padding-bottom: 15px !important;
  }
  .padding-md-left-neg-15 {
    padding-left: 15px !important;
  }
  .padding-md-20 {
    padding: 20px !important;
  }
  .padding-md-top-20 {
    padding-top: 20px !important;
  }
  .padding-md-right-20 {
    padding-right: 20px !important;
  }
  .padding-md-bottom-20 {
    padding-bottom: 20px !important;
  }
  .padding-md-left-20 {
    padding-left: 20px !important;
  }
  .padding-md-neg-20 {
    padding: 20px !important;
  }
  .padding-md-top-neg-20 {
    padding-top: 20px !important;
  }
  .padding-md-right-neg-20 {
    padding-right: 20px !important;
  }
  .padding-md-bottom-neg-20 {
    padding-bottom: 20px !important;
  }
  .padding-md-left-neg-20 {
    padding-left: 20px !important;
  }
  .padding-md-25 {
    padding: 25px !important;
  }
  .padding-md-top-25 {
    padding-top: 25px !important;
  }
  .padding-md-right-25 {
    padding-right: 25px !important;
  }
  .padding-md-bottom-25 {
    padding-bottom: 25px !important;
  }
  .padding-md-left-25 {
    padding-left: 25px !important;
  }
  .padding-md-neg-25 {
    padding: 25px !important;
  }
  .padding-md-top-neg-25 {
    padding-top: 25px !important;
  }
  .padding-md-right-neg-25 {
    padding-right: 25px !important;
  }
  .padding-md-bottom-neg-25 {
    padding-bottom: 25px !important;
  }
  .padding-md-left-neg-25 {
    padding-left: 25px !important;
  }
  .padding-md-30 {
    padding: 30px !important;
  }
  .padding-md-top-30 {
    padding-top: 30px !important;
  }
  .padding-md-right-30 {
    padding-right: 30px !important;
  }
  .padding-md-bottom-30 {
    padding-bottom: 30px !important;
  }
  .padding-md-left-30 {
    padding-left: 30px !important;
  }
  .padding-md-neg-30 {
    padding: 30px !important;
  }
  .padding-md-top-neg-30 {
    padding-top: 30px !important;
  }
  .padding-md-right-neg-30 {
    padding-right: 30px !important;
  }
  .padding-md-bottom-neg-30 {
    padding-bottom: 30px !important;
  }
  .padding-md-left-neg-30 {
    padding-left: 30px !important;
  }
  .padding-md-35 {
    padding: 35px !important;
  }
  .padding-md-top-35 {
    padding-top: 35px !important;
  }
  .padding-md-right-35 {
    padding-right: 35px !important;
  }
  .padding-md-bottom-35 {
    padding-bottom: 35px !important;
  }
  .padding-md-left-35 {
    padding-left: 35px !important;
  }
  .padding-md-neg-35 {
    padding: 35px !important;
  }
  .padding-md-top-neg-35 {
    padding-top: 35px !important;
  }
  .padding-md-right-neg-35 {
    padding-right: 35px !important;
  }
  .padding-md-bottom-neg-35 {
    padding-bottom: 35px !important;
  }
  .padding-md-left-neg-35 {
    padding-left: 35px !important;
  }
  .padding-md-40 {
    padding: 40px !important;
  }
  .padding-md-top-40 {
    padding-top: 40px !important;
  }
  .padding-md-right-40 {
    padding-right: 40px !important;
  }
  .padding-md-bottom-40 {
    padding-bottom: 40px !important;
  }
  .padding-md-left-40 {
    padding-left: 40px !important;
  }
  .padding-md-neg-40 {
    padding: 40px !important;
  }
  .padding-md-top-neg-40 {
    padding-top: 40px !important;
  }
  .padding-md-right-neg-40 {
    padding-right: 40px !important;
  }
  .padding-md-bottom-neg-40 {
    padding-bottom: 40px !important;
  }
  .padding-md-left-neg-40 {
    padding-left: 40px !important;
  }
  .padding-md-45 {
    padding: 45px !important;
  }
  .padding-md-top-45 {
    padding-top: 45px !important;
  }
  .padding-md-right-45 {
    padding-right: 45px !important;
  }
  .padding-md-bottom-45 {
    padding-bottom: 45px !important;
  }
  .padding-md-left-45 {
    padding-left: 45px !important;
  }
  .padding-md-neg-45 {
    padding: 45px !important;
  }
  .padding-md-top-neg-45 {
    padding-top: 45px !important;
  }
  .padding-md-right-neg-45 {
    padding-right: 45px !important;
  }
  .padding-md-bottom-neg-45 {
    padding-bottom: 45px !important;
  }
  .padding-md-left-neg-45 {
    padding-left: 45px !important;
  }
  .padding-md-50 {
    padding: 50px !important;
  }
  .padding-md-top-50 {
    padding-top: 50px !important;
  }
  .padding-md-right-50 {
    padding-right: 50px !important;
  }
  .padding-md-bottom-50 {
    padding-bottom: 50px !important;
  }
  .padding-md-left-50 {
    padding-left: 50px !important;
  }
  .padding-md-neg-50 {
    padding: 50px !important;
  }
  .padding-md-top-neg-50 {
    padding-top: 50px !important;
  }
  .padding-md-right-neg-50 {
    padding-right: 50px !important;
  }
  .padding-md-bottom-neg-50 {
    padding-bottom: 50px !important;
  }
  .padding-md-left-neg-50 {
    padding-left: 50px !important;
  }
  .padding-md-55 {
    padding: 55px !important;
  }
  .padding-md-top-55 {
    padding-top: 55px !important;
  }
  .padding-md-right-55 {
    padding-right: 55px !important;
  }
  .padding-md-bottom-55 {
    padding-bottom: 55px !important;
  }
  .padding-md-left-55 {
    padding-left: 55px !important;
  }
  .padding-md-neg-55 {
    padding: 55px !important;
  }
  .padding-md-top-neg-55 {
    padding-top: 55px !important;
  }
  .padding-md-right-neg-55 {
    padding-right: 55px !important;
  }
  .padding-md-bottom-neg-55 {
    padding-bottom: 55px !important;
  }
  .padding-md-left-neg-55 {
    padding-left: 55px !important;
  }
  .padding-md-60 {
    padding: 60px !important;
  }
  .padding-md-top-60 {
    padding-top: 60px !important;
  }
  .padding-md-right-60 {
    padding-right: 60px !important;
  }
  .padding-md-bottom-60 {
    padding-bottom: 60px !important;
  }
  .padding-md-left-60 {
    padding-left: 60px !important;
  }
  .padding-md-neg-60 {
    padding: 60px !important;
  }
  .padding-md-top-neg-60 {
    padding-top: 60px !important;
  }
  .padding-md-right-neg-60 {
    padding-right: 60px !important;
  }
  .padding-md-bottom-neg-60 {
    padding-bottom: 60px !important;
  }
  .padding-md-left-neg-60 {
    padding-left: 60px !important;
  }
  .padding-md-65 {
    padding: 65px !important;
  }
  .padding-md-top-65 {
    padding-top: 65px !important;
  }
  .padding-md-right-65 {
    padding-right: 65px !important;
  }
  .padding-md-bottom-65 {
    padding-bottom: 65px !important;
  }
  .padding-md-left-65 {
    padding-left: 65px !important;
  }
  .padding-md-neg-65 {
    padding: 65px !important;
  }
  .padding-md-top-neg-65 {
    padding-top: 65px !important;
  }
  .padding-md-right-neg-65 {
    padding-right: 65px !important;
  }
  .padding-md-bottom-neg-65 {
    padding-bottom: 65px !important;
  }
  .padding-md-left-neg-65 {
    padding-left: 65px !important;
  }
  .padding-md-70 {
    padding: 70px !important;
  }
  .padding-md-top-70 {
    padding-top: 70px !important;
  }
  .padding-md-right-70 {
    padding-right: 70px !important;
  }
  .padding-md-bottom-70 {
    padding-bottom: 70px !important;
  }
  .padding-md-left-70 {
    padding-left: 70px !important;
  }
  .padding-md-neg-70 {
    padding: 70px !important;
  }
  .padding-md-top-neg-70 {
    padding-top: 70px !important;
  }
  .padding-md-right-neg-70 {
    padding-right: 70px !important;
  }
  .padding-md-bottom-neg-70 {
    padding-bottom: 70px !important;
  }
  .padding-md-left-neg-70 {
    padding-left: 70px !important;
  }
  .padding-md-75 {
    padding: 75px !important;
  }
  .padding-md-top-75 {
    padding-top: 75px !important;
  }
  .padding-md-right-75 {
    padding-right: 75px !important;
  }
  .padding-md-bottom-75 {
    padding-bottom: 75px !important;
  }
  .padding-md-left-75 {
    padding-left: 75px !important;
  }
  .padding-md-neg-75 {
    padding: 75px !important;
  }
  .padding-md-top-neg-75 {
    padding-top: 75px !important;
  }
  .padding-md-right-neg-75 {
    padding-right: 75px !important;
  }
  .padding-md-bottom-neg-75 {
    padding-bottom: 75px !important;
  }
  .padding-md-left-neg-75 {
    padding-left: 75px !important;
  }
  .padding-md-80 {
    padding: 80px !important;
  }
  .padding-md-top-80 {
    padding-top: 80px !important;
  }
  .padding-md-right-80 {
    padding-right: 80px !important;
  }
  .padding-md-bottom-80 {
    padding-bottom: 80px !important;
  }
  .padding-md-left-80 {
    padding-left: 80px !important;
  }
  .padding-md-neg-80 {
    padding: 80px !important;
  }
  .padding-md-top-neg-80 {
    padding-top: 80px !important;
  }
  .padding-md-right-neg-80 {
    padding-right: 80px !important;
  }
  .padding-md-bottom-neg-80 {
    padding-bottom: 80px !important;
  }
  .padding-md-left-neg-80 {
    padding-left: 80px !important;
  }
  .padding-md-85 {
    padding: 85px !important;
  }
  .padding-md-top-85 {
    padding-top: 85px !important;
  }
  .padding-md-right-85 {
    padding-right: 85px !important;
  }
  .padding-md-bottom-85 {
    padding-bottom: 85px !important;
  }
  .padding-md-left-85 {
    padding-left: 85px !important;
  }
  .padding-md-neg-85 {
    padding: 85px !important;
  }
  .padding-md-top-neg-85 {
    padding-top: 85px !important;
  }
  .padding-md-right-neg-85 {
    padding-right: 85px !important;
  }
  .padding-md-bottom-neg-85 {
    padding-bottom: 85px !important;
  }
  .padding-md-left-neg-85 {
    padding-left: 85px !important;
  }
  .padding-md-90 {
    padding: 90px !important;
  }
  .padding-md-top-90 {
    padding-top: 90px !important;
  }
  .padding-md-right-90 {
    padding-right: 90px !important;
  }
  .padding-md-bottom-90 {
    padding-bottom: 90px !important;
  }
  .padding-md-left-90 {
    padding-left: 90px !important;
  }
  .padding-md-neg-90 {
    padding: 90px !important;
  }
  .padding-md-top-neg-90 {
    padding-top: 90px !important;
  }
  .padding-md-right-neg-90 {
    padding-right: 90px !important;
  }
  .padding-md-bottom-neg-90 {
    padding-bottom: 90px !important;
  }
  .padding-md-left-neg-90 {
    padding-left: 90px !important;
  }
  .padding-md-95 {
    padding: 95px !important;
  }
  .padding-md-top-95 {
    padding-top: 95px !important;
  }
  .padding-md-right-95 {
    padding-right: 95px !important;
  }
  .padding-md-bottom-95 {
    padding-bottom: 95px !important;
  }
  .padding-md-left-95 {
    padding-left: 95px !important;
  }
  .padding-md-neg-95 {
    padding: 95px !important;
  }
  .padding-md-top-neg-95 {
    padding-top: 95px !important;
  }
  .padding-md-right-neg-95 {
    padding-right: 95px !important;
  }
  .padding-md-bottom-neg-95 {
    padding-bottom: 95px !important;
  }
  .padding-md-left-neg-95 {
    padding-left: 95px !important;
  }
  .padding-md-100 {
    padding: 100px !important;
  }
  .padding-md-top-100 {
    padding-top: 100px !important;
  }
  .padding-md-right-100 {
    padding-right: 100px !important;
  }
  .padding-md-bottom-100 {
    padding-bottom: 100px !important;
  }
  .padding-md-left-100 {
    padding-left: 100px !important;
  }
  .padding-md-neg-100 {
    padding: 100px !important;
  }
  .padding-md-top-neg-100 {
    padding-top: 100px !important;
  }
  .padding-md-right-neg-100 {
    padding-right: 100px !important;
  }
  .padding-md-bottom-neg-100 {
    padding-bottom: 100px !important;
  }
  .padding-md-left-neg-100 {
    padding-left: 100px !important;
  }
  .margin-md-0 {
    margin: 0px !important;
  }
  .margin-md-top-0 {
    margin-top: 0px !important;
  }
  .margin-md-right-0 {
    margin-right: 0px !important;
  }
  .margin-md-bottom-0 {
    margin-bottom: 0px !important;
  }
  .margin-md-left-0 {
    margin-left: 0px !important;
  }
  .margin-md-neg-0 {
    margin: 0px !important;
  }
  .margin-md-top-neg-0 {
    margin-top: 0px !important;
  }
  .margin-md-right-neg-0 {
    margin-right: 0px !important;
  }
  .margin-md-bottom-neg-0 {
    margin-bottom: 0px !important;
  }
  .margin-md-left-neg-0 {
    margin-left: 0px !important;
  }
  .margin-md-5 {
    margin: 5px !important;
  }
  .margin-md-top-5 {
    margin-top: 5px !important;
  }
  .margin-md-right-5 {
    margin-right: 5px !important;
  }
  .margin-md-bottom-5 {
    margin-bottom: 5px !important;
  }
  .margin-md-left-5 {
    margin-left: 5px !important;
  }
  .margin-md-neg-5 {
    margin: 5px !important;
  }
  .margin-md-top-neg-5 {
    margin-top: 5px !important;
  }
  .margin-md-right-neg-5 {
    margin-right: 5px !important;
  }
  .margin-md-bottom-neg-5 {
    margin-bottom: 5px !important;
  }
  .margin-md-left-neg-5 {
    margin-left: 5px !important;
  }
  .margin-md-10 {
    margin: 10px !important;
  }
  .margin-md-top-10 {
    margin-top: 10px !important;
  }
  .margin-md-right-10 {
    margin-right: 10px !important;
  }
  .margin-md-bottom-10 {
    margin-bottom: 10px !important;
  }
  .margin-md-left-10 {
    margin-left: 10px !important;
  }
  .margin-md-neg-10 {
    margin: 10px !important;
  }
  .margin-md-top-neg-10 {
    margin-top: 10px !important;
  }
  .margin-md-right-neg-10 {
    margin-right: 10px !important;
  }
  .margin-md-bottom-neg-10 {
    margin-bottom: 10px !important;
  }
  .margin-md-left-neg-10 {
    margin-left: 10px !important;
  }
  .margin-md-15 {
    margin: 15px !important;
  }
  .margin-md-top-15 {
    margin-top: 15px !important;
  }
  .margin-md-right-15 {
    margin-right: 15px !important;
  }
  .margin-md-bottom-15 {
    margin-bottom: 15px !important;
  }
  .margin-md-left-15 {
    margin-left: 15px !important;
  }
  .margin-md-neg-15 {
    margin: 15px !important;
  }
  .margin-md-top-neg-15 {
    margin-top: 15px !important;
  }
  .margin-md-right-neg-15 {
    margin-right: 15px !important;
  }
  .margin-md-bottom-neg-15 {
    margin-bottom: 15px !important;
  }
  .margin-md-left-neg-15 {
    margin-left: 15px !important;
  }
  .margin-md-20 {
    margin: 20px !important;
  }
  .margin-md-top-20 {
    margin-top: 20px !important;
  }
  .margin-md-right-20 {
    margin-right: 20px !important;
  }
  .margin-md-bottom-20 {
    margin-bottom: 20px !important;
  }
  .margin-md-left-20 {
    margin-left: 20px !important;
  }
  .margin-md-neg-20 {
    margin: 20px !important;
  }
  .margin-md-top-neg-20 {
    margin-top: 20px !important;
  }
  .margin-md-right-neg-20 {
    margin-right: 20px !important;
  }
  .margin-md-bottom-neg-20 {
    margin-bottom: 20px !important;
  }
  .margin-md-left-neg-20 {
    margin-left: 20px !important;
  }
  .margin-md-25 {
    margin: 25px !important;
  }
  .margin-md-top-25 {
    margin-top: 25px !important;
  }
  .margin-md-right-25 {
    margin-right: 25px !important;
  }
  .margin-md-bottom-25 {
    margin-bottom: 25px !important;
  }
  .margin-md-left-25 {
    margin-left: 25px !important;
  }
  .margin-md-neg-25 {
    margin: 25px !important;
  }
  .margin-md-top-neg-25 {
    margin-top: 25px !important;
  }
  .margin-md-right-neg-25 {
    margin-right: 25px !important;
  }
  .margin-md-bottom-neg-25 {
    margin-bottom: 25px !important;
  }
  .margin-md-left-neg-25 {
    margin-left: 25px !important;
  }
  .margin-md-30 {
    margin: 30px !important;
  }
  .margin-md-top-30 {
    margin-top: 30px !important;
  }
  .margin-md-right-30 {
    margin-right: 30px !important;
  }
  .margin-md-bottom-30 {
    margin-bottom: 30px !important;
  }
  .margin-md-left-30 {
    margin-left: 30px !important;
  }
  .margin-md-neg-30 {
    margin: 30px !important;
  }
  .margin-md-top-neg-30 {
    margin-top: 30px !important;
  }
  .margin-md-right-neg-30 {
    margin-right: 30px !important;
  }
  .margin-md-bottom-neg-30 {
    margin-bottom: 30px !important;
  }
  .margin-md-left-neg-30 {
    margin-left: 30px !important;
  }
  .margin-md-35 {
    margin: 35px !important;
  }
  .margin-md-top-35 {
    margin-top: 35px !important;
  }
  .margin-md-right-35 {
    margin-right: 35px !important;
  }
  .margin-md-bottom-35 {
    margin-bottom: 35px !important;
  }
  .margin-md-left-35 {
    margin-left: 35px !important;
  }
  .margin-md-neg-35 {
    margin: 35px !important;
  }
  .margin-md-top-neg-35 {
    margin-top: 35px !important;
  }
  .margin-md-right-neg-35 {
    margin-right: 35px !important;
  }
  .margin-md-bottom-neg-35 {
    margin-bottom: 35px !important;
  }
  .margin-md-left-neg-35 {
    margin-left: 35px !important;
  }
  .margin-md-40 {
    margin: 40px !important;
  }
  .margin-md-top-40 {
    margin-top: 40px !important;
  }
  .margin-md-right-40 {
    margin-right: 40px !important;
  }
  .margin-md-bottom-40 {
    margin-bottom: 40px !important;
  }
  .margin-md-left-40 {
    margin-left: 40px !important;
  }
  .margin-md-neg-40 {
    margin: 40px !important;
  }
  .margin-md-top-neg-40 {
    margin-top: 40px !important;
  }
  .margin-md-right-neg-40 {
    margin-right: 40px !important;
  }
  .margin-md-bottom-neg-40 {
    margin-bottom: 40px !important;
  }
  .margin-md-left-neg-40 {
    margin-left: 40px !important;
  }
  .margin-md-45 {
    margin: 45px !important;
  }
  .margin-md-top-45 {
    margin-top: 45px !important;
  }
  .margin-md-right-45 {
    margin-right: 45px !important;
  }
  .margin-md-bottom-45 {
    margin-bottom: 45px !important;
  }
  .margin-md-left-45 {
    margin-left: 45px !important;
  }
  .margin-md-neg-45 {
    margin: 45px !important;
  }
  .margin-md-top-neg-45 {
    margin-top: 45px !important;
  }
  .margin-md-right-neg-45 {
    margin-right: 45px !important;
  }
  .margin-md-bottom-neg-45 {
    margin-bottom: 45px !important;
  }
  .margin-md-left-neg-45 {
    margin-left: 45px !important;
  }
  .margin-md-50 {
    margin: 50px !important;
  }
  .margin-md-top-50 {
    margin-top: 50px !important;
  }
  .margin-md-right-50 {
    margin-right: 50px !important;
  }
  .margin-md-bottom-50 {
    margin-bottom: 50px !important;
  }
  .margin-md-left-50 {
    margin-left: 50px !important;
  }
  .margin-md-neg-50 {
    margin: 50px !important;
  }
  .margin-md-top-neg-50 {
    margin-top: 50px !important;
  }
  .margin-md-right-neg-50 {
    margin-right: 50px !important;
  }
  .margin-md-bottom-neg-50 {
    margin-bottom: 50px !important;
  }
  .margin-md-left-neg-50 {
    margin-left: 50px !important;
  }
  .margin-md-55 {
    margin: 55px !important;
  }
  .margin-md-top-55 {
    margin-top: 55px !important;
  }
  .margin-md-right-55 {
    margin-right: 55px !important;
  }
  .margin-md-bottom-55 {
    margin-bottom: 55px !important;
  }
  .margin-md-left-55 {
    margin-left: 55px !important;
  }
  .margin-md-neg-55 {
    margin: 55px !important;
  }
  .margin-md-top-neg-55 {
    margin-top: 55px !important;
  }
  .margin-md-right-neg-55 {
    margin-right: 55px !important;
  }
  .margin-md-bottom-neg-55 {
    margin-bottom: 55px !important;
  }
  .margin-md-left-neg-55 {
    margin-left: 55px !important;
  }
  .margin-md-60 {
    margin: 60px !important;
  }
  .margin-md-top-60 {
    margin-top: 60px !important;
  }
  .margin-md-right-60 {
    margin-right: 60px !important;
  }
  .margin-md-bottom-60 {
    margin-bottom: 60px !important;
  }
  .margin-md-left-60 {
    margin-left: 60px !important;
  }
  .margin-md-neg-60 {
    margin: 60px !important;
  }
  .margin-md-top-neg-60 {
    margin-top: 60px !important;
  }
  .margin-md-right-neg-60 {
    margin-right: 60px !important;
  }
  .margin-md-bottom-neg-60 {
    margin-bottom: 60px !important;
  }
  .margin-md-left-neg-60 {
    margin-left: 60px !important;
  }
  .margin-md-65 {
    margin: 65px !important;
  }
  .margin-md-top-65 {
    margin-top: 65px !important;
  }
  .margin-md-right-65 {
    margin-right: 65px !important;
  }
  .margin-md-bottom-65 {
    margin-bottom: 65px !important;
  }
  .margin-md-left-65 {
    margin-left: 65px !important;
  }
  .margin-md-neg-65 {
    margin: 65px !important;
  }
  .margin-md-top-neg-65 {
    margin-top: 65px !important;
  }
  .margin-md-right-neg-65 {
    margin-right: 65px !important;
  }
  .margin-md-bottom-neg-65 {
    margin-bottom: 65px !important;
  }
  .margin-md-left-neg-65 {
    margin-left: 65px !important;
  }
  .margin-md-70 {
    margin: 70px !important;
  }
  .margin-md-top-70 {
    margin-top: 70px !important;
  }
  .margin-md-right-70 {
    margin-right: 70px !important;
  }
  .margin-md-bottom-70 {
    margin-bottom: 70px !important;
  }
  .margin-md-left-70 {
    margin-left: 70px !important;
  }
  .margin-md-neg-70 {
    margin: 70px !important;
  }
  .margin-md-top-neg-70 {
    margin-top: 70px !important;
  }
  .margin-md-right-neg-70 {
    margin-right: 70px !important;
  }
  .margin-md-bottom-neg-70 {
    margin-bottom: 70px !important;
  }
  .margin-md-left-neg-70 {
    margin-left: 70px !important;
  }
  .margin-md-75 {
    margin: 75px !important;
  }
  .margin-md-top-75 {
    margin-top: 75px !important;
  }
  .margin-md-right-75 {
    margin-right: 75px !important;
  }
  .margin-md-bottom-75 {
    margin-bottom: 75px !important;
  }
  .margin-md-left-75 {
    margin-left: 75px !important;
  }
  .margin-md-neg-75 {
    margin: 75px !important;
  }
  .margin-md-top-neg-75 {
    margin-top: 75px !important;
  }
  .margin-md-right-neg-75 {
    margin-right: 75px !important;
  }
  .margin-md-bottom-neg-75 {
    margin-bottom: 75px !important;
  }
  .margin-md-left-neg-75 {
    margin-left: 75px !important;
  }
  .margin-md-80 {
    margin: 80px !important;
  }
  .margin-md-top-80 {
    margin-top: 80px !important;
  }
  .margin-md-right-80 {
    margin-right: 80px !important;
  }
  .margin-md-bottom-80 {
    margin-bottom: 80px !important;
  }
  .margin-md-left-80 {
    margin-left: 80px !important;
  }
  .margin-md-neg-80 {
    margin: 80px !important;
  }
  .margin-md-top-neg-80 {
    margin-top: 80px !important;
  }
  .margin-md-right-neg-80 {
    margin-right: 80px !important;
  }
  .margin-md-bottom-neg-80 {
    margin-bottom: 80px !important;
  }
  .margin-md-left-neg-80 {
    margin-left: 80px !important;
  }
  .margin-md-85 {
    margin: 85px !important;
  }
  .margin-md-top-85 {
    margin-top: 85px !important;
  }
  .margin-md-right-85 {
    margin-right: 85px !important;
  }
  .margin-md-bottom-85 {
    margin-bottom: 85px !important;
  }
  .margin-md-left-85 {
    margin-left: 85px !important;
  }
  .margin-md-neg-85 {
    margin: 85px !important;
  }
  .margin-md-top-neg-85 {
    margin-top: 85px !important;
  }
  .margin-md-right-neg-85 {
    margin-right: 85px !important;
  }
  .margin-md-bottom-neg-85 {
    margin-bottom: 85px !important;
  }
  .margin-md-left-neg-85 {
    margin-left: 85px !important;
  }
  .margin-md-90 {
    margin: 90px !important;
  }
  .margin-md-top-90 {
    margin-top: 90px !important;
  }
  .margin-md-right-90 {
    margin-right: 90px !important;
  }
  .margin-md-bottom-90 {
    margin-bottom: 90px !important;
  }
  .margin-md-left-90 {
    margin-left: 90px !important;
  }
  .margin-md-neg-90 {
    margin: 90px !important;
  }
  .margin-md-top-neg-90 {
    margin-top: 90px !important;
  }
  .margin-md-right-neg-90 {
    margin-right: 90px !important;
  }
  .margin-md-bottom-neg-90 {
    margin-bottom: 90px !important;
  }
  .margin-md-left-neg-90 {
    margin-left: 90px !important;
  }
  .margin-md-95 {
    margin: 95px !important;
  }
  .margin-md-top-95 {
    margin-top: 95px !important;
  }
  .margin-md-right-95 {
    margin-right: 95px !important;
  }
  .margin-md-bottom-95 {
    margin-bottom: 95px !important;
  }
  .margin-md-left-95 {
    margin-left: 95px !important;
  }
  .margin-md-neg-95 {
    margin: 95px !important;
  }
  .margin-md-top-neg-95 {
    margin-top: 95px !important;
  }
  .margin-md-right-neg-95 {
    margin-right: 95px !important;
  }
  .margin-md-bottom-neg-95 {
    margin-bottom: 95px !important;
  }
  .margin-md-left-neg-95 {
    margin-left: 95px !important;
  }
  .margin-md-100 {
    margin: 100px !important;
  }
  .margin-md-top-100 {
    margin-top: 100px !important;
  }
  .margin-md-right-100 {
    margin-right: 100px !important;
  }
  .margin-md-bottom-100 {
    margin-bottom: 100px !important;
  }
  .margin-md-left-100 {
    margin-left: 100px !important;
  }
  .margin-md-neg-100 {
    margin: 100px !important;
  }
  .margin-md-top-neg-100 {
    margin-top: 100px !important;
  }
  .margin-md-right-neg-100 {
    margin-right: 100px !important;
  }
  .margin-md-bottom-neg-100 {
    margin-bottom: 100px !important;
  }
  .margin-md-left-neg-100 {
    margin-left: 100px !important;
  }
}
@media only screen and (min-width: 1200px) {
  .padding-lg-0 {
    padding: 0px !important;
  }
  .padding-lg-top-0 {
    padding-top: 0px !important;
  }
  .padding-lg-right-0 {
    padding-right: 0px !important;
  }
  .padding-lg-bottom-0 {
    padding-bottom: 0px !important;
  }
  .padding-lg-left-0 {
    padding-left: 0px !important;
  }
  .padding-lg-neg-0 {
    padding: 0px !important;
  }
  .padding-lg-top-neg-0 {
    padding-top: 0px !important;
  }
  .padding-lg-right-neg-0 {
    padding-right: 0px !important;
  }
  .padding-lg-bottom-neg-0 {
    padding-bottom: 0px !important;
  }
  .padding-lg-left-neg-0 {
    padding-left: 0px !important;
  }
  .padding-lg-5 {
    padding: 5px !important;
  }
  .padding-lg-top-5 {
    padding-top: 5px !important;
  }
  .padding-lg-right-5 {
    padding-right: 5px !important;
  }
  .padding-lg-bottom-5 {
    padding-bottom: 5px !important;
  }
  .padding-lg-left-5 {
    padding-left: 5px !important;
  }
  .padding-lg-neg-5 {
    padding: 5px !important;
  }
  .padding-lg-top-neg-5 {
    padding-top: 5px !important;
  }
  .padding-lg-right-neg-5 {
    padding-right: 5px !important;
  }
  .padding-lg-bottom-neg-5 {
    padding-bottom: 5px !important;
  }
  .padding-lg-left-neg-5 {
    padding-left: 5px !important;
  }
  .padding-lg-10 {
    padding: 10px !important;
  }
  .padding-lg-top-10 {
    padding-top: 10px !important;
  }
  .padding-lg-right-10 {
    padding-right: 10px !important;
  }
  .padding-lg-bottom-10 {
    padding-bottom: 10px !important;
  }
  .padding-lg-left-10 {
    padding-left: 10px !important;
  }
  .padding-lg-neg-10 {
    padding: 10px !important;
  }
  .padding-lg-top-neg-10 {
    padding-top: 10px !important;
  }
  .padding-lg-right-neg-10 {
    padding-right: 10px !important;
  }
  .padding-lg-bottom-neg-10 {
    padding-bottom: 10px !important;
  }
  .padding-lg-left-neg-10 {
    padding-left: 10px !important;
  }
  .padding-lg-15 {
    padding: 15px !important;
  }
  .padding-lg-top-15 {
    padding-top: 15px !important;
  }
  .padding-lg-right-15 {
    padding-right: 15px !important;
  }
  .padding-lg-bottom-15 {
    padding-bottom: 15px !important;
  }
  .padding-lg-left-15 {
    padding-left: 15px !important;
  }
  .padding-lg-neg-15 {
    padding: 15px !important;
  }
  .padding-lg-top-neg-15 {
    padding-top: 15px !important;
  }
  .padding-lg-right-neg-15 {
    padding-right: 15px !important;
  }
  .padding-lg-bottom-neg-15 {
    padding-bottom: 15px !important;
  }
  .padding-lg-left-neg-15 {
    padding-left: 15px !important;
  }
  .padding-lg-20 {
    padding: 20px !important;
  }
  .padding-lg-top-20 {
    padding-top: 20px !important;
  }
  .padding-lg-right-20 {
    padding-right: 20px !important;
  }
  .padding-lg-bottom-20 {
    padding-bottom: 20px !important;
  }
  .padding-lg-left-20 {
    padding-left: 20px !important;
  }
  .padding-lg-neg-20 {
    padding: 20px !important;
  }
  .padding-lg-top-neg-20 {
    padding-top: 20px !important;
  }
  .padding-lg-right-neg-20 {
    padding-right: 20px !important;
  }
  .padding-lg-bottom-neg-20 {
    padding-bottom: 20px !important;
  }
  .padding-lg-left-neg-20 {
    padding-left: 20px !important;
  }
  .padding-lg-25 {
    padding: 25px !important;
  }
  .padding-lg-top-25 {
    padding-top: 25px !important;
  }
  .padding-lg-right-25 {
    padding-right: 25px !important;
  }
  .padding-lg-bottom-25 {
    padding-bottom: 25px !important;
  }
  .padding-lg-left-25 {
    padding-left: 25px !important;
  }
  .padding-lg-neg-25 {
    padding: 25px !important;
  }
  .padding-lg-top-neg-25 {
    padding-top: 25px !important;
  }
  .padding-lg-right-neg-25 {
    padding-right: 25px !important;
  }
  .padding-lg-bottom-neg-25 {
    padding-bottom: 25px !important;
  }
  .padding-lg-left-neg-25 {
    padding-left: 25px !important;
  }
  .padding-lg-30 {
    padding: 30px !important;
  }
  .padding-lg-top-30 {
    padding-top: 30px !important;
  }
  .padding-lg-right-30 {
    padding-right: 30px !important;
  }
  .padding-lg-bottom-30 {
    padding-bottom: 30px !important;
  }
  .padding-lg-left-30 {
    padding-left: 30px !important;
  }
  .padding-lg-neg-30 {
    padding: 30px !important;
  }
  .padding-lg-top-neg-30 {
    padding-top: 30px !important;
  }
  .padding-lg-right-neg-30 {
    padding-right: 30px !important;
  }
  .padding-lg-bottom-neg-30 {
    padding-bottom: 30px !important;
  }
  .padding-lg-left-neg-30 {
    padding-left: 30px !important;
  }
  .padding-lg-35 {
    padding: 35px !important;
  }
  .padding-lg-top-35 {
    padding-top: 35px !important;
  }
  .padding-lg-right-35 {
    padding-right: 35px !important;
  }
  .padding-lg-bottom-35 {
    padding-bottom: 35px !important;
  }
  .padding-lg-left-35 {
    padding-left: 35px !important;
  }
  .padding-lg-neg-35 {
    padding: 35px !important;
  }
  .padding-lg-top-neg-35 {
    padding-top: 35px !important;
  }
  .padding-lg-right-neg-35 {
    padding-right: 35px !important;
  }
  .padding-lg-bottom-neg-35 {
    padding-bottom: 35px !important;
  }
  .padding-lg-left-neg-35 {
    padding-left: 35px !important;
  }
  .padding-lg-40 {
    padding: 40px !important;
  }
  .padding-lg-top-40 {
    padding-top: 40px !important;
  }
  .padding-lg-right-40 {
    padding-right: 40px !important;
  }
  .padding-lg-bottom-40 {
    padding-bottom: 40px !important;
  }
  .padding-lg-left-40 {
    padding-left: 40px !important;
  }
  .padding-lg-neg-40 {
    padding: 40px !important;
  }
  .padding-lg-top-neg-40 {
    padding-top: 40px !important;
  }
  .padding-lg-right-neg-40 {
    padding-right: 40px !important;
  }
  .padding-lg-bottom-neg-40 {
    padding-bottom: 40px !important;
  }
  .padding-lg-left-neg-40 {
    padding-left: 40px !important;
  }
  .padding-lg-45 {
    padding: 45px !important;
  }
  .padding-lg-top-45 {
    padding-top: 45px !important;
  }
  .padding-lg-right-45 {
    padding-right: 45px !important;
  }
  .padding-lg-bottom-45 {
    padding-bottom: 45px !important;
  }
  .padding-lg-left-45 {
    padding-left: 45px !important;
  }
  .padding-lg-neg-45 {
    padding: 45px !important;
  }
  .padding-lg-top-neg-45 {
    padding-top: 45px !important;
  }
  .padding-lg-right-neg-45 {
    padding-right: 45px !important;
  }
  .padding-lg-bottom-neg-45 {
    padding-bottom: 45px !important;
  }
  .padding-lg-left-neg-45 {
    padding-left: 45px !important;
  }
  .padding-lg-50 {
    padding: 50px !important;
  }
  .padding-lg-top-50 {
    padding-top: 50px !important;
  }
  .padding-lg-right-50 {
    padding-right: 50px !important;
  }
  .padding-lg-bottom-50 {
    padding-bottom: 50px !important;
  }
  .padding-lg-left-50 {
    padding-left: 50px !important;
  }
  .padding-lg-neg-50 {
    padding: 50px !important;
  }
  .padding-lg-top-neg-50 {
    padding-top: 50px !important;
  }
  .padding-lg-right-neg-50 {
    padding-right: 50px !important;
  }
  .padding-lg-bottom-neg-50 {
    padding-bottom: 50px !important;
  }
  .padding-lg-left-neg-50 {
    padding-left: 50px !important;
  }
  .padding-lg-55 {
    padding: 55px !important;
  }
  .padding-lg-top-55 {
    padding-top: 55px !important;
  }
  .padding-lg-right-55 {
    padding-right: 55px !important;
  }
  .padding-lg-bottom-55 {
    padding-bottom: 55px !important;
  }
  .padding-lg-left-55 {
    padding-left: 55px !important;
  }
  .padding-lg-neg-55 {
    padding: 55px !important;
  }
  .padding-lg-top-neg-55 {
    padding-top: 55px !important;
  }
  .padding-lg-right-neg-55 {
    padding-right: 55px !important;
  }
  .padding-lg-bottom-neg-55 {
    padding-bottom: 55px !important;
  }
  .padding-lg-left-neg-55 {
    padding-left: 55px !important;
  }
  .padding-lg-60 {
    padding: 60px !important;
  }
  .padding-lg-top-60 {
    padding-top: 60px !important;
  }
  .padding-lg-right-60 {
    padding-right: 60px !important;
  }
  .padding-lg-bottom-60 {
    padding-bottom: 60px !important;
  }
  .padding-lg-left-60 {
    padding-left: 60px !important;
  }
  .padding-lg-neg-60 {
    padding: 60px !important;
  }
  .padding-lg-top-neg-60 {
    padding-top: 60px !important;
  }
  .padding-lg-right-neg-60 {
    padding-right: 60px !important;
  }
  .padding-lg-bottom-neg-60 {
    padding-bottom: 60px !important;
  }
  .padding-lg-left-neg-60 {
    padding-left: 60px !important;
  }
  .padding-lg-65 {
    padding: 65px !important;
  }
  .padding-lg-top-65 {
    padding-top: 65px !important;
  }
  .padding-lg-right-65 {
    padding-right: 65px !important;
  }
  .padding-lg-bottom-65 {
    padding-bottom: 65px !important;
  }
  .padding-lg-left-65 {
    padding-left: 65px !important;
  }
  .padding-lg-neg-65 {
    padding: 65px !important;
  }
  .padding-lg-top-neg-65 {
    padding-top: 65px !important;
  }
  .padding-lg-right-neg-65 {
    padding-right: 65px !important;
  }
  .padding-lg-bottom-neg-65 {
    padding-bottom: 65px !important;
  }
  .padding-lg-left-neg-65 {
    padding-left: 65px !important;
  }
  .padding-lg-70 {
    padding: 70px !important;
  }
  .padding-lg-top-70 {
    padding-top: 70px !important;
  }
  .padding-lg-right-70 {
    padding-right: 70px !important;
  }
  .padding-lg-bottom-70 {
    padding-bottom: 70px !important;
  }
  .padding-lg-left-70 {
    padding-left: 70px !important;
  }
  .padding-lg-neg-70 {
    padding: 70px !important;
  }
  .padding-lg-top-neg-70 {
    padding-top: 70px !important;
  }
  .padding-lg-right-neg-70 {
    padding-right: 70px !important;
  }
  .padding-lg-bottom-neg-70 {
    padding-bottom: 70px !important;
  }
  .padding-lg-left-neg-70 {
    padding-left: 70px !important;
  }
  .padding-lg-75 {
    padding: 75px !important;
  }
  .padding-lg-top-75 {
    padding-top: 75px !important;
  }
  .padding-lg-right-75 {
    padding-right: 75px !important;
  }
  .padding-lg-bottom-75 {
    padding-bottom: 75px !important;
  }
  .padding-lg-left-75 {
    padding-left: 75px !important;
  }
  .padding-lg-neg-75 {
    padding: 75px !important;
  }
  .padding-lg-top-neg-75 {
    padding-top: 75px !important;
  }
  .padding-lg-right-neg-75 {
    padding-right: 75px !important;
  }
  .padding-lg-bottom-neg-75 {
    padding-bottom: 75px !important;
  }
  .padding-lg-left-neg-75 {
    padding-left: 75px !important;
  }
  .padding-lg-80 {
    padding: 80px !important;
  }
  .padding-lg-top-80 {
    padding-top: 80px !important;
  }
  .padding-lg-right-80 {
    padding-right: 80px !important;
  }
  .padding-lg-bottom-80 {
    padding-bottom: 80px !important;
  }
  .padding-lg-left-80 {
    padding-left: 80px !important;
  }
  .padding-lg-neg-80 {
    padding: 80px !important;
  }
  .padding-lg-top-neg-80 {
    padding-top: 80px !important;
  }
  .padding-lg-right-neg-80 {
    padding-right: 80px !important;
  }
  .padding-lg-bottom-neg-80 {
    padding-bottom: 80px !important;
  }
  .padding-lg-left-neg-80 {
    padding-left: 80px !important;
  }
  .padding-lg-85 {
    padding: 85px !important;
  }
  .padding-lg-top-85 {
    padding-top: 85px !important;
  }
  .padding-lg-right-85 {
    padding-right: 85px !important;
  }
  .padding-lg-bottom-85 {
    padding-bottom: 85px !important;
  }
  .padding-lg-left-85 {
    padding-left: 85px !important;
  }
  .padding-lg-neg-85 {
    padding: 85px !important;
  }
  .padding-lg-top-neg-85 {
    padding-top: 85px !important;
  }
  .padding-lg-right-neg-85 {
    padding-right: 85px !important;
  }
  .padding-lg-bottom-neg-85 {
    padding-bottom: 85px !important;
  }
  .padding-lg-left-neg-85 {
    padding-left: 85px !important;
  }
  .padding-lg-90 {
    padding: 90px !important;
  }
  .padding-lg-top-90 {
    padding-top: 90px !important;
  }
  .padding-lg-right-90 {
    padding-right: 90px !important;
  }
  .padding-lg-bottom-90 {
    padding-bottom: 90px !important;
  }
  .padding-lg-left-90 {
    padding-left: 90px !important;
  }
  .padding-lg-neg-90 {
    padding: 90px !important;
  }
  .padding-lg-top-neg-90 {
    padding-top: 90px !important;
  }
  .padding-lg-right-neg-90 {
    padding-right: 90px !important;
  }
  .padding-lg-bottom-neg-90 {
    padding-bottom: 90px !important;
  }
  .padding-lg-left-neg-90 {
    padding-left: 90px !important;
  }
  .padding-lg-95 {
    padding: 95px !important;
  }
  .padding-lg-top-95 {
    padding-top: 95px !important;
  }
  .padding-lg-right-95 {
    padding-right: 95px !important;
  }
  .padding-lg-bottom-95 {
    padding-bottom: 95px !important;
  }
  .padding-lg-left-95 {
    padding-left: 95px !important;
  }
  .padding-lg-neg-95 {
    padding: 95px !important;
  }
  .padding-lg-top-neg-95 {
    padding-top: 95px !important;
  }
  .padding-lg-right-neg-95 {
    padding-right: 95px !important;
  }
  .padding-lg-bottom-neg-95 {
    padding-bottom: 95px !important;
  }
  .padding-lg-left-neg-95 {
    padding-left: 95px !important;
  }
  .padding-lg-100 {
    padding: 100px !important;
  }
  .padding-lg-top-100 {
    padding-top: 100px !important;
  }
  .padding-lg-right-100 {
    padding-right: 100px !important;
  }
  .padding-lg-bottom-100 {
    padding-bottom: 100px !important;
  }
  .padding-lg-left-100 {
    padding-left: 100px !important;
  }
  .padding-lg-neg-100 {
    padding: 100px !important;
  }
  .padding-lg-top-neg-100 {
    padding-top: 100px !important;
  }
  .padding-lg-right-neg-100 {
    padding-right: 100px !important;
  }
  .padding-lg-bottom-neg-100 {
    padding-bottom: 100px !important;
  }
  .padding-lg-left-neg-100 {
    padding-left: 100px !important;
  }
  .margin-lg-0 {
    margin: 0px !important;
  }
  .margin-lg-top-0 {
    margin-top: 0px !important;
  }
  .margin-lg-right-0 {
    margin-right: 0px !important;
  }
  .margin-lg-bottom-0 {
    margin-bottom: 0px !important;
  }
  .margin-lg-left-0 {
    margin-left: 0px !important;
  }
  .margin-lg-neg-0 {
    margin: 0px !important;
  }
  .margin-lg-top-neg-0 {
    margin-top: 0px !important;
  }
  .margin-lg-right-neg-0 {
    margin-right: 0px !important;
  }
  .margin-lg-bottom-neg-0 {
    margin-bottom: 0px !important;
  }
  .margin-lg-left-neg-0 {
    margin-left: 0px !important;
  }
  .margin-lg-5 {
    margin: 5px !important;
  }
  .margin-lg-top-5 {
    margin-top: 5px !important;
  }
  .margin-lg-right-5 {
    margin-right: 5px !important;
  }
  .margin-lg-bottom-5 {
    margin-bottom: 5px !important;
  }
  .margin-lg-left-5 {
    margin-left: 5px !important;
  }
  .margin-lg-neg-5 {
    margin: 5px !important;
  }
  .margin-lg-top-neg-5 {
    margin-top: 5px !important;
  }
  .margin-lg-right-neg-5 {
    margin-right: 5px !important;
  }
  .margin-lg-bottom-neg-5 {
    margin-bottom: 5px !important;
  }
  .margin-lg-left-neg-5 {
    margin-left: 5px !important;
  }
  .margin-lg-10 {
    margin: 10px !important;
  }
  .margin-lg-top-10 {
    margin-top: 10px !important;
  }
  .margin-lg-right-10 {
    margin-right: 10px !important;
  }
  .margin-lg-bottom-10 {
    margin-bottom: 10px !important;
  }
  .margin-lg-left-10 {
    margin-left: 10px !important;
  }
  .margin-lg-neg-10 {
    margin: 10px !important;
  }
  .margin-lg-top-neg-10 {
    margin-top: 10px !important;
  }
  .margin-lg-right-neg-10 {
    margin-right: 10px !important;
  }
  .margin-lg-bottom-neg-10 {
    margin-bottom: 10px !important;
  }
  .margin-lg-left-neg-10 {
    margin-left: 10px !important;
  }
  .margin-lg-15 {
    margin: 15px !important;
  }
  .margin-lg-top-15 {
    margin-top: 15px !important;
  }
  .margin-lg-right-15 {
    margin-right: 15px !important;
  }
  .margin-lg-bottom-15 {
    margin-bottom: 15px !important;
  }
  .margin-lg-left-15 {
    margin-left: 15px !important;
  }
  .margin-lg-neg-15 {
    margin: 15px !important;
  }
  .margin-lg-top-neg-15 {
    margin-top: 15px !important;
  }
  .margin-lg-right-neg-15 {
    margin-right: 15px !important;
  }
  .margin-lg-bottom-neg-15 {
    margin-bottom: 15px !important;
  }
  .margin-lg-left-neg-15 {
    margin-left: 15px !important;
  }
  .margin-lg-20 {
    margin: 20px !important;
  }
  .margin-lg-top-20 {
    margin-top: 20px !important;
  }
  .margin-lg-right-20 {
    margin-right: 20px !important;
  }
  .margin-lg-bottom-20 {
    margin-bottom: 20px !important;
  }
  .margin-lg-left-20 {
    margin-left: 20px !important;
  }
  .margin-lg-neg-20 {
    margin: 20px !important;
  }
  .margin-lg-top-neg-20 {
    margin-top: 20px !important;
  }
  .margin-lg-right-neg-20 {
    margin-right: 20px !important;
  }
  .margin-lg-bottom-neg-20 {
    margin-bottom: 20px !important;
  }
  .margin-lg-left-neg-20 {
    margin-left: 20px !important;
  }
  .margin-lg-25 {
    margin: 25px !important;
  }
  .margin-lg-top-25 {
    margin-top: 25px !important;
  }
  .margin-lg-right-25 {
    margin-right: 25px !important;
  }
  .margin-lg-bottom-25 {
    margin-bottom: 25px !important;
  }
  .margin-lg-left-25 {
    margin-left: 25px !important;
  }
  .margin-lg-neg-25 {
    margin: 25px !important;
  }
  .margin-lg-top-neg-25 {
    margin-top: 25px !important;
  }
  .margin-lg-right-neg-25 {
    margin-right: 25px !important;
  }
  .margin-lg-bottom-neg-25 {
    margin-bottom: 25px !important;
  }
  .margin-lg-left-neg-25 {
    margin-left: 25px !important;
  }
  .margin-lg-30 {
    margin: 30px !important;
  }
  .margin-lg-top-30 {
    margin-top: 30px !important;
  }
  .margin-lg-right-30 {
    margin-right: 30px !important;
  }
  .margin-lg-bottom-30 {
    margin-bottom: 30px !important;
  }
  .margin-lg-left-30 {
    margin-left: 30px !important;
  }
  .margin-lg-neg-30 {
    margin: 30px !important;
  }
  .margin-lg-top-neg-30 {
    margin-top: 30px !important;
  }
  .margin-lg-right-neg-30 {
    margin-right: 30px !important;
  }
  .margin-lg-bottom-neg-30 {
    margin-bottom: 30px !important;
  }
  .margin-lg-left-neg-30 {
    margin-left: 30px !important;
  }
  .margin-lg-35 {
    margin: 35px !important;
  }
  .margin-lg-top-35 {
    margin-top: 35px !important;
  }
  .margin-lg-right-35 {
    margin-right: 35px !important;
  }
  .margin-lg-bottom-35 {
    margin-bottom: 35px !important;
  }
  .margin-lg-left-35 {
    margin-left: 35px !important;
  }
  .margin-lg-neg-35 {
    margin: 35px !important;
  }
  .margin-lg-top-neg-35 {
    margin-top: 35px !important;
  }
  .margin-lg-right-neg-35 {
    margin-right: 35px !important;
  }
  .margin-lg-bottom-neg-35 {
    margin-bottom: 35px !important;
  }
  .margin-lg-left-neg-35 {
    margin-left: 35px !important;
  }
  .margin-lg-40 {
    margin: 40px !important;
  }
  .margin-lg-top-40 {
    margin-top: 40px !important;
  }
  .margin-lg-right-40 {
    margin-right: 40px !important;
  }
  .margin-lg-bottom-40 {
    margin-bottom: 40px !important;
  }
  .margin-lg-left-40 {
    margin-left: 40px !important;
  }
  .margin-lg-neg-40 {
    margin: 40px !important;
  }
  .margin-lg-top-neg-40 {
    margin-top: 40px !important;
  }
  .margin-lg-right-neg-40 {
    margin-right: 40px !important;
  }
  .margin-lg-bottom-neg-40 {
    margin-bottom: 40px !important;
  }
  .margin-lg-left-neg-40 {
    margin-left: 40px !important;
  }
  .margin-lg-45 {
    margin: 45px !important;
  }
  .margin-lg-top-45 {
    margin-top: 45px !important;
  }
  .margin-lg-right-45 {
    margin-right: 45px !important;
  }
  .margin-lg-bottom-45 {
    margin-bottom: 45px !important;
  }
  .margin-lg-left-45 {
    margin-left: 45px !important;
  }
  .margin-lg-neg-45 {
    margin: 45px !important;
  }
  .margin-lg-top-neg-45 {
    margin-top: 45px !important;
  }
  .margin-lg-right-neg-45 {
    margin-right: 45px !important;
  }
  .margin-lg-bottom-neg-45 {
    margin-bottom: 45px !important;
  }
  .margin-lg-left-neg-45 {
    margin-left: 45px !important;
  }
  .margin-lg-50 {
    margin: 50px !important;
  }
  .margin-lg-top-50 {
    margin-top: 50px !important;
  }
  .margin-lg-right-50 {
    margin-right: 50px !important;
  }
  .margin-lg-bottom-50 {
    margin-bottom: 50px !important;
  }
  .margin-lg-left-50 {
    margin-left: 50px !important;
  }
  .margin-lg-neg-50 {
    margin: 50px !important;
  }
  .margin-lg-top-neg-50 {
    margin-top: 50px !important;
  }
  .margin-lg-right-neg-50 {
    margin-right: 50px !important;
  }
  .margin-lg-bottom-neg-50 {
    margin-bottom: 50px !important;
  }
  .margin-lg-left-neg-50 {
    margin-left: 50px !important;
  }
  .margin-lg-55 {
    margin: 55px !important;
  }
  .margin-lg-top-55 {
    margin-top: 55px !important;
  }
  .margin-lg-right-55 {
    margin-right: 55px !important;
  }
  .margin-lg-bottom-55 {
    margin-bottom: 55px !important;
  }
  .margin-lg-left-55 {
    margin-left: 55px !important;
  }
  .margin-lg-neg-55 {
    margin: 55px !important;
  }
  .margin-lg-top-neg-55 {
    margin-top: 55px !important;
  }
  .margin-lg-right-neg-55 {
    margin-right: 55px !important;
  }
  .margin-lg-bottom-neg-55 {
    margin-bottom: 55px !important;
  }
  .margin-lg-left-neg-55 {
    margin-left: 55px !important;
  }
  .margin-lg-60 {
    margin: 60px !important;
  }
  .margin-lg-top-60 {
    margin-top: 60px !important;
  }
  .margin-lg-right-60 {
    margin-right: 60px !important;
  }
  .margin-lg-bottom-60 {
    margin-bottom: 60px !important;
  }
  .margin-lg-left-60 {
    margin-left: 60px !important;
  }
  .margin-lg-neg-60 {
    margin: 60px !important;
  }
  .margin-lg-top-neg-60 {
    margin-top: 60px !important;
  }
  .margin-lg-right-neg-60 {
    margin-right: 60px !important;
  }
  .margin-lg-bottom-neg-60 {
    margin-bottom: 60px !important;
  }
  .margin-lg-left-neg-60 {
    margin-left: 60px !important;
  }
  .margin-lg-65 {
    margin: 65px !important;
  }
  .margin-lg-top-65 {
    margin-top: 65px !important;
  }
  .margin-lg-right-65 {
    margin-right: 65px !important;
  }
  .margin-lg-bottom-65 {
    margin-bottom: 65px !important;
  }
  .margin-lg-left-65 {
    margin-left: 65px !important;
  }
  .margin-lg-neg-65 {
    margin: 65px !important;
  }
  .margin-lg-top-neg-65 {
    margin-top: 65px !important;
  }
  .margin-lg-right-neg-65 {
    margin-right: 65px !important;
  }
  .margin-lg-bottom-neg-65 {
    margin-bottom: 65px !important;
  }
  .margin-lg-left-neg-65 {
    margin-left: 65px !important;
  }
  .margin-lg-70 {
    margin: 70px !important;
  }
  .margin-lg-top-70 {
    margin-top: 70px !important;
  }
  .margin-lg-right-70 {
    margin-right: 70px !important;
  }
  .margin-lg-bottom-70 {
    margin-bottom: 70px !important;
  }
  .margin-lg-left-70 {
    margin-left: 70px !important;
  }
  .margin-lg-neg-70 {
    margin: 70px !important;
  }
  .margin-lg-top-neg-70 {
    margin-top: 70px !important;
  }
  .margin-lg-right-neg-70 {
    margin-right: 70px !important;
  }
  .margin-lg-bottom-neg-70 {
    margin-bottom: 70px !important;
  }
  .margin-lg-left-neg-70 {
    margin-left: 70px !important;
  }
  .margin-lg-75 {
    margin: 75px !important;
  }
  .margin-lg-top-75 {
    margin-top: 75px !important;
  }
  .margin-lg-right-75 {
    margin-right: 75px !important;
  }
  .margin-lg-bottom-75 {
    margin-bottom: 75px !important;
  }
  .margin-lg-left-75 {
    margin-left: 75px !important;
  }
  .margin-lg-neg-75 {
    margin: 75px !important;
  }
  .margin-lg-top-neg-75 {
    margin-top: 75px !important;
  }
  .margin-lg-right-neg-75 {
    margin-right: 75px !important;
  }
  .margin-lg-bottom-neg-75 {
    margin-bottom: 75px !important;
  }
  .margin-lg-left-neg-75 {
    margin-left: 75px !important;
  }
  .margin-lg-80 {
    margin: 80px !important;
  }
  .margin-lg-top-80 {
    margin-top: 80px !important;
  }
  .margin-lg-right-80 {
    margin-right: 80px !important;
  }
  .margin-lg-bottom-80 {
    margin-bottom: 80px !important;
  }
  .margin-lg-left-80 {
    margin-left: 80px !important;
  }
  .margin-lg-neg-80 {
    margin: 80px !important;
  }
  .margin-lg-top-neg-80 {
    margin-top: 80px !important;
  }
  .margin-lg-right-neg-80 {
    margin-right: 80px !important;
  }
  .margin-lg-bottom-neg-80 {
    margin-bottom: 80px !important;
  }
  .margin-lg-left-neg-80 {
    margin-left: 80px !important;
  }
  .margin-lg-85 {
    margin: 85px !important;
  }
  .margin-lg-top-85 {
    margin-top: 85px !important;
  }
  .margin-lg-right-85 {
    margin-right: 85px !important;
  }
  .margin-lg-bottom-85 {
    margin-bottom: 85px !important;
  }
  .margin-lg-left-85 {
    margin-left: 85px !important;
  }
  .margin-lg-neg-85 {
    margin: 85px !important;
  }
  .margin-lg-top-neg-85 {
    margin-top: 85px !important;
  }
  .margin-lg-right-neg-85 {
    margin-right: 85px !important;
  }
  .margin-lg-bottom-neg-85 {
    margin-bottom: 85px !important;
  }
  .margin-lg-left-neg-85 {
    margin-left: 85px !important;
  }
  .margin-lg-90 {
    margin: 90px !important;
  }
  .margin-lg-top-90 {
    margin-top: 90px !important;
  }
  .margin-lg-right-90 {
    margin-right: 90px !important;
  }
  .margin-lg-bottom-90 {
    margin-bottom: 90px !important;
  }
  .margin-lg-left-90 {
    margin-left: 90px !important;
  }
  .margin-lg-neg-90 {
    margin: 90px !important;
  }
  .margin-lg-top-neg-90 {
    margin-top: 90px !important;
  }
  .margin-lg-right-neg-90 {
    margin-right: 90px !important;
  }
  .margin-lg-bottom-neg-90 {
    margin-bottom: 90px !important;
  }
  .margin-lg-left-neg-90 {
    margin-left: 90px !important;
  }
  .margin-lg-95 {
    margin: 95px !important;
  }
  .margin-lg-top-95 {
    margin-top: 95px !important;
  }
  .margin-lg-right-95 {
    margin-right: 95px !important;
  }
  .margin-lg-bottom-95 {
    margin-bottom: 95px !important;
  }
  .margin-lg-left-95 {
    margin-left: 95px !important;
  }
  .margin-lg-neg-95 {
    margin: 95px !important;
  }
  .margin-lg-top-neg-95 {
    margin-top: 95px !important;
  }
  .margin-lg-right-neg-95 {
    margin-right: 95px !important;
  }
  .margin-lg-bottom-neg-95 {
    margin-bottom: 95px !important;
  }
  .margin-lg-left-neg-95 {
    margin-left: 95px !important;
  }
  .margin-lg-100 {
    margin: 100px !important;
  }
  .margin-lg-top-100 {
    margin-top: 100px !important;
  }
  .margin-lg-right-100 {
    margin-right: 100px !important;
  }
  .margin-lg-bottom-100 {
    margin-bottom: 100px !important;
  }
  .margin-lg-left-100 {
    margin-left: 100px !important;
  }
  .margin-lg-neg-100 {
    margin: 100px !important;
  }
  .margin-lg-top-neg-100 {
    margin-top: 100px !important;
  }
  .margin-lg-right-neg-100 {
    margin-right: 100px !important;
  }
  .margin-lg-bottom-neg-100 {
    margin-bottom: 100px !important;
  }
  .margin-lg-left-neg-100 {
    margin-left: 100px !important;
  }
}
/* Body */
body {
  background-image: none;
}
/* Header/top nav */
sa-header,
public-header {
  height: 68px;
}
#header {
  padding: 0;
  height: 68px;
  background: none #ffffff;
  border-bottom: 5px solid #dddddd;
}
#header > #logo-group {
  margin-left: 5%;
  width: 80%;
  display: table;
}
#header > #logo-group h2 {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  height: 63px;
  width: 100%;
}
#header > #logo-group h2.smaller {
  font-size: 15px;
}
#header > #action-group {
  float: right;
  margin-right: 5%;
}
#public-header {
  padding: 0;
  height: 68px;
  background: none #ffffff;
  border-bottom: 5px solid #dddddd;
  position: static;
  width: 100%;
  z-index: 999;
}
#public-header > #logo-group {
  float: left;
  margin-left: 5%;
  width: 20%;
  display: table;
}
#public-header > #logo-group h2 {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  height: 63px;
  width: 100%;
}
#public-header > #logo-group h2.smaller {
  font-size: 15px;
}
#public-header > #logo-group #logo {
  text-align: left;
}
#public-header > #action-group {
  float: right;
  margin-top: 12px;
  margin-right: 5%;
}
#logo {
  height: 100%;
  margin: 15px 0;
  text-align: left;
}
#logo img {
  max-height: 39px;
  max-width: 200px;
  height: auto;
  width: auto;
}
#login-info {
  margin-top: 8px;
}
#login-info a {
  display: inline-block;
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -5px;
  margin-bottom: -5px;
}
#hide-menu > :first-child > a,
#logout > :first-child > a,
#select-client > :first-child > a,
#select-portal > :first-child > a,
#select-producer > :first-child > a {
  width: 35px !important;
  margin-top: 15px !important;
  height: 35px !important;
  line-height: 25px !important;
  font-size: 17px !important;
}
#hide-menu i,
#logout i,
#select-client i,
#select-portal i,
#select-producer i {
  font-size: 100%;
}
#hide-menu > span:first-child > a,
.hidden-menu #hide-menu > span:first-child > a,
#logout > span:first-child > a,
#select-client > span:first-child > a,
#select-portal > span:first-child > a,
#select-producer > span:first-child > a {
  margin-top: 15px !important;
}
#hide-menu > span:first-child > a,
.hidden-menu #hide-menu > span:first-child > a,
#logout > span:first-child > a,
#select-client > span:first-child > a,
#select-portal > span:first-child > a,
#select-producer > span:first-child > a,
#hide-menu > span:first-child > a:hover,
.hidden-menu #hide-menu > span:first-child > a:hover,
#logout > span:first-child > a:hover,
#select-client > span:first-child > a:hover,
#select-portal > span:first-child > a:hover,
#select-producer > span:first-child > a:hover,
#hide-menu > span:first-child > a:active,
.hidden-menu #hide-menu > span:first-child > a:active,
#logout > span:first-child > a:active,
#select-client > span:first-child > a:active,
#select-portal > span:first-child > a:active,
#select-producer > span:first-child > a:active,
#hide-menu > span:first-child > a:focus,
.hidden-menu #hide-menu > span:first-child > a:focus,
#logout > span:first-child > a:focus,
#select-client > span:first-child > a:focus,
#select-portal > span:first-child > a:focus,
#select-producer > span:first-child > a:focus,
#hide-menu > span:first-child > a:visited,
.hidden-menu #hide-menu > span:first-child > a:visited,
#logout > span:first-child > a:visited,
#select-client > span:first-child > a:visited,
#select-portal > span:first-child > a:visited,
#select-producer > span:first-child > a:visited,
#hide-menu > span:first-child > a > i,
.hidden-menu #hide-menu > span:first-child > a > i,
#logout > span:first-child > a > i,
#select-client > span:first-child > a > i,
#select-portal > span:first-child > a > i,
#select-producer > span:first-child > a > i,
#hide-menu > span:first-child > a:hover > i,
.hidden-menu #hide-menu > span:first-child > a:hover > i,
#logout > span:first-child > a:hover > i,
#select-client > span:first-child > a:hover > i,
#select-portal > span:first-child > a:hover > i,
#select-producer > span:first-child > a:hover > i,
#hide-menu > span:first-child > a:active > i,
.hidden-menu #hide-menu > span:first-child > a:active > i,
#logout > span:first-child > a:active > i,
#select-client > span:first-child > a:active > i,
#select-portal > span:first-child > a:active > i,
#select-producer > span:first-child > a:active > i,
#hide-menu > span:first-child > a:focus > i,
.hidden-menu #hide-menu > span:first-child > a:focus > i,
#logout > span:first-child > a:focus > i,
#select-client > span:first-child > a:focus > i,
#select-portal > span:first-child > a:focus > i,
#select-producer > span:first-child > a:focus > i,
#hide-menu > span:first-child > a:visited > i,
.hidden-menu #hide-menu > span:first-child > a:visited > i,
#logout > span:first-child > a:visited > i,
#select-client > span:first-child > a:visited > i,
#select-portal > span:first-child > a:visited > i,
#select-producer > span:first-child > a:visited > i {
  color: #222 !important;
  box-shadow: none !important;
}
.btn-header {
  display: inline-block;
}
.btn-header > :first-child > a,
.btn-header > :first-child > a:hover,
.btn-header > :first-child > a:active,
.hidden-menu #hide-menu > :first-child > a {
  width: 35px;
  margin-top: 4px;
  background: none #dddddd;
  border: 3px solid #dddddd;
  border-radius: 0;
  height: 35px;
  line-height: 25px;
  box-shadow: none;
  cursor: pointer !important;
}
.hidden-menu #hide-menu > :first-child > a {
  color: #54b7ff;
}
.header-search {
  display: inline-block;
  overflow: hidden;
  margin-top: 4px;
  border: 3px solid #dddddd;
  width: 200px;
  transition: width 200ms ease-out;
}
.header-search:hover i,
.header-search :active i {
  color: #222;
}
.header-search.zipped {
  width: 35px;
}
.header-search.zipped input {
  background-color: #dddddd;
}
.header-search > input[type="text"] {
  border: none !important;
  margin-top: 0;
}
.header-search > button[type="button"] {
  top: 0;
}
@media (min-width: 980px) {
  #hide-menu {
    display: none;
  }
}
@media (max-width: 979px) and (min-width: 768px) {
  .btn-header > :first-child > a,
  .btn-header > :first-child > a:hover,
  .btn-header > :first-child > a:active,
  .hidden-menu #hide-menu > :first-child > a {
    width: 35px !important;
    margin-top: 4px !important;
  }
  #logo,
  #header > #logo-group h2 {
    text-align: left;
  }
}
@media (max-width: 880px) and (min-width: 768px) {
  #hide-menu > :first-child > a,
  .btn-header a {
    width: 35px !important;
    line-height: 24px !important;
    font-size: 17px !important;
    padding-top: 3px;
  }
  #hide-menu i {
    font-size: 100%;
  }
  #hide-menu i:hover,
  #hide-menu i:active {
    color: #222;
  }
}
@media (max-width: 880px) {
  #logout > :first-child > a {
    padding-top: 5px;
  }
}
@media (max-width: 399px) {
  #select-client {
    display: none;
  }
}
/* Aside */
#left-panel {
  background: none #ffffff;
  padding-top: 95px;
  margin-left: 5%;
  width: 20%;
}
#profile-picture {
  width: 100%;
  height: auto;
  margin-bottom: 25px;
}
nav > ul > li {
  transition: background-color 200ms linear, color 200ms linear;
}
nav > ul > li .txt-color-white {
  transition: color 200ms linear;
}
nav > ul > li > a {
  color: #4c4f53 !important;
  border: 1px solid #ddd;
}
nav > ul > li > a:hover,
nav > ul > li > a:active,
nav > ul > li > a:focus,
nav > ul > li > a:hover > b,
nav > ul > li > a:active > b,
nav > ul > li > a:focus > b {
  color: #4c4f53 !important;
  background-color: #ddd !important;
}
nav > ul > li:not(:last-child) > a {
  border-bottom: none;
}
nav > ul > li.active > a {
  color: #fff !important;
  background-color: #4c4f53 !important;
  border: 1px solid #4c4f53;
}
nav > ul > li.active > a:before {
  color: transparent;
}
nav > ul > li.open {
  border: none !important;
}
nav > ul > li.open > a,
nav > ul > li.open > a > b {
  color: #4c4f53 !important;
  background-color: #ddd !important;
}
nav > ul > li:hover > ul:before,
nav > ul > li:hover > ul > li:before {
  border-color: #7a7a7a !important;
}
nav > ul > li > ul {
  background-color: #ddd !important;
}
nav > ul > li > ul li > a {
  color: #4c4f53;
}
nav > ul > li > ul li > a:hover,
nav > ul > li > ul li > a:focus,
nav > ul > li > ul li > a:active {
  color: #4c4f53;
  background-color: #aaa !important;
}
nav > ul > li > ul li > a:hover > b,
nav > ul > li > ul li > a:focus > b,
nav > ul > li > ul li > a:active > b {
  color: #4c4f53;
}
nav > ul > li > ul li > a > b {
  color: #4c4f53;
}
nav > ul > li > ul li.active > a {
  color: #fff !important;
  background-color: #4c4f53 !important;
}
nav > ul > li > ul li.active > a:before {
  color: transparent;
}
nav > ul > li > ul li.open > a,
nav > ul > li > ul li.open > a > b {
  color: #4c4f53 !important;
}
.btn.btn-link.toggle-menu {
  position: absolute !important;
  transition: all 0.2s ease;
  padding: 12px 4px 12px 6px;
  right: -14px;
}
.btn.btn-link.toggle-menu:hover {
  right: -18px;
  padding-left: 10px;
}
.btn.btn-link.toggle-menu,
.btn.btn-link.toggle-menu:hover,
.btn.btn-link.toggle-menu:active,
.btn.btn-link.toggle-menu:focus {
  display: none;
  top: 150px !important;
  left: auto !important;
  color: #fff !important;
  background-color: #54b7ff !important;
  border-radius: 0 4px 4px 0;
}
.hidden-menu .btn.btn-link.toggle-menu:hover {
  right: -10px;
  padding-left: 2px;
}
@media (max-width: 979px) {
  button.toggle-menu {
    display: block !important;
  }
  button.toggle-menu i.fa-angle-left {
    display: none;
  }
  .hidden-menu #left-panel button.toggle-menu i.fa-angle-left {
    display: inline-block !important;
  }
  .hidden-menu #left-panel button.toggle-menu i.fa-angle-right {
    display: none !important;
  }
}
#aside-info {
  padding: 15px;
}
#aside-info h5,
#aside-info p {
  margin-bottom: 5px;
}
/* Main content */
#main {
  /*padding-top: 50px;*/
  margin-left: 25%;
  margin-right: 5%;
  padding-left: 25px;
  width: 70% !important;
}
html > body:not(.menu-on-top) #main {
  min-height: calc(100vh - 128px);
}
#content {
  padding: 25px 0;
}
#spinner {
  display: block !important;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  html,
  body,
  app-root,
  app-main-layout,
  #main,
  #content {
    display: block !important;
  }
  #main {
    min-height: 100vh !important;
  }
}
@media (max-width: 979px) {
  #main {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    width: 100% !important;
    transition: transform 250ms cubic-bezier(0.1, 0.57, 0.1, 1);
  }
  .hidden-menu #main {
    left: 0;
    transform: translateX(245px);
  }
  .hidden-menu #left-panel {
    margin-left: 5%;
  }
  #left-panel {
    width: 220px;
    margin-left: 0;
  }
}
/* Modals */
.modal {
  transition-duration: 300ms !important;
}
.modal.in {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-header {
  padding: 20px;
}
.modal-header .close {
  font-weight: 200;
  transition: all 200ms linear;
}
.modal-header .close:hover,
.modal-header .close:active {
  transform: rotate(90deg) scale(1.5, 1.5);
  font-weight: 100;
}
.modal-content {
  border-radius: 0;
}
.modal-footer {
  text-align: left;
}
.modal-backdrop {
  display: none !important;
}
@media (min-width: 768px) {
  .modal-dialog.modal-md.over-modal {
    margin: 50px auto;
  }
}
/* Forms */
.smart-form {
  color: #404040;
}
.smart-form ::-webkit-input-placeholder {
  color: #aaa;
}
.smart-form :-ms-input-placeholder {
  color: #aaa;
}
.smart-form ::-moz-placeholder {
  color: #aaa;
  opacity: 1;
}
.smart-form :-moz-placeholder {
  color: #aaa;
  opacity: 1;
}
.smart-form .input input,
.smart-form .select select,
.smart-form .textarea textarea {
  border-radius: 4px;
  padding: 10px;
  height: 42px;
}
.smart-form .select {
  background-color: #fff;
  border-radius: 4px;
}
.smart-form .select.state-success {
  background-color: #f0fff0;
}
.smart-form .select.state-coverage-success {
  background-color: #54b7ff;
}
.smart-form .select.state-warning {
  background-color: #fffddd;
}
.smart-form .select.state-error {
  background-color: #fff0f0;
}
.smart-form .select i:before {
  top: 6px;
}
.smart-form .select i:after {
  top: 13px;
}
.smart-form .select.small i:before {
  top: 0;
}
.smart-form .select.small i:after {
  top: 7px;
}
.smart-form .select select {
  background: none;
  position: relative;
  z-index: 100;
}
.smart-form .select i {
  z-index: 99;
}
.smart-form .modal .select select {
  z-index: 1100;
}
.smart-form .modal .select i {
  z-index: 1099;
}
.smart-form .input.small input,
.smart-form .select.small select,
.smart-form .textarea.small textarea {
  border-radius: 4px;
  padding: 5px;
  height: 32px;
}
.smart-form .input.small.split {
  height: 32px;
}
.smart-form .input.split input:not(:last-of-type) {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 50%;
  float: left;
}
.smart-form .input.split input:not(:first-of-type) {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 50%;
  float: left;
}
.smart-form .checkbox i {
  border-radius: 4px;
}
.smart-form .icon-append {
  padding: 5px 5px 0 8px;
  height: 28px;
  font-size: 17px;
}
.smart-form .icon-append.click-me:hover {
  color: #0086e6;
}
.smart-form .icon-prepend {
  padding: 5px 8px 0 5px;
  height: 28px;
  font-size: 17px;
}
.smart-form .icon-prepend.click-me:hover {
  color: #0086e6;
}
.smart-form .icon-prepend + input {
  padding-left: 47px !important;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .smart-form .icon-append {
    background-color: #fff;
  }
}
.smart-form .tooltip {
  background-color: #ddd;
  color: #4c4f53;
  border-radius: 4px;
}
.smart-form .tooltip.tooltip-top-right::after {
  border-top-color: #ddd;
}
.smart-form .input.small .ui-select-container {
  padding: 5px;
  box-sizing: border-box;
}
.smart-form .input.small .ui-select-container .ui-select-search {
  height: 20px;
  margin: 0;
}
.smart-form .input.small .ui-select-container .ui-select-match .ui-select-toggle .ui-select-match-text {
  display: inline-block;
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.smart-form .input.small .ui-select-container .ui-select-choices li .ui-select-choices-row a div {
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  overflow-x: -moz-scrollbars-none;
}
.smart-form .input.small .ui-select-container .ui-select-choices li .ui-select-choices-row a div::-webkit-scrollbar {
  display: none;
}
.smart-form .input.small .ui-select-container .ui-select-choices li .ui-select-choices-row a div:after {
  content: "";
  padding-right: 10px;
}
.smart-form .input.small .ui-select-container,
.smart-form .input.small .ui-select-choices {
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
}
.smart-form .input.small .ui-select-choices-row > a {
  padding: 5px 0 5px 10px;
}
.smart-form .input.small .ui-select-choices-row.active > a {
  background-color: #54b7ff !important;
}
.smart-form .input.small ng-select.has-empty-option ul.ui-select-choices li:first-child .ui-select-choices-row > a {
  color: #aaa;
  background-color: #fff !important;
}
.smart-form .input.small.state-error .ui-select-container {
  background-color: #fff0f0;
  border-color: #a90329;
}
.smart-form .input.small.state-error .icon-append,
.smart-form .input.small.state-error .icon-prepend {
  color: #a90329;
}
.smart-form .input.small.state-warning input,
.smart-form .input.small.state-warning .ui-select-container .ui-select-search,
.smart-form .input.small.state-warning .ui-select-container .ui-select-match .ui-select-toggle {
  background-color: #fffddd;
  border-color: #efe1b3;
}
.smart-form .input.small .icon-append {
  padding: 0 0 0 3px;
  height: 22px;
  font-size: 14px;
}
.smart-form .input.small .icon-prepend {
  padding: 0 3px 0 0;
  height: 22px;
  font-size: 14px;
}
.smart-form .input.small .icon-prepend + input {
  padding-left: 35px;
}
.smart-form .checkbox input:checked + i,
.smart-form .radio input:checked + i,
.smart-form .toggle input:checked + i {
  border-color: #0086e6;
}
.smart-form .checkbox.state-warning input:not(:checked) + i,
.smart-form .radio.state-warning input:not(:checked) + i {
  background-color: #fffddd;
  border-color: #efe1b3;
}
.smart-form .radio input + i:after {
  background-color: #0086e6;
}
.smart-form header,
.smart-form fieldset,
.smart-form footer {
  padding: 20px;
}
@media (max-width: 625px) {
  .smart-form header,
  .smart-form fieldset,
  .smart-form footer {
    padding: 0px;
    padding-top: 10px;
  }
}
.smart-form .input-file .button {
  border-radius: 4px;
  height: 24px;
  background-color: #54b7ff;
}
.smart-form .input-file .button:hover,
.smart-form .input-file .button:active {
  background-color: #0086e6;
}
.smart-form .input.inline input,
.smart-form .textarea.inline textarea,
.smart-form .select.inline select {
  border-radius: 0;
  border: 1px solid transparent;
  background: none;
  padding: 0;
  margin: 0;
  height: auto;
  transition: none;
}
.smart-form .input.inline.state-error input,
.smart-form .textarea.inline.state-error textarea,
.smart-form .select.inline.state-error select,
.smart-form .input.inline.state-error .ui-select-container {
  background-color: #fff0f0;
  border-bottom: 1px dotted #a90329;
}
.smart-form .textarea.inline textarea {
  padding-bottom: 5px;
}
.smart-form .textarea.inline textarea:hover {
  border-bottom: 2px solid #d1e9f9;
}
.smart-form .textarea.inline textarea:focus {
  border-bottom: 2px solid #54b7ff;
}
.smart-form .select.inline select {
  padding-left: 2px;
  padding-right: 12px;
}
.smart-form .select.inline .fa {
  position: absolute;
  right: 2px;
  top: 2px;
}
.smart-form .input.inline input:hover,
.smart-form .input.inline select:hover {
  border-bottom: 1px solid #d1e9f9;
}
.smart-form .input.inline input:focus,
.smart-form .input.inline select:focus {
  border-bottom: 1px solid #54b7ff;
}
.smart-form .input.inline input .ui-select-container,
.smart-form .input.inline select .ui-select-container {
  border: none;
  padding: 2px 0;
  border-radius: 0 !important;
  border-bottom: 1px solid #54b7ff;
}
.smart-form .input.inline input .ui-select-container input.ui-select-search,
.smart-form .input.inline select .ui-select-container input.ui-select-search {
  width: auto;
  display: inline-block;
  margin-bottom: 0;
}
.smart-form .input.inline input .ui-select-container .ui-select-match-item,
.smart-form .input.inline select .ui-select-container .ui-select-match-item {
  margin-bottom: 0;
}
.smart-form .input.inline.state-error .ui-select-container {
  padding-left: 5px;
}
.smart-form .input.inline i {
  position: absolute;
  top: 5px;
  right: 5px;
}
.smart-form .input .ui-select-container:not(.ui-select-multiple) .btn.btn-default,
.smart-form .input .ui-select-container:not(.ui-select-multiple) .ui-select-search {
  border-radius: 4px !important;
  padding: 10px;
  height: 42px;
  background-color: #fff;
  border: 1px solid #bdbdbd;
}
.smart-form .input .ui-select-container:not(.ui-select-multiple) .btn.btn-default:focus,
.smart-form .input .ui-select-container:not(.ui-select-multiple) .ui-select-search:focus {
  border-color: #5d98cc;
}
.smart-form .input.small .ui-select-container:not(.ui-select-multiple) {
  padding: 0;
}
.smart-form .input.small .ui-select-container:not(.ui-select-multiple) .btn.btn-default,
.smart-form .input.small .ui-select-container:not(.ui-select-multiple) .ui-select-search {
  padding: 5px 23px 5px 5px;
  height: 32px;
}
.smart-form .input .dropdown-menu {
  animation: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.smart-form .select.state-warning select {
  background-color: #fffddd;
  border-color: #efe1b3;
}
.smart-form .select.state-warning i {
  background-color: #fffddd;
  box-shadow: 0 0 0 9px #fffddd;
}
.smart-form button.btn-inline {
  cursor: pointer;
  background: none;
  border-radius: 0;
  padding: 5px;
  margin: -5px;
  box-shadow: none;
  text-align: left;
}
.smart-form button.btn-inline .click-me {
  color: #404040;
  padding-right: 2px;
  border-bottom: 1px solid transparent;
}
.smart-form button.btn-inline .click-me.state-error {
  border-bottom: 1px dotted #a90329;
  background-color: #fff0f0;
}
.smart-form button.btn-inline:hover .click-me {
  border-bottom: 1px solid #d1e9f9;
}
.smart-form button.btn-inline:hover .click-me.state-error {
  border-bottom: 1px dotted #a90329;
  background-color: #fff0f0;
}
.smart-form button.btn-inline:hover .state-disabled {
  color: #aaa;
  cursor: default;
  border-bottom-color: #aaa;
}
.smart-form button.btn-inline:focus .click-me {
  border-bottom: 1px solid #54b7ff;
}
.smart-form button.btn-inline:active {
  top: 0;
  left: 0;
}
.smart-form button.btn-inline .placeholder,
.smart-form button.btn-inline .state-disabled {
  color: #aaa;
}
.smart-form span.inline {
  color: #404040;
  font-size: 13px;
}
.smart-form .ui-select-container .btn.btn-default:active {
  top: 0;
  left: 0;
}
.smart-form .ui-select-container .ui-select-search:hover {
  border-color: #0086e6;
}
.smart-form .ui-select-container.dropdown.open:hover .ui-select-toggle {
  border-color: #5d98cc;
}
.smart-form .ui-select-container.dropdown.open:focus .ui-select-toggle {
  border-color: #3276b1;
}
.smart-form .ui-select-toggle a.btn-link {
  display: none !important;
}
.smart-form .state-success .icon-append,
.smart-form .state-success .icon-prepend {
  color: #7dc27d;
  background-color: transparent;
}
.smart-form .state-coverage-success .icon-append,
.smart-form .state-coverage-success .icon-prepend {
  color: #54b7ff;
  background-color: transparent;
}
.smart-form .toggle {
  font-size: 13px;
}
.smart-form .toggle i {
  top: 8px;
  height: 11px;
  width: 28px;
  background-color: #ddd;
  border: none !important;
  transition: background-color 100ms linear;
}
.smart-form .toggle i:before {
  top: -3px;
  right: 13px;
  height: 16px;
  width: 16px;
  background-color: #999;
  transition: all 100ms linear;
}
.smart-form .toggle input:checked + i {
  background-color: #54b7ff;
}
.smart-form .toggle input:checked + i:before {
  right: -3px;
  background-color: #0086e6;
}
.smart-form .toggle input:hover + i:before,
.smart-form .toggle input:focus + i:before {
  background-color: #0086e6;
}
.smart-form ul.multi-select-list {
  list-style-type: none;
  margin-bottom: 5px;
}
.smart-form ul.multi-select-list li {
  padding: 2px 0;
}
.smart-form ul.multi-select-list li:not(.heading) {
  padding-left: 5px;
  padding-right: 5px;
}
.smart-form ul.multi-select-list li:not(.heading):hover {
  background-color: #ecf3f8;
}
.form-actions {
  background-color: #f5f5f5;
  padding: 19px;
}
/* Pagination */
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #54b7ff;
  border-color: #54b7ff;
  transition: background-color 200ms ease-out;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
@media all and (max-width: 600px) {
  .pagination .pagination-first,
  .pagination .pagination-prev,
  .pagination .pagination-next,
  .pagination .pagination-last {
    display: none;
  }
  .pagination li:nth-of-type(3) a {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .pagination li:nth-of-type(7) a {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
/* Datepicker */
.ui-datepicker {
  border-radius: 4px;
}
.ui-datepicker select.ui-datepicker-year {
  background-color: transparent;
  border-color: transparent;
  width: 75px;
  cursor: pointer;
}
.ui-datepicker .ui-datepicker-prev {
  top: 0;
  left: 0;
  height: 100%;
  width: 2.5em;
  margin-top: 0;
  padding-top: 5px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ui-datepicker .ui-datepicker-next {
  top: 0;
  right: 0;
  height: 100%;
  width: 2.5em;
  margin-top: 0;
  padding-top: 5px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ui-datepicker td .ui-state-highlight {
  background-color: #d1e9f9;
  color: gray;
  text-shadow: none;
}
.ui-datepicker td .ui-state-highlight.ui-state-hover {
  color: #fff;
}
.ui-datepicker td .ui-state-highlight:hover {
  color: #fff;
  background: #0086e6;
  background-color: #0086e6;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.1);
}
.ui-datepicker td .ui-state-hover {
  background: #0086e6;
  background-color: #0086e6;
}
.ui-datepicker-header,
.ui-datepicker td .ui-state-active,
.ui-datepicker td .ui-state-hover,
.ui-datepicker td .ui-state-highlight {
  border-radius: 4px;
}
/* Tabs */
div.nav-tabs-container {
  border-bottom: 2px solid #54b7ff;
  width: 100%;
}
div.nav-tabs-container ::-webkit-scrollbar {
  height: 2px;
}
div.nav-tabs-container ::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
}
div.nav-tabs-container ::-webkit-scrollbar-thumb {
  background: #54b7ff;
}
.nav-tabs {
  border-bottom: none;
  white-space: nowrap;
  overflow: visible;
}
.nav-tabs > li {
  display: inline-block;
  float: none;
}
.nav-tabs > li > a {
  padding: 5px 25px;
  background-color: #ffffff;
  border: none !important;
  transition: background-color 200ms ease-out;
  margin-bottom: 1px;
}
.nav-tabs > li > a:hover {
  background-color: #ddd;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.dropdown > a {
  box-shadow: none;
  background-color: #54b7ff;
  border: 1px solid #54b7ff;
  color: #ffffff;
  margin-top: 0 !important;
  margin-bottom: 0;
  font-weight: bold;
}
.tab-pane {
  padding-top: 20px;
  background-color: #ffffff;
}
.tab-pane-divider {
  margin: 25px -25px;
  border-top: 20px solid #f5f5f5;
}
/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  background: #54b7ff;
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #d1e9f9;
}
/* PDF Viewer */
.modal-dialog.modal-lg.has-pdf,
.modal-dialog.modal-lg.has-pdf .modal-content {
  width: calc(8.5in + 8px);
}
.modal-dialog.modal-lg.has-pdf .modal-body {
  width: 100%;
  overflow: auto;
}
@media (max-width: 830px) {
  .modal-dialog.modal-lg.has-pdf,
  .modal-dialog.modal-lg.has-pdf .modal-content {
    width: calc(100% - 10px);
  }
  .modal-dialog.modal-lg.has-pdf .modal-body {
    width: 100%;
    height: 50vh;
    overflow: auto;
  }
  .ng2-pdf-viewer-container .page,
  .ng2-pdf-viewer-container .page .canvasWrapper,
  .ng2-pdf-viewer-container .page canvas {
    max-width: calc(100vw - 20px);
    max-height: calc(100vw * 1.29 - 20px);
  }
}
@media (max-width: 830px) and (max-height: 700px) {
  .modal-dialog.modal-lg.has-pdf .modal-body {
    height: 75vh;
  }
}
@media (max-width: 830px) and (max-height: 600px) {
  .modal-dialog.modal-lg.has-pdf .modal-body {
    height: 70vh;
  }
}
.ng2-pdf-viewer-container {
  overflow: hidden !important;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}
pdf-viewer.inline .ng2-pdf-viewer-container {
  -webkit-box-shadow: none;
  box-shadow: none;
}
pdf-viewer.inline .ng2-pdf-viewer-container .pdfViewer.removePageBorders .page {
  margin-bottom: 0;
}
/* Footer */
.page-footer {
  z-index: 905;
  padding: 15px 5%;
  border-top: 5px solid #dddddd;
  background: none #ffffff;
  color: #4c4f53;
}
/* Auth */
#auth-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#auth-splash {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  height: 100%;
  background-position: center;
  background-size: cover;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
#auth-splash.ng-lazyloaded {
  opacity: 1;
}
#auth-form {
  position: absolute;
  overflow: auto;
  top: 0;
  right: 0;
  height: 100%;
  width: 60%;
}
#auth-form form {
  position: relative;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  width: 375px;
}
#auth-form form header,
#auth-form form footer {
  background-color: transparent;
  text-align: center;
}
#auth-form form header h4 {
  font-size: 24px;
  margin-bottom: 10px;
}
#auth-form form header img {
  margin-bottom: 50px;
  max-height: 100px;
  max-width: 200px;
  height: auto;
  width: auto;
}
#auth-form form footer .btn-lg {
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
  font-size: 17px;
}
@media (max-width: 737px) {
  #auth-splash {
    display: none;
  }
  #auth-form {
    width: 100%;
  }
  #auth-form form .well {
    padding: 20px;
  }
}
@media (max-width: 375px) {
  #auth-form form {
    width: 100%;
    margin: 0;
  }
}
/* External */
#extr-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#extr-aside {
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  height: 100%;
}
#extr-aside + #extr-form {
  width: 75%;
}
#extr-info {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 20px;
}
#extr-form {
  position: absolute;
  overflow: auto;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
#extr-form form {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 700px;
  max-width: 100%;
  padding: 20px;
  margin: 0 auto;
}
#extr-form form header,
#extr-form form footer {
  background-color: transparent;
  text-align: center;
}
#extr-form form header h4 {
  font-size: 24px;
  margin-bottom: 10px;
}
#extr-form form header img {
  margin-bottom: 50px;
  max-height: 100px;
  max-width: 200px;
  height: auto;
  width: auto;
}
#extr-form form footer .btn-lg {
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
  font-size: 17px;
}
#extr-form form .well {
  padding: 50px;
  font-size: larger;
  border-radius: 10px;
}
#extr-form form .well h3 {
  margin-bottom: 20px;
  color: #0086e6;
}
#extr-form form .well p:not(:last-of-type) {
  margin-bottom: 10px;
}
#extr-form form footer {
  padding-bottom: 50px;
}
#extr-form div.nav-tabs-container {
  height: 32px;
}
ul.tiled-list {
  list-style-type: none;
}
ul.tiled-list > li {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px;
}
@media (max-width: 737px) {
  #extr-splash {
    display: none;
  }
  #extr-form {
    width: 100%;
  }
  #extr-form form .well {
    padding: 20px;
  }
}
@media (max-width: 600px) {
  #extr-form form {
    width: 100%;
    margin: 0;
  }
}
/* Wizards */
.bootstrapWizard li .step,
.bootstrapWizard li.complete .step {
  padding: 4px 10px;
  z-index: 3;
}
.bootstrapWizard li:not(.jump) .step {
  transition: all 100ms linear 200ms;
}
.bootstrapWizard li.active .step,
.bootstrapWizard li.complete .step {
  background-color: #54b7ff;
  border-color: #54b7ff;
}
.bootstrapWizard li .title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 17px;
}
.bootstrapWizard li.active .title {
  font-weight: bold;
}
.bootstrapWizard li.disabled a:hover {
  cursor: default;
}
.bootstrapWizard li:not(:first-child):after,
.bootstrapWizard li:last-child:after {
  border-top: 3px solid #54b7ff;
  content: "";
  display: block;
  font-size: 0;
  overflow: hidden;
  position: relative;
  top: -29.5px;
  left: -50%;
  z-index: 2;
  width: 0;
}
.bootstrapWizard li:not(:first-child).active:after,
.bootstrapWizard li:last-child.active:after,
.bootstrapWizard li:not(:first-child).complete:after,
.bootstrapWizard li:last-child.complete:after {
  border-top: 3px solid #54b7ff;
  width: 100%;
  max-width: 100%;
}
.bootstrapWizard li:not(:first-child):not(.jump):after,
.bootstrapWizard li:last-child:not(.jump):after {
  transition: width 200ms linear;
}
@media only screen and (max-width: 479px) {
  .bootstrapWizard li:before,
  .bootstrapWizard li:after {
    border-top: none !important;
  }
}
/*Smart Notification*/
.MessageBoxContainer label.checkbox {
  padding-left: 23px;
  padding-top: 3px;
  transition: color 200ms linear;
  cursor: pointer;
}
.MessageBoxContainer label.checkbox:hover i {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.MessageBoxContainer label.checkbox input {
  position: absolute;
  left: -9999px;
}
.MessageBoxContainer label.checkbox input + i {
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  width: 17px;
  height: 17px;
  outline: 0;
  border: 1px solid #bdbdbd;
  background: #fff;
  border-radius: 4px;
  color: #0086e6;
}
.MessageBoxContainer label.checkbox input:checked + i {
  border-color: #0086e6;
}
.MessageBoxContainer label.checkbox input:checked + i:after {
  content: "\f00c";
  color: #0086e6;
  position: absolute;
  top: -2px;
  left: 0;
  width: 15px;
  height: 15px;
  font: 400 16px/19px FontAwesome;
  text-align: center;
}
.MessageBoxContainer input[type="checkbox"] {
  width: auto;
}
/*Animations*/
.fade {
  transition: opacity 150ms ease;
}
.fade.slow {
  transition-duration: 1s;
}
.fade.left {
  transform: translateX(-20%);
  transition: opacity 150ms ease, transform 150ms ease;
}
.fade.right {
  transform: translateX(20%);
  transition: opacity 150ms ease, transform 150ms ease;
}
.fade.out {
  transition: opacity 150ms ease;
}
.fade.in {
  transform: translateX(0);
}
/*SmartMessageBox*/
.MessageBoxContainer {
  top: 50%;
  transform: translateY(-50%);
}
.MessageBoxContainer .MessageBoxMiddle {
  white-space: pre-wrap;
}
.MessageBoxContainer .MessageBoxMiddle a {
  color: #54b7ff;
  transition: color 200ms linear;
}
.MessageBoxContainer .MessageBoxMiddle a:hover,
.MessageBoxContainer .MessageBoxMiddle a:active,
.MessageBoxContainer .MessageBoxMiddle a:focus {
  color: #0086e6;
}
/*Admin Documents Search*/
.results__blurb {
  display: none !important;
}
.searchResults__result {
  margin-bottom: 0;
  position: relative;
}
.searchResults__result .copy-link,
.searchResults__result .copy-link:active {
  position: absolute !important;
  top: 30px !important;
  left: auto !important;
  right: 15px !important;
  cursor: pointer;
}
.searchResults__result:not(:hover) .copy-link {
  display: none;
}
/* Overlay */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
}
/* Cards */
.card {
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 100ms ease;
}
.card.selected {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.card.selected .card-title {
  background-color: #54b7ff;
  color: #fff;
}
.card.selected .card-title .light {
  color: #eee !important;
}
.card .card-title {
  font-size: large;
  font-weight: 500;
  padding: 8px 16px;
  margin: -16px -16px 16px -16px;
  border-radius: 10px 10px 0 0;
  background: #f0f0f0;
  transition: background-color 100ms ease, color 100ms ease;
}
.card .card-actions {
  padding: 8px 16px;
  margin: 16px -16px -16px -16px;
  border-radius: 0 0 10px 10px;
  border-top: 2px solid #f0f0f0;
  text-align: right;
}
.card .card-actions .btn {
  font-size: larger;
  font-weight: 500;
}
.card .card-actions .btn:not(.btn-primary):hover {
  background-color: #f0f0f0;
}
.card .card-actions .btn[disabled] {
  background: none;
  border: none;
  box-shadow: none;
  position: static;
  transition: background-color 100ms ease;
}
.card .card-actions .btn[disabled].btn-primary {
  color: #54b7ff !important;
}
@media (max-width: 600px) {
  .card .card-actions {
    text-align: center;
  }
}
/* No UI Slider */
.noUi-target {
  margin: 16px;
  height: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.noUi-horizontal .noUi-handle {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  box-shadow: none;
  transform: translate(-5px, 5px);
  background-color: #54b7ff;
  transition: background-color 100ms ease;
}
.noUi-horizontal .noUi-handle:hover {
  background-color: #0086e6;
}
.noUi-horizontal .noUi-handle::before,
.noUi-horizontal .noUi-handle::after {
  top: 4px;
  height: 8px;
}
.noUi-horizontal .noUi-handle::before {
  left: 6px;
}
.noUi-horizontal .noUi-handle::after {
  left: 9px;
}
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 25px;
  height: 25px;
  border: 1px solid #69c5ef;
  border-radius: 50%;
  background-color: #69c5ef;
  color: #69c5ef;
  box-shadow: 9999px 0 0 -13px #69c5ef;
  animation: dotPulse 1s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse.on-primary {
  border-color: white;
  background-color: white;
  box-shadow: 9999px 0 0 -13px white;
  color: white;
}
.dot-pulse::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #69c5ef;
  background-color: #69c5ef;
  color: #69c5ef;
  right: 50px;
}
.dot-pulse::before.on-primary {
  border-color: white;
  background-color: white;
  box-shadow: 9999px 0 0 -13px white;
}
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #69c5ef;
  background-color: #69c5ef;
  color: #69c5ef;
  left: 50px;
}
.dot-pulse::after.on-primary {
  border-color: white;
  background-color: white;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -13px #69c5ef;
  animation: dotPulseBefore 1s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::before.on-primary {
  box-shadow: 9984px 0 0 -13px white;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -13px #69c5ef;
  animation: dotPulseAfter 1s infinite linear;
  animation-delay: 0.5s;
}
.dot-pulse::after.on-primary {
  box-shadow: 9984px 0 0 -13px white;
}
#static > table:nth-of-type(2) > tbody {
  display: none;
}
@media (max-width: 599px) {
  #static > table:nth-of-type(1) > tbody:nth-of-type(1) > tr:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -13px #69c5ef;
  }
  30% {
    box-shadow: 9984px 0 0 3px #69c5ef;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -13px #69c5ef;
  }
}
@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -13px #69c5ef;
  }
  30% {
    box-shadow: 9999px 0 0 3px #69c5ef;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -13px #69c5ef;
  }
}
@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -13px #69c5ef;
  }
  30% {
    box-shadow: 10014px 0 0 3px #69c5ef;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -13px #69c5ef;
  }
}
