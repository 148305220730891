@import "./var.less";
/* Fonts */

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

body {
    padding-top: 0;
}

h1,
h2,
h3,
h4,
h5,
button,
a {
    &.theme-font {
        font-family: "Open Sans", "Open Sans Condensed", "Trade Gothic", Arial, Helvetica, Sans-Serif !important;
        letter-spacing: 0.1px;
        word-spacing: 1.5px;
        color: #666;
    }
}

/* Colors */

.bg-transparent {
    background-color: transparent !important;
}

.bg-white {
    background-color: #ffffff !important;
}

.red {
    color: #a90329
}

/* General layout */

.clear {
    clear: both;
}

.clear-left {
    clear: left;
}

.clear-right {
    clear: right;
}

.nowrap {
    white-space: nowrap !important;
}

.pre-line {
    white-space: pre-line !important;
}

.block {
    display: block !important;
}

.inline-block {
    display: inline-block !important;
}

.generate-padding(@n, @i: 0) when (@i =< @n) {
    .padding-@{i} {
        padding: unit(@i, px) !important;
    }

    .padding-top-@{i} {
        padding-top: unit(@i, px) !important;
    }

    .padding-right-@{i} {
        padding-right: unit(@i, px) !important;
    }

    .padding-bottom-@{i} {
        padding-bottom: unit(@i, px) !important;
    }

    .padding-left-@{i} {
        padding-left: unit(@i, px) !important;
    }

    .padding-neg-@{i} {
        padding: unit(@i, px) !important;
    }

    .padding-top-neg-@{i} {
        padding-top: unit(@i, px) !important;
    }

    .padding-right-neg-@{i} {
        padding-right: unit(@i, px) !important;
    }

    .padding-bottom-neg-@{i} {
        padding-bottom: unit(@i, px) !important;
    }

    .padding-left-neg-@{i} {
        padding-left: unit(@i, px) !important;
    }

    .generate-padding(@n, (@i + 5));
}

.generate-padding(100);

.generate-margin(@n, @i: 0) when (@i =< @n) {
    .margin-@{i} {
        margin: unit(@i, px) !important;
    }

    .margin-top-@{i} {
        margin-top: unit(@i, px) !important;
    }

    .margin-right-@{i} {
        margin-right: unit(@i, px) !important;
    }

    .margin-bottom-@{i} {
        margin-bottom: unit(@i, px) !important;
    }

    .margin-left-@{i} {
        margin-left: unit(@i, px) !important;
    }

    .margin-neg-@{i} {
        margin: unit(@i, px) !important;
    }

    .margin-top-neg-@{i} {
        margin-top: unit(@i, px) !important;
    }

    .margin-right-neg-@{i} {
        margin-right: unit(@i, px) !important;
    }

    .margin-bottom-neg-@{i} {
        margin-bottom: unit(@i, px) !important;
    }

    .margin-left-neg-@{i} {
        margin-left: unit(@i, px) !important;
    }

    .generate-margin(@n, (@i + 5));
}

.generate-margin(100);

.generate-width(@n, @i: 0) when (@i =< @n) {
    .w@{i} {
        width: @i * 1% !important;
    }

    .generate-width(@n, (@i + 5));
}

.generate-width(100);

.generate-height(@n, @i: 0) when (@i =< @n) {
    .h@{i} {
        height: @i * 1% !important;
    }

    .generate-height(@n, (@i + 5));
}

.generate-height(100);

.generate-width-px(@n, @i: 0) when (@i =< @n) {
    .w@{i}px {
        width: unit(@i, px);
    }

    .generate-width-px(@n, (@i + 5));
}

.generate-width-px(1000);

.row.compact {
    margin-left: -5px;
    margin-right: -5px;

    .col.compact {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.well {
    border-radius: 0;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: #f5f5f5;
}

.stacking {
    margin-top: 5px;
    margin-bottom: 5px;
}

.btn,
a:link,
button {
    -webkit-tap-highlight-color: @brand-lighter;
}

.btn {
    border-radius: 4px;
    border: none;
    transition: background-color 200ms ease-out;

    &[disabled],
    &.disabled {
        &:active {
            top: 0;
            left: 0;
        }
    }

    &.btn-default {
        background-color: #eeeeee;

        &[disabled],
        &.disabled {

            &,
            &:hover,
            &:active,
            &:focus {
                background-color: #efefef;
                cursor: default;
            }
        }
    }

    &.btn-primary {
        color: #fff !important;
        background-color: @brand-color;

        &:not([disabled]) {

            &:hover,
            &:active,
            &:focus {
                background-color: @brand-darker;
            }
        }

        &[disabled] {

            &,
            &:hover,
            &:active,
            &:focus {
                background-color: @brand-lighter;
                cursor: default;
            }
        }
    }

    &.btn-dropdown.dropdown-toggle {
        padding-top: 2px;
    }

    &.btn-lg.btn-labeled {
        .btn-label {
            left: -16px;
            padding: 12px 16px;
        }
    }

    &.btn-link {
        font-size: 1em;
        line-height: 1em;
        padding-left: 0;
        padding-right: 0;

        &,
        &:active {
            position: static;
            transform: translateY(-1px) !important;
        }

        &[disabled] {
            cursor: default;

            &:hover {
                color: #3276b1;
            }
        }
    }
}

.smart-form .btn.form-button {
    box-sizing: content-box;
    padding: 6px 12px;
}

.dropdown-menu {
    border-radius: 4px;

    .scroll {
        overflow-y: auto;
        max-height: 150px;
    }

    .scroll-toggle {
        color: @brand-color;

        .fa {
            transition: transform 200ms linear;
        }

        &:hover {
            color: @brand-darker;

            .fa {
                transform: scale(1.5);
            }
        }

        &.disabled {
            color: #999;

            &:hover .fa {
                transform: none;
            }
        }

        &:first-child {
            padding-top: 5px;
            margin-top: -5px;
        }
    }

    li {
        list-style-type: none;
        width: 100%;

        a {
            width: 100%;
            box-sizing: border-box;
        }

        ul {
            margin-left: -40px;

            li a {
                display: block;
                width: 100%;
                padding: 3px 20px;
                color: #404040;
            }
        }
    }

    li.active > a,
    li:hover > a,
    li.active > a:hover {
        background-color: @brand-color;

        &,
        .txt-color-primary,
        .txt-color {
            color: #ffffff !important;
        }
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .dropdown-toggle .fa-caret-right {
        transition: transform 100ms linear;

        &.open {
            transform: rotate(90deg);
        }
    }
}

.btn-group {

    .btn-default,
    .btn-default:hover,
    .btn-default:active {
        &:not(:first-child) {
            border-left: 1px solid lighten(#eeeeee, 5%);
        }

        &:not(:last-child) {
            border-right: 1px solid lighten(#eeeeee, 5%);
        }
    }
}

.txt-color-transparent {
    color: transparent;
}

.txt-color-primary {
    color: @brand-color !important;
}

.txt-color-primaryDark {
    color: @brand-darker !important;
}

.txt-color-primaryLight {
    color: @brand-lighter !important;
}

.bold {
    font-weight: bold !important;
}

.demi {
    font-weight: 500 !important;
}

.italic {
    font-style: italic !important;
}

.light {
    color: #999 !important;
}

.larger {
    font-size: larger;
}

.smaller {
    font-size: smaller;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.capitalize {
    text-transform: capitalize;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bg-white {
    background-color: #ffffff;
}

.animated.fast {
    animation-duration: 300ms;
}

.page-title {
    margin-top: -3px;
    border-bottom: 3px solid #f5f5f5;
    margin-bottom: 25px;
}

#page-title {
    border-bottom: 3px solid @brand-color;
    font-size: 24px;
    padding-bottom: 3px;
    margin-bottom: -3px;
}

.click-me,
.btn-inline {
    cursor: pointer;

    i {
        color: @brand-color;
        transition: color 200ms ease;
    }

    &:hover,
    &:focus,
    &:active {
        i {
            color: @brand-darker;
        }

        .light,
        &.light,
        .small,
        &.small {
            color: #666 !important;
        }
    }

    .light,
    &.light {
        color: #666 !important;
    }

    &.disabled {
        cursor: default;

        i {
            color: @brand-lighter !important;
        }

        &,
        .light,
        &.light,
        .small,
        &.small {
            color: #999 !important;
        }
    }
}

.loading img {
    display: inline-block;
    margin: 50px auto;
}

table.vertical-center td {
    vertical-align: middle;
}

.alert {
    .close {
        top: -10px;
    }

    .btn {
        border: none;
        transition: background-color 200ms ease-out;

        &.btn-info {
            background-color: #9cb4c5;

            &:hover {
                background-color: darken(#9cb4c5, 15%);
            }
        }
    }

    a {
        text-decoration: underline;
    }

    &.alert-danger .btn-link {
        font-size: 1em;
        line-height: 1em;
        transform: translateY(-1px);
        text-decoration: underline;
        color: #fff;

        &:hover {
            opacity: 0.85;
        }
    }

    &.alert-info .click-me {
        color: #555;
        font-size: smaller;

        i {
            font-size: normal;
            color: #305d8c;
        }

        &:hover {
            color: #333;

            i {
                color: #204060;
            }
        }
    }
}

.relative {
    position: relative;
}

.fill-height {
    min-height: ~"calc(100vh - 224px)";
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (min-width: 601px) {
    .visible-mobile {
        display: none !important;
    }
}

@media only screen and (max-width: 600px) {
    .hidden-mobile {
        display: none !important;
    }

    body > bs-dropdown-container {
        position: fixed !important;
        top: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        height: 100%;
        width: 100%;
        z-index: 1000;

        .dropdown.open {
            position: absolute;
            width: 100%;
            height: 150px;
            bottom: 0;

            & > .dropdown-menu {
                animation-name: fadeInUp;
                top: 0;
                width: 100%;
                height: 150px;
                border: none;
                border-top: 3px solid #ddd;
                border-radius: 0;

                &::-webkit-scrollbar,
                &::-webkit-scrollbar-track,
                &::-webkit-scrollbar-thumb {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }
    }

    .generate-padding-mobile(@n, @i: 0) when (@i =< @n) {
        .padding-mobile-@{i} {
            padding: unit(@i, px) !important;
        }

        .padding-mobile-top-@{i} {
            padding-top: unit(@i, px) !important;
        }

        .padding-mobile-right-@{i} {
            padding-right: unit(@i, px) !important;
        }

        .padding-mobile-bottom-@{i} {
            padding-bottom: unit(@i, px) !important;
        }

        .padding-mobile-left-@{i} {
            padding-left: unit(@i, px) !important;
        }

        .padding-mobile-neg-@{i} {
            padding: unit(@i, px) !important;
        }

        .padding-mobile-top-neg-@{i} {
            padding-top: unit(@i, px) !important;
        }

        .padding-mobile-right-neg-@{i} {
            padding-right: unit(@i, px) !important;
        }

        .padding-mobile-bottom-neg-@{i} {
            padding-bottom: unit(@i, px) !important;
        }

        .padding-mobile-left-neg-@{i} {
            padding-left: unit(@i, px) !important;
        }

        .generate-padding-mobile(@n, (@i + 5));
    }

    .generate-padding-mobile(100);

    .generate-margin-mobile(@n, @i: 0) when (@i =< @n) {
        .margin-mobile-@{i} {
            margin: unit(@i, px) !important;
        }

        .margin-mobile-top-@{i} {
            margin-top: unit(@i, px) !important;
        }

        .margin-mobile-right-@{i} {
            margin-right: unit(@i, px) !important;
        }

        .margin-mobile-bottom-@{i} {
            margin-bottom: unit(@i, px) !important;
        }

        .margin-mobile-left-@{i} {
            margin-left: unit(@i, px) !important;
        }

        .margin-mobile-neg-@{i} {
            margin: unit(@i, px) !important;
        }

        .margin-mobile-top-neg-@{i} {
            margin-top: unit(@i, px) !important;
        }

        .margin-mobile-right-neg-@{i} {
            margin-right: unit(@i, px) !important;
        }

        .margin-mobile-bottom-neg-@{i} {
            margin-bottom: unit(@i, px) !important;
        }

        .margin-mobile-left-neg-@{i} {
            margin-left: unit(@i, px) !important;
        }

        .generate-margin-mobile(@n, (@i + 5));
    }

    .generate-margin-mobile(100);
}

@media only screen and (min-width: 601px) and (max-width: 767px) {

    .generate-padding-xs(@n, @i: 0) when (@i =< @n) {
        .padding-xs-@{i} {
            padding: unit(@i, px) !important;
        }

        .padding-xs-top-@{i} {
            padding-top: unit(@i, px) !important;
        }

        .padding-xs-right-@{i} {
            padding-right: unit(@i, px) !important;
        }

        .padding-xs-bottom-@{i} {
            padding-bottom: unit(@i, px) !important;
        }

        .padding-xs-left-@{i} {
            padding-left: unit(@i, px) !important;
        }

        .padding-xs-neg-@{i} {
            padding: unit(@i, px) !important;
        }

        .padding-xs-top-neg-@{i} {
            padding-top: unit(@i, px) !important;
        }

        .padding-xs-right-neg-@{i} {
            padding-right: unit(@i, px) !important;
        }

        .padding-xs-bottom-neg-@{i} {
            padding-bottom: unit(@i, px) !important;
        }

        .padding-xs-left-neg-@{i} {
            padding-left: unit(@i, px) !important;
        }

        .generate-padding-xs(@n, (@i + 5));
    }

    .generate-padding-xs(100);

    .generate-margin-xs(@n, @i: 0) when (@i =< @n) {
        .margin-xs-@{i} {
            margin: unit(@i, px) !important;
        }

        .margin-xs-top-@{i} {
            margin-top: unit(@i, px) !important;
        }

        .margin-xs-right-@{i} {
            margin-right: unit(@i, px) !important;
        }

        .margin-xs-bottom-@{i} {
            margin-bottom: unit(@i, px) !important;
        }

        .margin-xs-left-@{i} {
            margin-left: unit(@i, px) !important;
        }

        .margin-xs-neg-@{i} {
            margin: unit(@i, px) !important;
        }

        .margin-xs-top-neg-@{i} {
            margin-top: unit(@i, px) !important;
        }

        .margin-xs-right-neg-@{i} {
            margin-right: unit(@i, px) !important;
        }

        .margin-xs-bottom-neg-@{i} {
            margin-bottom: unit(@i, px) !important;
        }

        .margin-xs-left-neg-@{i} {
            margin-left: unit(@i, px) !important;
        }

        .generate-margin-xs(@n, (@i + 5));
    }

    .generate-margin-xs(100);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .generate-padding-sm(@n, @i: 0) when (@i =< @n) {
        .padding-sm-@{i} {
            padding: unit(@i, px) !important;
        }

        .padding-sm-top-@{i} {
            padding-top: unit(@i, px) !important;
        }

        .padding-sm-right-@{i} {
            padding-right: unit(@i, px) !important;
        }

        .padding-sm-bottom-@{i} {
            padding-bottom: unit(@i, px) !important;
        }

        .padding-sm-left-@{i} {
            padding-left: unit(@i, px) !important;
        }

        .padding-sm-neg-@{i} {
            padding: unit(@i, px) !important;
        }

        .padding-sm-top-neg-@{i} {
            padding-top: unit(@i, px) !important;
        }

        .padding-sm-right-neg-@{i} {
            padding-right: unit(@i, px) !important;
        }

        .padding-sm-bottom-neg-@{i} {
            padding-bottom: unit(@i, px) !important;
        }

        .padding-sm-left-neg-@{i} {
            padding-left: unit(@i, px) !important;
        }

        .generate-padding-sm(@n, (@i + 5));
    }

    .generate-padding-sm(100);

    .generate-margin-sm(@n, @i: 0) when (@i =< @n) {
        .margin-sm-@{i} {
            margin: unit(@i, px) !important;
        }

        .margin-sm-top-@{i} {
            margin-top: unit(@i, px) !important;
        }

        .margin-sm-right-@{i} {
            margin-right: unit(@i, px) !important;
        }

        .margin-sm-bottom-@{i} {
            margin-bottom: unit(@i, px) !important;
        }

        .margin-sm-left-@{i} {
            margin-left: unit(@i, px) !important;
        }

        .margin-sm-neg-@{i} {
            margin: unit(@i, px) !important;
        }

        .margin-sm-top-neg-@{i} {
            margin-top: unit(@i, px) !important;
        }

        .margin-sm-right-neg-@{i} {
            margin-right: unit(@i, px) !important;
        }

        .margin-sm-bottom-neg-@{i} {
            margin-bottom: unit(@i, px) !important;
        }

        .margin-sm-left-neg-@{i} {
            margin-left: unit(@i, px) !important;
        }

        .generate-margin-sm(@n, (@i + 5));
    }

    .generate-margin-sm(100);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .generate-padding-md(@n, @i: 0) when (@i =< @n) {
        .padding-md-@{i} {
            padding: unit(@i, px) !important;
        }

        .padding-md-top-@{i} {
            padding-top: unit(@i, px) !important;
        }

        .padding-md-right-@{i} {
            padding-right: unit(@i, px) !important;
        }

        .padding-md-bottom-@{i} {
            padding-bottom: unit(@i, px) !important;
        }

        .padding-md-left-@{i} {
            padding-left: unit(@i, px) !important;
        }

        .padding-md-neg-@{i} {
            padding: unit(@i, px) !important;
        }

        .padding-md-top-neg-@{i} {
            padding-top: unit(@i, px) !important;
        }

        .padding-md-right-neg-@{i} {
            padding-right: unit(@i, px) !important;
        }

        .padding-md-bottom-neg-@{i} {
            padding-bottom: unit(@i, px) !important;
        }

        .padding-md-left-neg-@{i} {
            padding-left: unit(@i, px) !important;
        }

        .generate-padding-md(@n, (@i + 5));
    }

    .generate-padding-md(100);

    .generate-margin-md(@n, @i: 0) when (@i =< @n) {
        .margin-md-@{i} {
            margin: unit(@i, px) !important;
        }

        .margin-md-top-@{i} {
            margin-top: unit(@i, px) !important;
        }

        .margin-md-right-@{i} {
            margin-right: unit(@i, px) !important;
        }

        .margin-md-bottom-@{i} {
            margin-bottom: unit(@i, px) !important;
        }

        .margin-md-left-@{i} {
            margin-left: unit(@i, px) !important;
        }

        .margin-md-neg-@{i} {
            margin: unit(@i, px) !important;
        }

        .margin-md-top-neg-@{i} {
            margin-top: unit(@i, px) !important;
        }

        .margin-md-right-neg-@{i} {
            margin-right: unit(@i, px) !important;
        }

        .margin-md-bottom-neg-@{i} {
            margin-bottom: unit(@i, px) !important;
        }

        .margin-md-left-neg-@{i} {
            margin-left: unit(@i, px) !important;
        }

        .generate-margin-md(@n, (@i + 5));
    }

    .generate-margin-md(100);
}

@media only screen and (min-width: 1200px) {

    .generate-padding-lg(@n, @i: 0) when (@i =< @n) {
        .padding-lg-@{i} {
            padding: unit(@i, px) !important;
        }

        .padding-lg-top-@{i} {
            padding-top: unit(@i, px) !important;
        }

        .padding-lg-right-@{i} {
            padding-right: unit(@i, px) !important;
        }

        .padding-lg-bottom-@{i} {
            padding-bottom: unit(@i, px) !important;
        }

        .padding-lg-left-@{i} {
            padding-left: unit(@i, px) !important;
        }

        .padding-lg-neg-@{i} {
            padding: unit(@i, px) !important;
        }

        .padding-lg-top-neg-@{i} {
            padding-top: unit(@i, px) !important;
        }

        .padding-lg-right-neg-@{i} {
            padding-right: unit(@i, px) !important;
        }

        .padding-lg-bottom-neg-@{i} {
            padding-bottom: unit(@i, px) !important;
        }

        .padding-lg-left-neg-@{i} {
            padding-left: unit(@i, px) !important;
        }

        .generate-padding-lg(@n, (@i + 5));
    }

    .generate-padding-lg(100);

    .generate-margin-lg(@n, @i: 0) when (@i =< @n) {
        .margin-lg-@{i} {
            margin: unit(@i, px) !important;
        }

        .margin-lg-top-@{i} {
            margin-top: unit(@i, px) !important;
        }

        .margin-lg-right-@{i} {
            margin-right: unit(@i, px) !important;
        }

        .margin-lg-bottom-@{i} {
            margin-bottom: unit(@i, px) !important;
        }

        .margin-lg-left-@{i} {
            margin-left: unit(@i, px) !important;
        }

        .margin-lg-neg-@{i} {
            margin: unit(@i, px) !important;
        }

        .margin-lg-top-neg-@{i} {
            margin-top: unit(@i, px) !important;
        }

        .margin-lg-right-neg-@{i} {
            margin-right: unit(@i, px) !important;
        }

        .margin-lg-bottom-neg-@{i} {
            margin-bottom: unit(@i, px) !important;
        }

        .margin-lg-left-neg-@{i} {
            margin-left: unit(@i, px) !important;
        }

        .generate-margin-lg(@n, (@i + 5));
    }

    .generate-margin-lg(100);
}

/* Body */

body {
    background-image: none;
}

/* Header/top nav */

sa-header,
public-header {
    height: 68px;
}

#header {
    padding: 0;
    height: 68px;
    background: none #ffffff;
    border-bottom: 5px solid #dddddd;

    & > #logo-group {
        margin-left: 5%;
        width: 80%;
        display: table;

        h2 {
            display: table-cell;
            vertical-align: middle;
            text-align: left;
            height: 63px;
            width: 100%;

            &.smaller {
                font-size: 15px;
            }
        }
    }

    & > #action-group {
        float: right;
        margin-right: 5%;
    }
}

#public-header {
    padding: 0;
    height: 68px;
    background: none #ffffff;
    border-bottom: 5px solid #dddddd;
    position: static;
    width: 100%;
    z-index: 999;

    & > #logo-group {
        float: left;
        margin-left: 5%;
        width: 20%;
        display: table;

        h2 {
            display: table-cell;
            vertical-align: middle;
            text-align: left;
            height: 63px;
            width: 100%;

            &.smaller {
                font-size: 15px;
            }
        }

        #logo {
            text-align: left;
        }
    }

    & > #action-group {
        float: right;
        margin-top: 12px;
        margin-right: 5%;
    }
}

#logo {
    height: 100%;
    margin: 15px 0;
    text-align: left;

    img {
        max-height: 39px;
        max-width: 200px;
        height: auto;
        width: auto;
    }
}

#login-info {
    margin-top: 8px;

    a {
        display: inline-block;
        white-space: nowrap;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: -5px;
        margin-bottom: -5px;
    }
}

#hide-menu,
#logout,
#select-client,
#select-portal,
#select-producer {
    & > :first-child > a {
        width: 35px !important;
        margin-top: 15px !important;
        height: 35px !important;
        line-height: 25px !important;
        font-size: 17px !important;
    }

    i {
        font-size: 100%;
    }
}

#hide-menu,
.hidden-menu #hide-menu,
#logout,
#select-client,
#select-portal,
#select-producer {
    & > span:first-child > a {

        &,
        &:hover,
        &:active,
        &:focus,
        &:visited {

            &,
            & > i {
                color: #222 !important;
                box-shadow: none !important;
            }
        }

        margin-top: 15px !important;
    }
}

.btn-header {
    display: inline-block;
}

.btn-header > :first-child > a,
.btn-header > :first-child > a:hover,
.btn-header > :first-child > a:active,
.hidden-menu #hide-menu > :first-child > a {
    width: 35px;
    margin-top: 4px;
    background: none #dddddd;
    border: 3px solid #dddddd;
    border-radius: 0;
    height: 35px;
    line-height: 25px;
    box-shadow: none;
    cursor: pointer !important;
}

.hidden-menu #hide-menu > :first-child > a {
    color: @brand-color;
}

.header-search {
    display: inline-block;
    overflow: hidden;
    margin-top: 4px;
    border: 3px solid #dddddd;
    width: 200px;
    transition: width 200ms ease-out;

    &:hover,
    :active {
        i {
            color: #222;
        }
    }
}

.header-search.zipped {
    width: 35px;

    input {
        background-color: #dddddd;
    }
}

.header-search {
    & > input[type="text"] {
        border: none !important;
        margin-top: 0;
    }

    & > button[type="button"] {
        top: 0;
    }
}

@media (min-width: 980px) {
    #hide-menu {
        display: none;
    }
}

@media (max-width: 979px) and (min-width: 768px) {

    .btn-header > :first-child > a,
    .btn-header > :first-child > a:hover,
    .btn-header > :first-child > a:active,
    .hidden-menu #hide-menu > :first-child > a {
        width: 35px !important;
        margin-top: 4px !important;
    }

    #logo,
    #header > #logo-group h2 {
        text-align: left;
    }
}

@media (max-width: 880px) and (min-width: 768px) {

    #hide-menu > :first-child > a,
    .btn-header a {
        width: 35px !important;
        line-height: 24px !important;
        font-size: 17px !important;
        padding-top: 3px;
    }

    #hide-menu i {
        font-size: 100%;

        &:hover,
        &:active {
            color: #222;
        }
    }
}

@media (max-width: 880px) {
    #logout > :first-child > a {
        padding-top: 5px;
    }
}

@media (max-width: 399px) {
    #select-client {
        display: none;
    }
}

// @media (min-width: 768px){
//     #logout{
//         display: none;
//     }
// }
// @media (max-width: 319px){
//     #logo-group{
//         width: 100px !important;
//     }
// }
/* Aside */

#left-panel {
    background: none #ffffff;
    padding-top: 95px;
    margin-left: 5%;
    width: 20%;
}

#profile-picture {
    width: 100%;
    height: auto;
    margin-bottom: 25px;
}

nav > ul {
    & > li {
        transition: background-color 200ms linear, color 200ms linear;

        .txt-color-white {
            transition: color 200ms linear;
        }

        & > a {
            color: #4c4f53 !important;
            border: 1px solid #ddd;

            &:hover,
            &:active,
            &:focus {

                &,
                & > b {
                    color: #4c4f53 !important;
                    background-color: #ddd !important;
                }
            }
        }

        &:not(:last-child) > a {
            border-bottom: none;
        }

        &.active {
            & > a {
                color: #fff !important;
                background-color: #4c4f53 !important;
                border: 1px solid #4c4f53;

                &:before {
                    color: transparent;
                }
            }
        }

        &.open {
            border: none !important;

            & > a {

                &,
                & > b {
                    color: #4c4f53 !important;
                    background-color: #ddd !important;
                }
            }
        }

        &:hover {

            & > ul:before,
            & > ul > li:before {
                border-color: #7a7a7a !important;
            }
        }

        & > ul {
            background-color: #ddd !important;

            li {
                & > a {
                    color: #4c4f53;

                    &:hover,
                    &:focus,
                    &:active {
                        color: #4c4f53;
                        background-color: #aaa !important;

                        & > b {
                            color: #4c4f53;
                        }
                    }

                    & > b {
                        color: #4c4f53;
                    }
                }

                &.active {
                    & > a {
                        color: #fff !important;
                        background-color: #4c4f53 !important;

                        &:before {
                            color: transparent;
                        }
                    }
                }

                &.open {

                    & > a,
                    & > a > b {
                        color: #4c4f53 !important;
                    }
                }
            }
        }
    }
}

.btn.btn-link.toggle-menu {
    position: absolute !important;
    transition: all 0.2s ease;
    padding: 12px 4px 12px 6px;
    right: -14px;

    &:hover {
        right: -18px;
        padding-left: 10px;
    }

    &,
    &:hover,
    &:active,
    &:focus {
        display: none;
        top: 150px !important;
        left: auto !important;
        color: #fff !important;
        background-color: @brand-color !important;
        border-radius: 0 4px 4px 0;
    }
}

.hidden-menu .btn.btn-link.toggle-menu:hover {
    right: -10px;
    padding-left: 2px;
}

@media (max-width: 979px) {
    button.toggle-menu {
        display: block !important;

        i.fa-angle-left {
            display: none;
        }
    }

    .hidden-menu #left-panel button.toggle-menu {
        i.fa-angle-left {
            display: inline-block !important;
        }

        i.fa-angle-right {
            display: none !important;
        }
    }
}

#aside-info {
    padding: 15px;

    h5,
    p {
        margin-bottom: 5px;
    }
}

/* Main content */

#main {
    /*padding-top: 50px;*/
    margin-left: 25%;
    margin-right: 5%;
    padding-left: 25px;
    width: 70% !important;
}

html > body:not(.menu-on-top) #main {
    min-height: calc(100vh - 128px);
}

#content {
    padding: 25px 0;
}

#spinner {
    display: block !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

    // IE hack
    html,
    body,
    app-root,
    app-main-layout,
    #main,
    #content {
        display: block !important;
    }

    #main {
        min-height: 100vh !important;
    }
}

@media (max-width: 979px) {
    #main {
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 5% !important;
        padding-right: 5% !important;
        width: 100% !important;
        transition: transform 250ms cubic-bezier(0.1, 0.57, 0.1, 1);
    }

    .hidden-menu {
        #main {
            left: 0;
            transform: translateX(245px);
        }

        #left-panel {
            margin-left: 5%;
        }
    }

    #left-panel {
        width: 220px;
        margin-left: 0;
    }
}

/* Modals */

.modal {
    transition-duration: 300ms !important;

    &.in {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.modal-header {
    padding: 20px;

    .close {
        font-weight: 200;
        transition: all 200ms linear;

        &:hover,
        &:active {
            transform: rotate(90deg) scale(1.5, 1.5);
            font-weight: 100;
        }
    }
}

.modal-content {
    border-radius: 0;
}

.modal-footer {
    text-align: left;
}

.modal-backdrop {
    display: none !important;
}

@media (min-width: 768px) {
    .modal-dialog.modal-md.over-modal {
        margin: 50px auto;
    }
}

/* Forms */

.smart-form {
    ::-webkit-input-placeholder {
        color: #aaa;
    }

    :-ms-input-placeholder {
        color: #aaa;
    }

    ::-moz-placeholder {
        color: #aaa;
        opacity: 1;
    }

    :-moz-placeholder {
        color: #aaa;
        opacity: 1;
    }

    color: #404040;

    .input input,
    .select select,
    .textarea textarea {
        border-radius: 4px;
        padding: 10px;
        height: 42px;
    }

    .select {
        background-color: #fff;
        border-radius: 4px;

        &.state-success {
            background-color: rgb(240, 255, 240);
        }
        &.state-coverage-success {
            background-color: #54b7ff;
        }

        &.state-warning {
            background-color: #fffddd;
        }

        &.state-error {
            background-color: rgb(255, 240, 240);
        }
    }

    .select i:before {
        top: 6px;
    }

    .select i:after {
        top: 13px;
    }

    .select.small i:before {
        top: 0;
    }

    .select.small i:after {
        top: 7px;
    }

    .select select {
        background: none;
        position: relative;
        z-index: 100;
    }

    .select i {
        z-index: 99;
    }

    .modal {
        .select select {
            z-index: 1100;
        }

        .select i {
            z-index: 1099;
        }
    }

    .input.small input,
    .select.small select,
    .textarea.small textarea {
        border-radius: 4px;
        padding: 5px;
        height: 32px;
    }

    .input.small.split {
        height: 32px;
    }

    .input.split input:not(:last-of-type) {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 50%;
        float: left;
    }

    .input.split input:not(:first-of-type) {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 50%;
        float: left;
    }

    .checkbox i {
        border-radius: 4px;
    }

    .icon-append {
        padding: 5px 5px 0 8px;
        height: 28px;
        font-size: 17px;

        &.click-me:hover {
            color: @brand-darker;
        }
    }

    .icon-prepend {
        padding: 5px 8px 0 5px;
        height: 28px;
        font-size: 17px;

        &.click-me:hover {
            color: @brand-darker;
        }

        & + input {
            padding-left: 47px !important;
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        // IE hack
        .icon-append {
            background-color: #fff;
        }
    }

    .tooltip {
        background-color: #ddd;
        color: #4c4f53;
        border-radius: 4px;

        &.tooltip-top-right::after {
            border-top-color: #ddd;
        }
    }

    .input.small {
        .ui-select-container {
            padding: 5px;
            box-sizing: border-box;

            .ui-select-search {
                height: 20px;
                margin: 0;
            }

            .ui-select-match .ui-select-toggle .ui-select-match-text {
                display: inline-block;
                max-width: 100%;
                overflow-x: hidden;
                text-overflow: ellipsis;
            }

            .ui-select-choices li .ui-select-choices-row a div {
                width: 100%;
                overflow-x: auto;
                -ms-overflow-style: none; // IE 10+
                overflow-x: -moz-scrollbars-none; // Firefox
                &::-webkit-scrollbar {
                    display: none;
                }

                &:after {
                    content: "";
                    padding-right: 10px;
                }
            }
        }

        .ui-select-container,
        .ui-select-choices {
            border-radius: 4px !important;
            -webkit-border-radius: 4px !important;
        }

        .ui-select-choices-row > a {
            padding: 5px 0 5px 10px;
        }

        .ui-select-choices-row.active > a {
            background-color: @brand-color !important;
        }

        ng-select.has-empty-option ul.ui-select-choices li:first-child .ui-select-choices-row > a {
            color: #aaa;
            background-color: #fff !important;
        }

        &.state-error {
            .ui-select-container {
                background-color: #fff0f0;
                border-color: #a90329;
            }

            .icon-append,
            .icon-prepend {
                color: #a90329;
            }
        }

        &.state-warning input,
        &.state-warning .ui-select-container .ui-select-search,
        &.state-warning .ui-select-container .ui-select-match .ui-select-toggle {
            background-color: #fffddd;
            border-color: #efe1b3;
        }

        .icon-append {
            padding: 0 0 0 3px;
            height: 22px;
            font-size: 14px;
        }

        .icon-prepend {
            padding: 0 3px 0 0;
            height: 22px;
            font-size: 14px;
        }

        .icon-prepend + input {
            padding-left: 35px;
        }
    }

    .checkbox,
    .radio,
    .toggle {
        input:checked + i {
            border-color: @brand-darker;
        }
    }

    .checkbox,
    .radio {
        &.state-warning input:not(:checked) + i {
            background-color: #fffddd;
            border-color: #efe1b3;
        }
    }

    .radio input + i:after {
        background-color: @brand-darker;
    }

    header,
    fieldset,
    footer {
        padding: 20px;
    }

    @media (max-width: 625px) {
        header,
        fieldset,
        footer {
            padding: 0px;
            padding-top: 10px
        }
    }

    .input-file {
        .button {
            border-radius: 4px;
            height: 24px;
            background-color: @brand-color;

            &:hover,
            &:active {
                background-color: @brand-darker;
            }
        }
    }

    .input.inline input,
    .textarea.inline textarea,
    .select.inline select {
        border-radius: 0;
        border: 1px solid transparent; // preserve border spacing
        background: none;
        padding: 0;
        margin: 0;
        height: auto;
        transition: none;
    }

    .input.inline.state-error input,
    .textarea.inline.state-error textarea,
    .select.inline.state-error select,
    .input.inline.state-error .ui-select-container {
        background-color: #fff0f0;
        border-bottom: 1px dotted #a90329;
    }

    .textarea.inline textarea {
        padding-bottom: 5px;

        &:hover {
            border-bottom: 2px solid @brand-lighter;
        }

        &:focus {
            border-bottom: 2px solid @brand-color;
        }
    }

    .select.inline {
        select {
            padding-left: 2px;
            padding-right: 12px;
        }

        .fa {
            position: absolute;
            right: 2px;
            top: 2px;
        }
    }

    .input {
        &.inline {

            input,
            select {
                &:hover {
                    border-bottom: 1px solid @brand-lighter;
                }

                &:focus {
                    border-bottom: 1px solid @brand-color;
                }

                .ui-select-container {
                    border: none;
                    padding: 2px 0;
                    border-radius: 0 !important;
                    border-bottom: 1px solid @brand-color;

                    input.ui-select-search {
                        width: auto;
                        display: inline-block;
                        margin-bottom: 0;
                    }

                    .ui-select-match-item {
                        margin-bottom: 0;
                    }
                }
            }

            &.state-error .ui-select-container {
                padding-left: 5px;
            }

            i {
                position: absolute;
                top: 5px;
                right: 5px;
            }
        }

        .ui-select-container:not(.ui-select-multiple) {

            .btn.btn-default,
            .ui-select-search {
                border-radius: 4px !important;
                padding: 10px;
                height: 42px;
                background-color: #fff;
                border: 1px solid #bdbdbd;

                &:focus {
                    border-color: #5d98cc;
                }
            }
        }

        &.small .ui-select-container:not(.ui-select-multiple) {
            padding: 0;

            .btn.btn-default,
            .ui-select-search {
                padding: 5px 23px 5px 5px;
                height: 32px;
            }
        }

        .dropdown-menu {
            animation: none !important;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    .select.state-warning {
        select {
            background-color: #fffddd;
            border-color: #efe1b3;
        }

        i {
            background-color: #fffddd;
            box-shadow: 0 0 0 9px #fffddd;
        }
    }

    button.btn-inline {
        cursor: pointer;
        background: none;
        border-radius: 0;
        padding: 5px;
        margin: -5px;
        box-shadow: none;
        text-align: left;

        .click-me {
            color: #404040;
            padding-right: 2px;
            border-bottom: 1px solid transparent;

            &.state-error {
                border-bottom: 1px dotted #a90329;
                background-color: #fff0f0;
            }
        }

        &:hover {
            .click-me {
                border-bottom: 1px solid @brand-lighter;

                &.state-error {
                    border-bottom: 1px dotted #a90329;
                    background-color: #fff0f0;
                }
            }

            .state-disabled {
                color: #aaa;
                cursor: default;
                border-bottom-color: #aaa;
            }
        }

        &:focus .click-me {
            border-bottom: 1px solid @brand-color;
        }

        &:active {
            top: 0;
            left: 0;
        }

        .placeholder,
        .state-disabled {
            color: #aaa;
        }
    }

    span.inline {
        color: #404040;
        font-size: 13px; //transform: translateY(1px);
    }

    .ui-select-container {
        .btn.btn-default:active {
            top: 0;
            left: 0;
        }

        .ui-select-search:hover {
            border-color: @brand-darker;
        }

        &.dropdown.open {
            &:hover .ui-select-toggle {
                border-color: #5d98cc;
            }

            &:focus .ui-select-toggle {
                border-color: #3276b1;
            }
        }
    }

    .ui-select-toggle a.btn-link {
        display: none !important;
    }

    .state-success .icon-append,
    .state-success .icon-prepend {
        color: #7dc27d;
        background-color: transparent;
    }

    .state-coverage-success .icon-append,
    .state-coverage-success .icon-prepend {
        color:#54b7ff;
        background-color: transparent;
    }

    .toggle {
        font-size: 13px;

        i {
            top: 8px;
            height: 11px;
            width: 28px;
            background-color: #ddd;
            border: none !important;
            transition: background-color 100ms linear;

            &:before {
                top: -3px;
                right: 13px;
                height: 16px;
                width: 16px;
                background-color: #999;
                transition: all 100ms linear;
            }
        }

        input:checked + i {
            background-color: @brand-color;

            &:before {
                right: -3px;
                background-color: @brand-darker;
            }
        }

        input:hover + i:before,
        input:focus + i:before {
            background-color: @brand-darker;
        }
    }

    ul.multi-select-list {
        list-style-type: none;
        margin-bottom: 5px;

        li {
            padding: 2px 0;

            &:not(.heading) {
                padding-left: 5px;
                padding-right: 5px;

                &:hover {
                    background-color: #ecf3f8;
                }
            }
        }
    }
}

.form-actions {
    background-color: #f5f5f5;
    padding: 19px;
}

/* Pagination */

.pagination {
    & > .active {

        & > a,
        & > a:focus,
        & > a:hover,
        & > span,
        & > span:focus,
        & > span:hover {
            background-color: @brand-color;
            border-color: @brand-color;
            transition: background-color 200ms ease-out;
        }
    }

    & > li:first-child {

        & > a,
        & > span {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    & > li:last-child > a,
    & > li:last-child > span {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

@media all and (max-width: 600px) {
    .pagination {

        .pagination-first,
        .pagination-prev,
        .pagination-next,
        .pagination-last {
            display: none;
        }

        li:nth-of-type(3) a {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        li:nth-of-type(7) a {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}

/* Datepicker */

.ui-datepicker {
    border-radius: 4px;

    select.ui-datepicker-year {
        background-color: transparent;
        border-color: transparent;
        width: 75px;
        cursor: pointer;
    }

    .ui-datepicker-prev {
        top: 0;
        left: 0;
        height: 100%;
        width: 2.5em;
        margin-top: 0;
        padding-top: 5px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .ui-datepicker-next {
        top: 0;
        right: 0;
        height: 100%;
        width: 2.5em;
        margin-top: 0;
        padding-top: 5px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    td {
        .ui-state-highlight {
            background-color: @brand-lighter;
            color: gray;
            text-shadow: none;

            &.ui-state-hover {
                color: #fff;
            }

            &:hover {
                color: #fff;
                background: @brand-darker;
                background-color: @brand-darker;
                text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
                border-color: rgba(0, 0, 0, 0.1);
            }
        }

        .ui-state-hover {
            background: @brand-darker;
            background-color: @brand-darker;
        }
    }
}

.ui-datepicker-header,
.ui-datepicker td .ui-state-active,
.ui-datepicker td .ui-state-hover,
.ui-datepicker td .ui-state-highlight {
    border-radius: 4px;
}

/* Tabs */

div.nav-tabs-container {
    border-bottom: 2px solid @brand-color;
    width: 100%;

    ::-webkit-scrollbar {
        height: 2px;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
    }

    ::-webkit-scrollbar-thumb {
        background: @brand-color;
    }
}

.nav-tabs {
    border-bottom: none;
    white-space: nowrap;
    overflow: visible;

    & > li {
        display: inline-block;
        float: none;

        & > a {
            padding: 5px 25px;
            background-color: #ffffff;
            border: none !important;
            transition: background-color 200ms ease-out;
            margin-bottom: 1px;

            &:hover {
                background-color: #ddd;
            }
        }

        &.active > a,
        &.active > a:focus,
        &.active > a:hover,
        &.dropdown > a {
            box-shadow: none;
            background-color: @brand-color;
            border: 1px solid @brand-color;
            color: #ffffff;
            margin-top: 0 !important;
            margin-bottom: 0;
            font-weight: bold;
        }
    }
}

.tab-pane {
    padding-top: 20px;
    background-color: #ffffff;
}

.tab-pane-divider {
    margin: 25px -25px;
    border-top: 20px solid #f5f5f5;
}

/* Scrollbar */

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background: @brand-color;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
}

::-webkit-scrollbar-thumb:window-inactive {
    background: @brand-lighter;
}

/* PDF Viewer */

.modal-dialog.modal-lg.has-pdf {

    &,
    .modal-content {
        width: calc(8.5in + 8px);
    }

    .modal-body {
        width: 100%;
        overflow: auto;
    }
}

@media (max-width: 830px) {
    .modal-dialog.modal-lg.has-pdf {

        &,
        .modal-content {
            width: ~"calc(100% - 10px)";
            
        }

        .modal-body {
            width: 100%;
            height: 50vh;
            overflow: auto;
        }
    } 

    .ng2-pdf-viewer-container .page{
        &, .canvasWrapper, canvas {
            max-width: ~"calc(100vw - 20px)";
            max-height: ~"calc(100vw * 1.29 - 20px)";
        }
    }
}

@media (max-width: 830px) and (max-height: 700px) {
    .modal-dialog.modal-lg.has-pdf {

        .modal-body {
            height: 75vh;
        }
    } 
}

@media (max-width: 830px) and (max-height: 600px) {
    .modal-dialog.modal-lg.has-pdf {

        .modal-body {
            height: 70vh;
        }
    } 
}
.ng2-pdf-viewer-container {
    overflow: hidden !important;
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

pdf-viewer.inline .ng2-pdf-viewer-container {
    -webkit-box-shadow: none;
    box-shadow: none;

    .pdfViewer.removePageBorders .page {
        margin-bottom: 0;
    }
}

/* Footer */

.page-footer {
    z-index: 905;
    padding: 15px 5%;
    border-top: 5px solid #dddddd;
    background: none #ffffff;
    color: #4c4f53;
}

/* Auth */

#auth-main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#auth-splash {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: 100%;
    background-position: center;
    background-size: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;

    &.ng-lazyloaded {
        opacity: 1;
    }
}

#auth-form {
    position: absolute;
    overflow: auto;
    top: 0;
    right: 0;
    height: 100%;
    width: 60%;

    form {
        position: relative;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        width: 375px;

        header,
        footer {
            background-color: transparent;
            text-align: center;
        }

        header {
            h4 {
                font-size: 24px;
                margin-bottom: 10px;
            }

            img {
                margin-bottom: 50px;
                max-height: 100px;
                max-width: 200px;
                height: auto;
                width: auto;
            }
        }

        footer .btn-lg {
            padding-top: 10px;
            padding-bottom: 10px;
            height: auto;
            font-size: 17px;
        }
    }
}

@media (max-width: 737px) {
    #auth-splash {
        display: none;
    }

    #auth-form {
        width: 100%;

        form .well {
            padding: 20px;
        }
    }
}

@media (max-width: 375px) {
    #auth-form form {
        width: 100%;
        margin: 0;
    }
}

/* External */

#extr-main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#extr-aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 25%;
    height: 100%;

    & + #extr-form {
        width: 75%;
    }
}

#extr-info {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 20px;
}

#extr-form {
    position: absolute;
    overflow: auto;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;

    form {
        position: relative;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        width: 700px;
        max-width: 100%;
        padding: 20px;
        margin: 0 auto;

        header,
        footer {
            background-color: transparent;
            text-align: center;
        }

        header {
            h4 {
                font-size: 24px;
                margin-bottom: 10px;
            }

            img {
                margin-bottom: 50px;
                max-height: 100px;
                max-width: 200px;
                height: auto;
                width: auto;
            }
        }

        footer .btn-lg {
            padding-top: 10px;
            padding-bottom: 10px;
            height: auto;
            font-size: 17px;
        }

        .well {
            padding: 50px;
            font-size: larger;
            border-radius: 10px;

            h3 {
                margin-bottom: 20px;
                color: @brand-darker;
            }

            p:not(:last-of-type) {
                margin-bottom: 10px;
            }
        }

        footer {
            padding-bottom: 50px;
        }
    }

    div.nav-tabs-container {
        height: 32px;
    }
}

ul.tiled-list {
    list-style-type: none;

    & > li {
        background-color: #f5f5f5;
        border-radius: 4px;
        padding: 10px;
    }
}

@media (max-width: 737px) {
    #extr-splash {
        display: none;
    }

    #extr-form {
        width: 100%;

        form .well {
            padding: 20px;
        }
    }
}

@media (max-width: 600px) {
    #extr-form form {
        width: 100%;
        margin: 0;
    }
}

/* Wizards */

.bootstrapWizard {
    li {

        .step,
        &.complete .step {
            padding: 4px 10px;
            z-index: 3;
        }

        &:not(.jump) .step {
            transition: all 100ms linear 200ms;
        }

        &.active,
        &.complete {
            .step {
                background-color: @brand-color;
                border-color: @brand-color;
            }
        }

        .title {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 17px;
        }

        &.active {
            .title {
                font-weight: bold;
            }
        }

        &.disabled a:hover {
            cursor: default;
        }

        &:not(:first-child),
        &:last-child {
            &:after {
                border-top: 3px solid @brand-color;
                content: "";
                display: block;
                font-size: 0;
                overflow: hidden;
                position: relative;
                top: -29.5px;
                left: -50%;
                z-index: 2;
                width: 0;
            }

            &.active:after,
            &.complete:after {
                border-top: 3px solid @brand-color;
                width: 100%;
                max-width: 100%;
            }

            &:not(.jump):after {
                transition: width 200ms linear;
            }
        }
    }
}

@media only screen and (max-width: 479px) {
    .bootstrapWizard li {

        &:before,
        &:after {
            border-top: none !important;
        }
    }
}

/*Smart Notification*/

.MessageBoxContainer label.checkbox {
    padding-left: 23px;
    padding-top: 3px; //color: @brand-color;
    transition: color 200ms linear;
    cursor: pointer;

    &:hover {
        //color: @brand-darker;
        i {
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
        }
    }

    input {
        position: absolute;
        left: -9999px;

        & + i {
            position: absolute;
            top: 3px;
            left: 0;
            display: block;
            width: 17px;
            height: 17px;
            outline: 0;
            border: 1px solid #bdbdbd;
            background: #fff;
            border-radius: 4px;
            color: #0086e6;
        }

        &:checked + i {
            border-color: #0086e6;

            &:after {
                content: "\f00c";
                color: #0086e6;
                position: absolute;
                top: -2px;
                left: 0;
                width: 15px;
                height: 15px;
                font: 400 16px/19px FontAwesome;
                text-align: center;
            }
        }
    }
}

.MessageBoxContainer input[type="checkbox"] {
    width: auto;
}

/*Animations*/

.fade {
    transition: opacity 150ms ease;
}

.fade.slow {
    transition-duration: 1s;
}

.fade.left {
    transform: translateX(-20%);
    transition: opacity 150ms ease, transform 150ms ease;
}

.fade.right {
    transform: translateX(20%);
    transition: opacity 150ms ease, transform 150ms ease;
}

.fade.out {
    transition: opacity 150ms ease;
}

.fade.in {
    transform: translateX(0);
}

/*SmartMessageBox*/

.MessageBoxContainer {
    top: 50%;
    transform: translateY(-50%);

    .MessageBoxMiddle {
        white-space: pre-wrap;

        a {
            color: @brand-color;
            transition: color 200ms linear;

            &:hover,
            &:active,
            &:focus {
                color: @brand-darker;
            }
        }
    }
}

/*Admin Documents Search*/

.results__blurb {
    display: none !important;
}

.searchResults__result {
    margin-bottom: 0;
    position: relative;
}

.searchResults__result .copy-link {

    &,
    &:active {
        position: absolute !important;
        top: 30px !important;
        left: auto !important;
        right: 15px !important;
        cursor: pointer;
    }
}

.searchResults__result:not(:hover) .copy-link {
    display: none;
}


/* Overlay */

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
    z-index: 9999;
}


/* Cards */

.card {
    padding: 16px;
    border-radius: 10px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: box-shadow 100ms ease;

    &.selected {
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

        .card-title {
            background-color: #54b7ff;
            color: #fff;

            .light {
                color: #eee !important;
            }
        }
    }

    .card-title {
        font-size: large;
        font-weight: 500;
        padding: 8px 16px;
        margin: -16px -16px 16px -16px;
        border-radius: 10px 10px 0 0;
        background: #f0f0f0;
        transition: background-color 100ms ease, color 100ms ease;
    }

    .card-actions {
        padding: 8px 16px;
        margin: 16px -16px -16px -16px;
        border-radius: 0 0 10px 10px;
        border-top: 2px solid #f0f0f0;
        text-align: right;

        .btn {
            font-size: larger;
            font-weight: 500;

            &:not(.btn-primary):hover {
                background-color: #f0f0f0;
            }

            &[disabled] {
                background: none;
                border: none;
                box-shadow: none;
                position: static;
                transition: background-color 100ms ease;

                &.btn-primary {
                    color: @brand-color !important;
                }
            }
        }
    }

    @media (max-width: 600px) {
        .card-actions {
            text-align: center;
        }
    }
}
/* No UI Slider */
.noUi-target {
    margin: 16px;
    height: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.noUi-horizontal .noUi-handle {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    box-shadow: none;
    transform: translate(-5px, 5px);
    background-color: @brand-color;
    transition: background-color 100ms ease;

    &:hover {
        background-color: @brand-darker;
    }

    &::before, &::after {
        top: 4px;
        height: 8px;
    }

    &::before {
        left: 6px;
    }

    &::after {
        left: 9px;
    }
}


.dot-pulse {
    position: relative;
    left: -9999px;
    width: 25px;
    height: 25px;
    border: 1px solid #69c5ef;
    border-radius: 50%;
    background-color: #69c5ef;
    color: #69c5ef;
    box-shadow: 9999px 0 0 -13px #69c5ef;
    animation: dotPulse 1s infinite linear;
    animation-delay: 0.25s;

    &.on-primary{
        border-color: white;
        background-color: white;
        box-shadow: 9999px 0 0 -13px white;
        color: white;
    }
  }
  
  .dot-pulse::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #69c5ef;
    background-color: #69c5ef;
    color: #69c5ef;
    right: 50px;

    &.on-primary{
        border-color: white;
        background-color: white;
        box-shadow: 9999px 0 0 -13px white;
    }
  }
  
  .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #69c5ef;
    background-color: #69c5ef;
    color: #69c5ef;
    left: 50px;

    &.on-primary{
        border-color: white;
        background-color: white;
    }
  }
  
  .dot-pulse::before {
    box-shadow: 9984px 0 0 -13px #69c5ef;
    animation: dotPulseBefore 1s infinite linear;
    animation-delay: 0s;

    &.on-primary{
        box-shadow: 9984px 0 0 -13px white;
    }
  }
  
  .dot-pulse::after {
    box-shadow: 10014px 0 0 -13px #69c5ef;
    animation: dotPulseAfter 1s infinite linear;
    animation-delay: 0.5s;

    &.on-primary{
        box-shadow: 9984px 0 0 -13px white;
    }
  }

  #static > table:nth-of-type(2) > tbody {
      display: none;
  }

  @media (max-width: 599px) {
    #static > table:nth-of-type(1) > tbody:nth-of-type(1) > tr:nth-of-type(1) {
       display: flex;
       flex-direction: column;
       align-items: center;
     }
   }
  
  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -13px #69c5ef;
    }
    30% {
      box-shadow: 9984px 0 0 3px #69c5ef;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -13px #69c5ef;
    }
  }
  
  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -13px #69c5ef;
    }
    30% {
      box-shadow: 9999px 0 0 3px #69c5ef;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -13px #69c5ef;
    }
  }
  
  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -13px #69c5ef;
    }
    30% {
      box-shadow: 10014px 0 0 3px #69c5ef;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -13px #69c5ef;
    }
  }

//   @media (max-width: 599px) {
//     .dot-pulse {
//         width: 15px;
//         height: 15px;
//         box-shadow: 9999px 0 0 -8px #69c5ef;
//         animation: dotPulse 1s infinite linear;
//         animation-delay: 0.25s;
//       }
      
//       .dot-pulse::before {
//         width: 15px;
//         height: 15px;
//         right: 10px;
//       }
      
//       .dot-pulse::after {
//         width: 15px;
//         height: 15px;
//         left: 10px;
//       }
      
//       .dot-pulse::before {
//         box-shadow: 9984px 0 0 -8px #69c5ef;
//         animation: dotPulseBefore 1s infinite linear;
//         animation-delay: 0s;
//       }
      
//       .dot-pulse::after {
//         box-shadow: 10014px 0 0 -8px #69c5ef;
//         animation: dotPulseAfter 1s infinite linear;
//         animation-delay: 0.5s;
//       }
      
//       @keyframes dotPulseBefore {
//         0% {
//           box-shadow: 9984px 0 0 -8px #69c5ef;
//         }
//         30% {
//           box-shadow: 9984px 0 0 2px #69c5ef;
//         }
//         60%,
//         100% {
//           box-shadow: 9984px 0 0 -8px #69c5ef;
//         }
//       }
      
//       @keyframes dotPulse {
//         0% {
//           box-shadow: 9999px 0 0 -8px #69c5ef;
//         }
//         30% {
//           box-shadow: 9999px 0 0 2px #69c5ef;
//         }
//         60%,
//         100% {
//           box-shadow: 9999px 0 0 -8px #69c5ef;
//         }
//       }
      
//       @keyframes dotPulseAfter {
//         0% {
//           box-shadow: 10014px 0 0 -8px #69c5ef;
//         }
//         30% {
//           box-shadow: 10014px 0 0 2px #69c5ef;
//         }
//         60%,
//         100% {
//           box-shadow: 10014px 0 0 -8px #69c5ef;
//         }
//       }
//   }